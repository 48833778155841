<<template>
  <layout active="/system/branding">
    <div class="wrapper">
      <panel title="System Branding">
        <form
          v-for="(data, name) in labels"
          :key="name"
          class="form">
          <h2>{{ name }}</h2>
          <template v-for="(item, key) in data">
            <ex-input v-if="typeof item === 'string'" v-model="form[key]" :key="key" class="item" :label="`System ${item}`" />
            <ex-select v-else v-model="form[key]" :options="item.options" :key="key" class="item" :label="`System ${item.label}`" />
          </template>
        </form>

        <div class="images">
          <div class="col">
            <label>System Logo</label>
            <image-upload v-model="logo" :base="base" />
          </div>
          <div class="col">
            <label>System Mail Logo</label>
            <image-upload v-model="mailLogo" :base="base" />
          </div>
          <div class="col">
            <label>System Icon</label>
            <image-upload v-model="systemIcon" :base="base" />
          </div>
        </div>

        <div class="buttons">
          <el-button type="primary" :loading="loading" @click="save()">Save</el-button>
        </div>
      </panel>
    </div>
  </layout>
</template>

<script>
import helperMixin from '../common/helpers-mixin'

import Layout from '../common/Layout'
import Panel from '../common/Panel'
import ExInput from '../common/Input'
import ExSelect from '../common/Select'
import ImageUpload from '../common/ImageUpload'

export default {
  name: 'SystemBranding',

  mixins: [helperMixin],

  components: {
    Panel,
    Layout,
    ExInput,
    ExSelect,
    ImageUpload
  },

  data () {
    return {
      form: {
        name: null,
        url: null,
        CompanyName: null,
        dbaName: null,
        address: null,
        city: null,
        country: null,
        state: null,
        postal: null,
        phone: null,
        supportPhone: null,
        email: null,
        supportEmail: null,
        website: null,
        mailName: null,
        mailAddress: null,
        memberNumber: null,
        memberName: null,
        federalTaxNumber: null,
        provincialTaxNumber: null
      },

      base: '/files/system/',
      logo: null,
      mailLogo: null,
      systemIcon: null,

      loading: false
    }
  },

  computed: {
    labels () {
      return {
        Settings: {
          name: 'Name',
          url: 'URL'
        },
        'Company Information': {
          CompanyName: 'Company Name',
          dbaName: 'DBA Name',
          address: 'Address',
          city: 'City',
          country: {
            label: 'Country',
            options: this.countryOptions
          },
          state: !this.stateOptions ? 'Province/State' : {
            label: 'Province/State',
            options: this.stateOptions
          },
          postal: 'Postal Code/Zip',
          phone: 'Main Phone',
          email: 'Main Email',
          website: 'Company Website',
          memberNumber: 'Member Number',
          memberName: 'Member Name',
        },
        'Tax Information': {
          federalTaxNumber: 'Federal Tax Number',
          provincialTaxNumber: 'Provincial Tax Number'
        },
        'Support Information': {
          supportPhone: 'Support Phone',
          supportEmail: 'Support Email'
        },
        'Mail Information': {
          mailName: 'Mail From Name',
          mailAddress: 'Mail From Address'
        }
      }
    },

    data () {
      return this.$store.state.system.system
    },

    stateOptions () {
      return (['CA', 'US'].includes(this.form.country)) ? window.states[this.form.country].map(state => {
        return {
          id: state.id,
          text: `${state.id} - ${state.text}`
        }
      }) : null
    }
  },

  watch: {
    stateOptions () {
      this.form.state = null
    },

    data (data) {
      Object.keys(this.form).forEach(key => this.form[key] = data[key])
      this.logo = data.logo
      this.mailLogo = data.mailLogo
      this.systemIcon = data.systemIcon
      this.$nextTick(() => this.form.state = data.state)
    }
  },

  created () {
    this.$store.dispatch('system/get')
  },

  methods: {
    async save () {
      this.loading = true
      const payload = { ...this.form }

      const logoKeys = ['logo', 'systemIcon', 'mailLogo']
      logoKeys.forEach(key => this.getLogo(payload, key))

      await this.$store.dispatch('system/update', payload)
      this.$message.success('Successfully updated system branding.')
      this.loading = false
    },
    
    async getLogo (payload, key) {
      if (this[key] !== this.data[key]) {
        const params = key === 'logo' ? {} : { field: key }
        if (this[key]) await this.$store.dispatch('system/upload', { file: this[key], params })
        else payload[key] = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 1024px;
  max-width: 100%;
  margin: 0 auto;
  .images {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    .col {
      text-align: center;
      display: block;
      font-size: 14px;
      font-weight: 300;
      color: #000;
      margin: 5px 15px;
    }
  }
  .buttons {
    margin-top: 20px;
    text-align: center;
  }
  .item {
    margin-bottom: 20px;
  }
}

.form {
  justify-content: flex-start;
  padding: 0 50px;
  .form-group {
    width: calc(25% - 40px);
  }
}

h2 {
  display: block;
  margin: 20px;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  color: #000;
}
</style>
