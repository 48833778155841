import Vue from 'vue'
import factory from "../factory"

const state = {
  user: null,
  users: null
}

const getters = {
  getUsersByType: state => role => state.users.filter(user => user.active === 'Yes' && user.role === role)
}

const actions = {
  async get ({ commit }, id) {
    const user  = (await api.get('User', id)).item
    commit('setUser', user)
    return user
  },

  async update ({ commit }, { id, payload }) {
    const data = (await api.update('User', id, payload, true)).item
    commit('setUser', data)
    return data
  },

  async list ({ commit }, payload) {
    const users = (await api.list('User', payload)).items
    commit('setUsers', users)
    return users
  },

  async action ({ commit }, { id, action, payload }) {
    const data = (await api.action('User', id, action, payload)).item
    commit('updateUser', data)
    return data
  },
}

const mutations = {
  setUser (state, user) {
    state.user = user
  },

  setUsers (state, users) {
    state.users = users
  },

  updateUser (state, user) {
    if (!state.users) return

    const index = state.users.findIndex(item => item.id === user.id)
    if (index !== -1) {
      Vue.set(state.users, index, user)
    }
  },

  removeUser (state, id) {
    if (!state.users) return
    const index = state.users.findIndex(item => item.id === id)
    state.users.splice(index, 1)
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  modules: {
    documents: factory('EmployeeDocument')
  },
  namespaced: true
}