<template>
  <div class="page-form">
    <p class="text-center">Citizenship - Citizenship Application;</p>
    
    <p><b>PERSONAL INFORMATION</b></p>

    <p>{{ client.firstName }} - {{ client.lastName }} - {{ age }} - {{ client.nationality | country }} - {{ client.residence | country }}</p>

    <p>Do you have a criminal record? <span class="answer">{{ formatYN(value.criminal_record) }}</span></p>

    <p><b>MAIN APPLICANT</b></p>

    <p>Are you able to communicate in
      <span class="checkbox-group"><input type="checkbox" :disabled="true" :checked="value.main_communicate === 'English'"> English</span>
      <span class="checkbox-group"><input type="checkbox" :disabled="true" :checked="value.main_communicate === 'French'"> French</span>
      <span class="checkbox-group"><input type="checkbox" :disabled="true" :checked="value.main_communicate === 'Both'"> Both</span>
      <span class="checkbox-group"><input type="checkbox" :disabled="true" :checked="value.main_communicate === 'Neither'"> Neither</span>
    </p>

    <p>Source of income for the past 5 years <span class="answer">{{ value.main_source_of_income }}</span></p>

    <p>Countries that you are citizen <span v-for="(country, k) in value.main_countries" :key="k" class="answer">{{ formatCountriesOptions(value.main_countries[k].main_countries) }}</span></p>

    <p>Since when are you a Canadian Permanent Resident <span class="answer">{{ value.main_since_resident | date('ll') }}</span></p>

    <p>How many days have you been outside Canada in the past 5 years? <span class="answer">{{ value.main_outside_canada }}</span></p> 

    <template v-if="value.applicants.length">
      <div v-for="(applicant, key) in value.applicants" :key="key">
        <p><b>APPLICANT {{ key + 2 }}</b></p>

        <p>
          First Name <span class="answer">{{ value.applicants[key].applicant_first_name }}</span>  
          Last Name <span class="answer">{{ value.applicants[key].applicant_last_name }}</span>
          Age <span class="answer">{{ value.applicants[key].applicant_age }}</span>
          <br> 
          Country of Birth <span class="answer">{{ formatCountriesOptions(value.applicants[key].applicant_birth) }}</span>
          Country of Residence <span class="answer">{{ formatCountriesOptions(value.applicants[key].applicant_residence) }}</span>
        </p>

        <p>Do you have a criminal record? <span class="answer">{{ formatYN(value.applicants[key].applicant_criminal_record) }}</span></p>

        <p>Are you able to communicate in
          <span class="checkbox-group"><input type="checkbox" :disabled="true" :checked="value.applicants[key].applicant_communicate === 'English'"> English</span>
          <span class="checkbox-group"><input type="checkbox" :disabled="true" :checked="value.applicants[key].applicant_communicate === 'French'"> French</span>
          <span class="checkbox-group"><input type="checkbox" :disabled="true" :checked="value.applicants[key].applicant_communicate === 'Both'"> Both</span>
          <span class="checkbox-group"><input type="checkbox" :disabled="true" :checked="value.applicants[key].applicant_communicate === 'Neither'"> Neither</span>
        </p>

        <p>Source of income for the past 5 years <span class="answer">{{ value.applicants[key].applicant_source_of_income }}</span></p>

        <p>Countries that you are citizen <span v-for="(country, k) in value.applicants[key].applicant_countries" :key="k" class="answer">{{ formatCountriesOptions(value.applicants[key].applicant_countries[k]) }}</span></p>

        <p>Since when are you a Canadian Permanent Resident <span class="answer">{{ value.applicants[key].applicant_since_resident | date('ll') }}</span></p>

        <p>How many days have you been outside Canada in the past 5 years? <span class="answer">{{ value.applicants[key].applicant_outside_canada }}</span></p>
      </div>
    </template>

    <p><b>ADDITIONAL INFORMATION:</b></p>

    <span class="answer">{{ value.additional_info }}</span>



  </div>
</template>

<script>
import numeral from 'numeral'

import Datepicker from 'vuejs-datepicker'
import ExInput from '../../../common/Input'
import ExSelect from '../../../common/Select'
import ExTextarea from '../../../common/Textarea'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    client: {
      type: Object,
      required: true
    },

    category: {
      type: String,
      default: null
    }
  },

  computed: {
    age () {
      return moment().diff(moment(this.client.dob), 'years')
    },

    countriesOptions () {
      return window.countriesOptions
    },
  },

  methods: {
    formatYN (val) {
      return val === 'yes' ? 'Yes' : 'No'
    },

    formatCountriesOptions(status) {
      const countriesOptions = window.countriesOptions
      return countriesOptions.find( item => item.id === status)?.text
    },
  }
}
</script>