<template>
  <div class="logo">
    <div class="logo-text-img"><img :src="`/files/system/${company.logo}`"></div>
    <h1>{{ company.name }}</h1>
  </div>
</template>

<script>
export default {
  data () {
    return {
      company: window.appData.company
    }
  }
}
</script>

<style lang="scss">
.logo {
  display: flex;
  flex-direction: column;
  align-items:  center;
  justify-content:  center;
  margin-bottom: 25px;
  .logo-img {
    display: block;
    width: 82px;
    height: 82px;
    margin-bottom: 20px;
  }
  .logo-text-img {
    max-width: 250px;
    margin-bottom: 15px;
    img {
      width: 100%;
    }
  }
  h1 {
    font-size: 21px;
    font-weight: 700;
    font-family:  'Roboto', sans-serif;
    color: #000;
    letter-spacing:  12px;
  }
}
</style>