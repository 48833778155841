<template>
  <table class="form-table">
    <tr>
      <td class="text-center"><b>Note</b></td>
      <td class="text-center"><b>Hours</b></td>
      <td class="text-center"><b>Cost</b></td>
    </tr>
    <tr
        v-for="tracker in data"
        :key="tracker.id">
        <td>
          <small>{{ tracker.createdAt | date }}</small>
          <el-tag effect="dark" size="mini">{{ tracker.createdBy.firstName }} {{ tracker.createdBy.lastName }}</el-tag>:
          {{ tracker.name }}
        </td>
        <td>{{ tracker.hours }}</td>
        <td>{{ tracker.cost }}</td>
      </tr>
    <tr>
      <td><ex-input v-model="form.name" label="Note" full no-style /></td>
      <td><ex-input v-model.number="form.hours" label="Hours" full no-style /></td>
      <td><ex-input v-model="form.cost" label="Cost" @blur="formatCurrency" full no-style /></td>
    </tr>
  </table>
</template>

<script>
import numeral from 'numeral'
import { required } from 'vuelidate/lib/validators'

import ExInput from './Input'
import ExSelect from './Select'

export default {
  name: 'Tracker',

  components: {
    ExInput,
    ExSelect
  },

  props: {
    type: {
      type: String,
      required: true
    },

    id: {
      type: [String, Number],
      default: null
    }
  },

  data () {
    return {
      form: {
        name: null,
        hours: null,
        cost: null
      }
    }
  },

  computed: {
    data () {
      return this.$store.state.tracker.data
    }
  },

  created () {
    this.$store.dispatch('tracker/load', { params: {
      type: this.type,
      parentId: this.id
    } })
  },

  methods: {
    save (id) {
      if (!this.$v.form.$invalid && this.id) {
        return this.$store.dispatch('tracker/create', {
          name: this.form.name,
          cost: this.form.cost || 0,
          hours: this.form.hours || 0,
          parentId: this.id || id,
          type: this.type,
          createdAt: 'CURRENT_TIMESTAMP',
          createdBy: window.appData.currentUser.id
        })
      }
    },

    formatCurrency (key) {
      this.form.cost = numeral(this.form.cost).format('$0.00')
    },
  },

  validations: {
    form: {
      name: { required },
    }
  }
}
</script>