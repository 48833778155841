<template>
  <subMenu
    :dashboard="{ to: '/tools/invoicing', text:'Invoicing Dashboard' }"
    :menu="menu" />
</template>

<script>
import SubMenu from '../common/SubMenu'
import Panel from '../common/Panel'

export default {
  name: 'InvoicingMenu',

  components: {
    SubMenu,
    Panel
  },

  props: {
    page: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      default: 'Dashboard',
      menu: []
    }
  },
}
</script>
