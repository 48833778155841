<template>
  <div class="image-holder">
    <span v-if="image" class="remove el-icon-error" @click="remove()" />
    <div class="image">
      <img v-if="image" :src="image">
      <img v-else src="/assets/images/black-logo.png" class="plugg">
    </div>
    <el-button size="mini" type="primary" icon="el-icon-picture" @click="$refs.upload.click()">Upload image</el-button>
    <input v-show="false" ref="upload" type="file" @change="uploadFile" />
  </div>
</template>

<script>
import helperMixin from './helpers-mixin'

export default {
  name: 'ImageUpload',

  mixins: [helperMixin],

  props: {
    base: {
      type: String,
      required: true
    },

    value: {
      type: [String, File],
      required: false
    }
  },

  data () {
    return {
      reader: null
    }
  },

  computed: {
    image () {
      if (this.reader) return this.reader
      return typeof this.value === 'string' ? this.base + this.value : null
    }
  },

  methods: {
    uploadFile ($e) {
      const file = $e.target.files[0]
      if (this.isImage(file)) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.reader = e.target.result
        }
        reader.readAsDataURL(file)

        this.$emit('input', file)
      }
    },

    remove () {
      this.$emit('input', null)
      this.reader = null
    }
  }
}
</script>

<style lang="scss" scoped>
.image-holder {
  width: 130px;
  background-color: #000;
  padding: 2px;
  border-radius: 3px;
  position: relative;
  text-align: center;
  .remove {
    position: absolute;
    top: 6px;
    right: 6px;
    color: #d23131;
    font-size: 20px;
    cursor: pointer;
  }
  .image {
    width: 100%;
    height: 100px;
    background-color: #fff;
    img {
      width: 100%;
      height: 100px;
      object-fit: contain;
    }
    .plugg {
      padding: 10px;
      opacity: .2;
    }
  }
}
</style>