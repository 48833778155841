<template>
  <user-logins-table :logs="logs" :loading="loading" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import UserLoginsTable from '../common/users/UserLoginsTable'

export default {
  components: { UserLoginsTable },

  data () {
    return {
      loading: false
    }
  },

  computed: {
    ...mapGetters({
      getUsersLogsById: 'client/single/users/getUsersLogsById' 
    }),

    registration () {
      return this.$store.state.client.single.registration
    },
    
    userId () {
      return this.registration.userId ? this.registration.userId.id : null
    },

    logs () {
      const logs = this.userId ? this.getUsersLogsById(this.userId) : null
      return logs || []
    }
  },

  async created () {
    if (this.userId) {
      this.loading = true
      await this.getUsersLogs({ id: this.userId })
      this.loading = false
    }
  },

  methods: {
    ...mapActions({
      getUsersLogs: 'client/single/users/getLogs'
    })
  },
}
</script>