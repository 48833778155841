<template>
  <modal
    :title="`Select Date Range`"
    :width="400"
    @close="$emit('close')">
    <div class="form-group">
      <label>Start Date:</label>
      <datepicker
        v-model="form.startDate"
        :class="{ error: $v.form.startDate.$dirty && $v.form.startDate.$invalid }"
        calendar-button
        calendar-button-icon="el-icon-date"
        maxlength="100"
        :validator="$v.form.startDate"
        format="yyyy MMM d"
        :open-date="new Date()"/>
    </div>
    <div class="form-group">
      <label>End Date:</label>
      <datepicker
        v-model="form.endDate"
        :class="{ error: $v.form.endDate.$dirty && $v.form.endDate.$invalid }"
        calendar-button
        calendar-button-icon="el-icon-date"
        maxlength="100"
        :validator="$v.form.endDate"
        format="yyyy MMM d"
        :open-date="new Date()"/>
    </div>

    <div class="buttons">
      <el-button @click="$emit('close')">Cancel</el-button>
      <el-button type="primary" @click="save()">Submit</el-button>
    </div>
  </modal>
</template>

<script>
  import Datepicker from 'vuejs-datepicker';
  import {required} from "vuelidate/lib/validators";

  export default {
    name: "BillingStatementForm",

    data() {
      return {
        form: {}
      }
    },

    computed: {
      registration() {
        return this.$store.state.client.single.registration
      },
    },

    components: {
      Datepicker
    },

    methods: {

      save() {
        this.$v.form.$touch();
        if (this.$v.$invalid) {
          this.$message.error('Please select dates');
          return;
        }

        const startDate = this.form.startDate.getTime();
        const endDate = this.form.endDate.getTime();
        if (endDate < startDate) {
          this.$message.error('Start date must be smaller');
          return;
        }

        this.storeStatement();
      },

      async storeStatement() {

        const loading = this.$loading();

        try {

          const payload = {
            client: this.registration.id,
            startDate: this.form.startDate.toISOString().slice(0, 10),
            endDate: this.form.endDate.toISOString().slice(0, 10),
            createdBy: window.appData.currentUser.id
          };

          const statement = await this.$store.dispatch('client/statements/create', payload);
          await this.generatePdf(statement.id);
          this.$emit('saved');
          this.$message.success('Statement added successfully.');
        } catch (e) {
          this.$message.error('Something went wrong');
        }

        loading.close();
      },

      async generatePdf(statementId) {
        await api.request('POST', `/client/statement/${statementId}/pdf`, {});
      }
    },

    validations() {
      return {
        form: {
          startDate: {required},
          endDate: {required}
        }
      };
    }
  }
</script>

<style lang="scss" scoped>
  .buttons {
    display: flex;
    justify-content: center;
  }

  .form-group {
    margin-bottom: 20px;
  }
</style>