<template>
  <table class="form-table mb-50">
    <tr class="heading">
      <td colspan="8"><b>1. Identity:</b></td>
    </tr>
    <tr>
      <td>Surname</td>
      <td>Name</td>
      <td>Date of birth</td>
      <td colspan="2">City</td>
      <td>Country</td>
      <td>Height</td>
      <td>Eye Color</td>
    </tr>
    <tr v-if="readonly">
      <td>{{ form.lastName }}</td>
      <td>{{ form.firstName }}</td>
      <td>{{ form.dob | date }}</td>
      <td colspan="2">{{ form.city }}</td>
      <td>{{ form.country | country }}</td>
      <td>{{ form.height }}</td>
      <td>{{ form.eyeColor }}</td>
    </tr>
    <tr v-else>
      <td><ex-input v-model="form.lastName" no-style full /></td>
      <td><ex-input v-model="form.firstName" no-style full /></td>
      <td><datepicker v-model="form.dob" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="Date of birth" no-style /></td>
      <td colspan="2"><ex-input v-model="form.city" no-style full /></td>
      <td><ex-select v-model="form.country" :options="countryOptions" no-style full /></td>
      <td><ex-input v-model="form.height" no-style full /></td>
      <td><ex-input v-model="form.eyeColor" no-style full /></td>
    </tr>
    <tr>
      <td>Current spouse / Partner Surname</td>
      <td>Current spouse / Partner Name</td>
      <td>Type of union Marriage / Common law partner</td>
      <td colspan="2">Date of union</td>
      <td>Date of birth</td>
      <td>City</td>
      <td>Country</td>
    </tr>
    <tr v-if="readonly">
      <td>{{ form.partner.lastName }}</td>
      <td>{{ form.partner.firstName }}</td>
      <td>{{ form.partner.marriageType }}</td>
      <td colspan="2">{{ form.partner.unionDate | date }}</td>
      <td>{{ form.partner.dob | date }}</td>
      <td>{{ form.partner.city }}</td>
      <td>{{ form.partner.country | country }}</td>
    </tr>
    <tr v-else>
      <td><ex-input v-model="form.partner.lastName" no-style full /></td>
      <td><ex-input v-model="form.partner.firstName" no-style full /></td>
      <td><ex-input v-model="form.partner.marriageType" no-style full /></td>
      <td colspan="2"><datepicker v-model="form.partner.unionDate" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="Date of union" no-style /></td>
      <td><datepicker v-model="form.partner.dob" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="Date of birth" no-style /></td>
      <td><ex-input v-model="form.partner.city" no-style full /></td>
      <td><ex-select v-model="form.partner.country" :options="countryOptions" no-style full /></td>
    </tr>
    <tr>
      <td>Previous spouse / Partner Surname</td>
      <td>Previous spouse / Partner Name</td>
      <td>Type of union Marriage / Common law partner</td>
      <td>Date of union</td>
      <td>Date of Separation</td>
      <td>Date of birth</td>
      <td>City</td>
      <td>Country</td>
    </tr>
    <tr v-if="readonly">
      <td>{{ form.previousPartner.lastName }}</td>
      <td>{{ form.previousPartner.firstName }}</td>
      <td>{{ form.previousPartner.marriageType }}</td>
      <td>{{ form.previousPartner.unionDate | date }}</td>
      <td>{{ form.previousPartner.separationDate | date }}</td>
      <td>{{ form.previousPartner.dob | date }}</td>
      <td>{{ form.previousPartner.city }}</td>
      <td>{{ form.previousPartner.country | country }}</td>
    </tr>
    <tr v-else>
      <td><ex-input v-model="form.previousPartner.lastName" no-style full /></td>
      <td><ex-input v-model="form.previousPartner.firstName" no-style full /></td>
      <td><ex-input v-model="form.previousPartner.marriageType" no-style full /></td>
      <td><datepicker v-model="form.previousPartner.unionDate" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="Date of union" no-style /></td>
      <td><datepicker v-model="form.previousPartner.separationDate" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="Date of Separation" no-style /></td>
      <td><datepicker v-model="form.previousPartner.dob" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="Date of birth" no-style /></td>
      <td><ex-input v-model="form.previousPartner.city" no-style full /></td>
      <td><ex-select v-model="form.previousPartner.country" :options="countryOptions" no-style full /></td>
    </tr>
  </table>
</template>

<script>
import helperMixin from '../../common/helpers-mixin'

import ExInput from '../../common/Input'
import ExSelect from '../../common/Select'
import Datepicker from 'vuejs-datepicker'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    readonly: {
      type: Boolean,
      default: true
    }
  },

  mixins: [ helperMixin ],

  components: {
    ExInput,
    ExSelect,
    Datepicker
  },

  data () {
    return {
      form: this.value
    }
  }
}
</script>
