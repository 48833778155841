export default {
  registration: {
    language: 'Language',
    client_registration: 'Client Registration',
    client_general_information: 'Client General Information',
    first_name: 'First Name',
    enter_first_name: 'Enter First Name',
    date_of_birth: 'Date of Birth',
    date_dd: 'DD',
    date_mm: 'MM',
    date_yy: 'YY',
    country_of_nationality: 'Country of Nationality',
    select_country_of_nationality: 'Select Country of Nationality',
    last_name: 'Last Name',
    enter_last_name: 'Enter Last Name',
    email_address: 'Email Address',
    enter_email_address: 'Enter Email Address',
    country_of_residence: 'Country of residence',
    select_country_of_residence: 'Select Country of Residence',
    client_address_information: 'Client Address information ',
    address: 'Address',
    enter_address: 'Enter Address',
    state_province: 'State/Province',
    select_state_province: 'Select State/ Province',
    phone_number: 'Phone Number',
    enter_phone_number: 'Enter Phone Number',
    city: 'City',
    enter_city: 'Enter City',
    postal_code: 'Postal Code',
    enter_postal_code: 'Enter Postal Code',
    other_phone_number: 'Other Phone Number',
    client_service_requested: 'Client Service Requested',
    service: 'Service',
    select_service: 'Select Service',
    do_you_agree_to_our: 'Do you agree to our',
    termes_conditions: 'Termes & Conditions',
    privacy: 'Privacy',
    submit_registration: 'Submit Registration',
    user_policy: 'User Policy ',
    already_a_user_login_to_get_started: 'Already a user? Login to get started',
    trouble_logging_in_please_contact: 'Trouble logging in? Please contact: ',
    use_of_this_site_is_restricted_for_the_use_of_its_immigration_clients_associates: 'Use of this site is restricted for the use of ITS Immigration Clients & Associates.'
  },

  services: {
    consultation: 'Consultation',
    permanent_residence: 'Permanent Residence',
    temporary_residence: 'Temporary Residence',
    sponsorship: 'Sponsorship',
    citizenship_application: 'Citizenship application',
    document_replacement_extension: 'Document Replacement & Extensions',
    travel_documents: 'Travel Documents',
    legal_advic: 'Legal Advice'
  }
}
