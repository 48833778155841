<template>
  <layout :active="active">
    <h1 class="page-title">{{ users ? 'Users' : 'Employees' }}</h1>

    <div class="space-between">
      <a
        v-if="!users"
        href="/system/create-staff-users">
        <el-button icon="el-icon-plus" type="primary">Add New Employee</el-button>
      </a>

      <el-button-group>
        <el-button :type="onlyActive ? 'success' : 'default'" size="small" @click="onlyActive = true">Active</el-button>
        <el-button :type="!onlyActive ? 'success' : 'default'" size="small" @click="onlyActive = false">Archived</el-button>
      </el-button-group>
    </div>

    <div class="table-wrapper mt-20">
      <el-table v-loading="loading" :data="list" style="min-width: 100%" stripe>
        <el-table-column label="Emp ID">
          <template slot-scope="scope">
            <a :href="`/system/employee/${scope.row.id}`" class="link">
              <b>{{ scope.row.uuid }}</b>
            </a>
          </template>
        </el-table-column>

        <el-table-column label="First Name">
          <template slot-scope="scope">{{ scope.row.firstName }}</template>
        </el-table-column>

        <el-table-column label="Last Name">
          <template slot-scope="scope">{{ scope.row.lastName }}</template>
        </el-table-column>

        <el-table-column label="Email">
          <template slot-scope="scope">{{ scope.row.email }}</template>
        </el-table-column>

        <el-table-column label="Role">
          <template slot-scope="scope">
            <el-tag type="success" effect="dark" size="mini">{{ scope.row.role | role }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="Actions" width="100" align="right">
          <template slot-scope="scope">
            <el-tooltip class="item" :content="!!scope.row.archiveDate ? 'Unarchive' : 'Archive'" placement="left">
              <el-button v-if="scope.row.id !== user.id" size="mini" icon="el-icon-takeaway-box" circle @click="askArchive(scope.row)" />
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </layout>
</template>

<script>
import Panel from '../common/Panel'
import Layout from '../common/Layout'

export default {
  name: 'Employees',

  props: {
    users: {
      type: String,
      default: null
    }
  },

  components: {
    Panel,
    Layout
  },

  data () {
    return {
      onlyActive: true,
      roles: null,
      loading: true
    }
  },

  computed: {
    user () {
      return window.appData.currentUser
    },

    active () {
      return this.users ? '/system/users' : '/system/employees'
    },

    data () {
      return this.$store.state.users.users || []
    },

    staff () {
      return this.roles ? this.roles.filter(role => role.user_group === 'staff').map(role => role.name) : []
    },

    list () {
      return this.users ? this.data : this.data.filter(user => this.staff.includes(user.role))
    }
  },

  watch: {
    onlyActive () {
      this.load()
    }
  },

  async created () {
    this.loading = true
    this.roles = (await api.list('Role')).items
    await this.load()
    this.loading = false
  },

  methods: {
    async askArchive (employee) {
      try {
        await this.$confirm(`Are you sure you want to ${employee.archiveDate ? 'un' : ''}archive employee ${employee.firstName} ${employee.lastName}?`)
        this.toggleArchive(employee, employee.id)
      } catch {}
    },

    async toggleArchive (employee, id) {
      const isArchive = !employee.archiveDate
      this.$store.dispatch('users/action', {
        id,
        action: isArchive ? 'archive' : 'unarchive',
      })

      this.$message.success(`Employee ${employee.firstName} ${employee.lastName} successfully ${isArchive ? 'archived' : 'unarchived'}.`)
    
      this.$store.commit('users/removeUser', id)
    },

    async load () {
      this.loading = true
      await this.$store.dispatch('users/list', {
        archiveDate: this.onlyActive ? ['IS NULL'] : ['IS NOT NULL']
      })
      this.loading = false
    }
  }
}
</script>
