<template>
  <div>
    <div class="buttons">
      <el-button v-if="data.userId && !edit" size="mini" icon="el-icon-edit" type="primary" @click="startEdit()">Edit</el-button>
      <template v-if="edit">
        <el-button size="mini" icon="el-icon-edit" type="success" @click="save()">Save</el-button>
        <el-button size="mini" icon="el-icon-edit" @click="edit = false">Cancel</el-button>
      </template>
    </div>

    <div class="info-box">
      <div class="col">
        <span>Account Number</span>
        <b>{{ data.userId ? data.userId.accountId : 'N/A' }}</b>
      </div>
      <div class="col">
        <span>Services Fees Option</span>
        <b v-if="!edit">{{ data.userId && worksheetData ? servicesFees[worksheetData.servicesFees] : 'N/A' }}</b>
        <ex-select v-else v-model="form.servicesFees" :validator="$v.form.servicesFees" :options="servicesFeeOptions" class="m-0" no-style full />
      </div>
      <div class="col">
        <span>Billing Cycle</span>
        <template v-if="!edit">
          <b v-if="data.userId && worksheetData">{{ worksheetData.billingCycle | capitalize }}</b>
          <b v-else>N/A</b>
        </template>
        <ex-select v-else v-model="form.billingCycle" :validator="$v.form.billingCycle" :options="billingCycleOtions" class="m-0" no-style full />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import ExSelect from '../../common/Select'

export default {
  components: { ExSelect },

  data () {
    return {
      form: {
        servicesFees: null,
        billingCycle: null
      },

      servicesFees: {
        pluggAccount: 'Plugg Account',
        prepay: 'Prepay',
        postPay: 'Post-Pay'
      },

      edit: false
    }
  },

  computed: {
    data () {
      return this.$store.state.client.single.registration
    },

    worksheet () {
      return this.data.Worksheet
    },

    worksheetData () {
      return this.worksheet ? JSON.parse(this.worksheet.data) : null
    },

    servicesFeeOptions () {
      return Object.keys(this.servicesFees).map(key => {
        return {
          id: key,
          text: this.servicesFees[key]
        }
      })
    },

    billingCycleOtions () {
      return [
        {
          id: 'weekly',
          text: 'Weekly'
        },
        {
          id: 'monthly',
          text: 'Monthly'
        }
      ]
    },
  },

  methods: {
    ...mapActions({
      updateChecklist: 'client/single/updateChecklist'
    }),

    async save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          const loading = this.$loading()
          const payload = { data: JSON.stringify(Object.assign(this.worksheetData, this.form)) }
          await this.updateChecklist({ type: 'Worksheet', id: this.worksheet.id, payload })
          this.$message.success('Successfully edited Client Billing Info.')
          this.edit = false
          loading.close()
        } catch {}
      }
    },

    startEdit () {
      this.form = {
        servicesFees: this.worksheetData.servicesFees,
        billingCycle: this.worksheetData.billingCycle
      }
      this.edit = true
    }
  },

  validations: {
    form: {
      servicesFees: { required },
      billingCycle: { required }
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  margin-bottom: 20px;
}
</style>