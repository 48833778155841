<template>
  <layout active="/scheduler/task-list">
    <div>
      <schedulerMenu :page="currentPage" />
    
      <panel :title="currentPage.text">
        <taskList :type="type" />
      </panel>
    </div>
  </layout>
</template>

<script>
import SchedulerMenu from './SchedulerMenu'
import TaskList from '../calendar/TaskList'
import Layout from '../common/Layout'
 
export default {
  name: 'SchedulerTaskList',

  components: {
    SchedulerMenu,
    TaskList,
    Layout
  },

  data () {
    return {
      type: 'SCHEDULER',
      currentPage: {
        text: 'Task List',
        breadcrumbs: [{
          text: 'Calendar',
          to: '/tools/scheduler/calendar'
        }]
      }
    }
  }
}
</script>
