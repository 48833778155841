<template>
  <div>
    <div class="table-wrapper">
      <div class="info-box">
        <h3>Client Evaluation</h3>
      </div>
      <el-table
        v-loading="loading"
        :data="[null]" style="min-width: 100%" stripe>
        <el-table-column label="Status" width="90">
          <div class="status">
            <i :class="statusIcon" />
          </div>
        </el-table-column>

        <el-table-column label="Program">
          <ex-select v-if="!data" v-model="program" :options="programOptions" no-style />
          <template v-else>{{ data.program | program }}</template>
        </el-table-column>

        <el-table-column label="Category">
          <ex-select v-if="!data" v-model="category" :options="categoryOptions" no-style />
          <template v-else>{{ data.category | programCategory }}</template>
        </el-table-column>

        <el-table-column label="Created">
          <template v-if="!data">N/A</template>
          <template v-else>{{ data.createdAt | date }}</template>
        </el-table-column>

        <el-table-column label="Submited">
          <template v-if="!isSubmitted">N/A</template>
          <template v-else>{{ data.submitted | date }}</template>
        </el-table-column>

        <el-table-column label="Accepted">
          <template v-if="!isAccepted">N/A</template>
          <template v-else>{{ data.accepted | date }}</template>
        </el-table-column>

        <el-table-column label="Actions" align="right">
          <el-button v-if="!data" :disabled="!valid" size="mini" type="primary" @click="create()">Create</el-button>
          <template v-else>
            <el-button v-if="!isSubmitted" size="mini" @click="shareModal = true">Share</el-button>
            <el-button size="mini" type="primary" @click="start()">{{ viewButtonText }}</el-button>
            <el-button size="mini" type="danger" @click="cancel()">Cancel</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <modal
      v-if="openForm"
      :width="1024"
      @close="close()">
      <component :is="programForm" v-model="form" :category="data ? data.category : null" :client="client" />

      <div class="mt-20 text-center">
        <template v-if="!isSubmitted">
          <el-button size="small" type="primary" :loading="buttonLoader" @click="save()">Save</el-button>
          <el-button size="small" type="success" :loading="buttonLoader" @click="submit()">Submit</el-button>
        </template>
        <template v-else>
          <template v-if="!editMode && !isAccepted">
            <el-button size="small" type="primary" @click="editMode = true">Edit</el-button>
            <el-button size="small" type="success" @click="accept()">Accept</el-button>
          </template>
          <el-button v-else size="small" type="primary" :loading="buttonLoader" @click="editSave()">Save</el-button>
        </template>
        <el-button size="small" @click="close()">Close</el-button>
      </div>
    </modal>

    <share
      v-if="shareModal"
      :data="data"
      :action="actionEvaluation"
      :link="link"
      @update="updateStore($event)"
      @close="shareModal = false" />
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions } from 'vuex'
import helperMixin from '../../common/helpers-mixin'

import ExSelect from '../../common/Select'
import Share from './evaluation/Share'
import TemporaryResidence from './evaluation/TemporaryResidence'
import PEQ from './evaluation/PEQ'
import PEQReview from './evaluation/PEQReview'
import TemporaryResidenceReview from './evaluation/TemporaryResidenceReview'
import EconomicImmigration from './evaluation/EconomicImmigration'
import HumanitarianImmigration from './evaluation/HumanitarianImmigration'
import HumanitarianImmigrationReview from './evaluation/HumanitarianImmigrationReview'
import EconomicImmigrationReview from './evaluation/EconomicImmigrationReview'
import Documents from './evaluation/Documents'
import DocumentsReview from './evaluation/DocumentsReview'
import LegalReturnToCanada from './evaluation/LegalReturnToCanada'
import LegalReturnToCanadaReview from './evaluation/LegalReturnToCanadaReview'
import LegalImmigrationAppeal from './evaluation/LegalImmigrationAppeal'
import LegalImmigrationAppealReview from './evaluation/LegalImmigrationAppealReview'
import LegalImmigrationDetention from './evaluation/LegalImmigrationDetention'
import LegalImmigrationDetentionReview from './evaluation/LegalImmigrationDetentionReview'
import LegalPRAA from './evaluation/LegalPRAA'
import LegalPRAAReview from './evaluation/LegalPRAAReview'
import LegalRefugeeAppeal from './evaluation/LegalRefugeeAppeal'
import LegalRefugeeAppealReview from './evaluation/LegalRefugeeAppealReview'
import LegalRefugeeClaim from './evaluation/LegalRefugeeClaim'
import LegalRefugeeClaimReview from './evaluation/LegalRefugeeClaimReview'
import FamilyReunification from './evaluation/FamilyReunification'
import FamilyReunificationReview from './evaluation/FamilyReunificationReview'
import Citizenship from './evaluation/Citizenship'
import CitizenshipReview from './evaluation/CitizenshipReview'

export default {
  name: 'ClientEvaluation',

  mixins: [helperMixin],

  components: {
    PEQ,
    Share,
    ExSelect,
    PEQReview,
    Documents,
    DocumentsReview,
    TemporaryResidence,
    EconomicImmigration,
    HumanitarianImmigration,
    TemporaryResidenceReview,
    LegalReturnToCanada,
    LegalReturnToCanadaReview,
    EconomicImmigrationReview,
    LegalImmigrationAppeal,
    LegalImmigrationAppealReview,
    HumanitarianImmigrationReview,
    LegalRefugeeAppealReview,
    LegalImmigrationDetention,
    LegalImmigrationDetentionReview,
    LegalPRAA,
    LegalPRAAReview,
    LegalRefugeeAppeal,
    LegalRefugeeClaim,
    LegalRefugeeClaimReview,
    FamilyReunification,
    FamilyReunificationReview,
    Citizenship,
    CitizenshipReview
  },

  data () {
    return {
      type: 'ClientEvaluation',

      program: null,
      category: null,

      editMode: false,
      shareModal: false,

      form: {},

      openForm: false,
      loading: false,
      buttonLoader: false
    }
  },

  computed: {
    link () {
      const url = window.appData.apiRoot.replace('/api', '/client-evaluation-form/')
      return this.data.link ? url + this.data.link : null
    },

    viewButtonText () {
      if (this.isAccepted) return 'View'
      if (this.isSubmitted) return 'Review'
      return 'Start'
    },

    statusIcon () {
      if (this.isAccepted) return 'el-icon-s-claim success'
      return this.isSubmitted ? 'el-icon-s-claim warning' : 'el-icon-s-release'
    },

    isSubmitted () {
      return this.data?.submitted
    },

    isAccepted () {
      return this.data?.accepted
    },

    programForm () {
      const forms = {
        economic_immigration: 'EconomicImmigration',
        humanitarian_immigration: 'HumanitarianImmigration',
        PEQ: 'PEQ',
        visitor_visa: 'TemporaryResidence',
        supervisa: 'TemporaryResidence',
        student_permit: 'TemporaryResidence',
        temporary_worker: 'TemporaryResidence',
        other: 'TemporaryResidence',
        document_replacement: 'Documents',
        document_extension: 'Documents',
        travel_documents: 'Documents',
        authorization_return: 'LegalReturnToCanada',
        imigration_appeal: 'LegalImmigrationAppeal',
        immigration_detention: 'LegalImmigrationDetention',
        PRAA: 'LegalPRAA',
        refugee_appeal: 'LegalRefugeeAppeal',
        refugee_claim: 'LegalRefugeeClaim',
        spouse_sponsorship: 'FamilyReunification',
        parents_sponsorship: 'FamilyReunification',
        children_sponsorship: 'FamilyReunification',
        citizenship_application: 'Citizenship'
      }
      let form =  this.data ? forms[this.data.category] || 'div' : 'div'
      if (this.isSubmitted && !this.editMode) form += 'Review'
      return form
    },

    data () {
      return this.client?.evaluation
    },

    programOptions () {
      return Object.keys(window.programOptions).map(key => ({
        id: key,
        text: window.programOptions[key].text
      }))
    },

    categoryOptions () {
      if (!this.program) return null
      return Object.keys(window.programOptions[this.program].items).map(key => ({
        id: key,
        text: window.programOptions[this.program].items[key]
      }))
    },

    client () {
      return this.$store.state.client.single.registration
    },

    valid () {
      return !!(this.program && this.category)
    }
  },

  watch: {
    'program' () {
      this.category = null
    }
  },

  methods: {
    ...mapActions({
      actionEvaluation: 'client/evaluation/action',
      createEvaluation: 'client/evaluation/create',
      updateEvaluation: 'client/evaluation/update',
      remove: 'client/evaluation/delete'
    }),

    editSave () {
      const payload = { data: this.form }
      this.update(payload, true)
      this.editMode = false
    },

    close () {
      this.openForm = false
      this.editMode = false
    },

    async accept () {
      this.$confirm('Are you sure you want to accept?').then(() => {
        const payload = { accepted: 'CURRENT_TIMESTAMP' }
        this.update(payload)
      })
    },

    submit () {
      const payload = {
        data: this.form,
        submitted: 'CURRENT_TIMESTAMP'
      }
      this.update(payload)
    },

    save () {
      const payload = { data: this.form }
      this.update(payload)
    },

    start () {
      if (this.data?.data) {
        this.form = _.cloneDeep(this.data.data)
      }
      this.openForm = true
    },

    async update (payload, openForm = false) {
      this.buttonLoader = true
      try {
        const evaluation = await this.updateEvaluation({ id: this.data.id, payload})
        this.updateStore(evaluation)
        this.$message.success('Client Evaluation updated successfully.')
        this.openForm = openForm
      } catch {} finally {
        this.buttonLoader = false
      }
    },

    async create () {
      if (!this.valid) return

      this.loading = true
      try {
        const payload = {
          program: this.program,
          category: this.category,
          client: this.client.id,
          createdAt: 'CURRENT_TIMESTAMP',
          createdBy: window.appData.currentUser.id
        }
        const evaluation = await this.createEvaluation(payload)
        this.updateStore(evaluation)
      } catch {} finally {
        this.loading = false
      }
    },

    async cancel () {
      try {
        await this.$confirm('Are you sure you want to cancel a Client Evaluation?')
        try {
          this.loading = true
          await this.remove(this.data.id)
          this.updateStore(null)
          this.$message.success('Client Evaluation cancelled successfully.')
        } catch {} finally {
          this.loading = false
        }
      } catch {}
    },

    updateStore (data) {
      this.$store.commit('client/single/setData', { key: 'ClientEvaluation', data })
      this.$store.commit('client/single/setData', { key: 'evaluation', data })
    }
  }
}
</script>

<style lang="scss" scoped>
.table-wrapper {
  margin-top: 20px;
}
.status {
  font-size: 30px;
  margin-right: 10px;
  .success {
    color: #67c23a;
  }
  .warning {
    color: orange;
  }
}
</style>