import { render, staticRenderFns } from "./LegalPRAA.vue?vue&type=template&id=40085c6b&"
import script from "./LegalPRAA.vue?vue&type=script&lang=js&"
export * from "./LegalPRAA.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports