<template>
  <div>
    <div class="buttons">
      <el-button v-if="!edit" size="mini" icon="el-icon-edit" type="primary" @click="startEdit()">Edit</el-button>
      <template v-if="edit">
        <el-button size="mini" icon="el-icon-edit" type="success" @click="save()">Save</el-button>
        <el-button size="mini" icon="el-icon-edit" @click="edit = false">Cancel</el-button>
      </template>
    </div>

    <div v-for="(info, idx) in general" :key="idx" class="info-box">
      <h3>{{ info.name }}</h3>
      <div v-for="(item, index) in info.items" :key="index" class="col">
        <span>{{ item.name }}</span>

        <template v-if="edit">
          <template v-if="['email', 'text'].includes(item.input)">
            <ex-input v-model="form[item.key]" :type="item.input" :validator="item.validation" class="m-0" full />
          </template>
          <ex-select v-if="item.input === 'select'" v-model="form[item.key]" :disabled="item.disabled || false" :validator="item.validation" :options="item.options" class="m-0" full />
        </template>

        <b v-else>{{ data.userId ? item.value : 'N/A' }}</b>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { phone } from '../common/validators'
import helperMixin from '../common/helpers-mixin'
import { required, email } from 'vuelidate/lib/validators'

import ExInput from '../common/Input'
import ExSelect from '../common/Select'

export default {
  components: {
    ExInput,
    ExSelect
  },

  mixins: [ helperMixin ],

  data () {
    return {
      edit: false,

      form: {
        company: null,
        companyType: null,
        country: null,
        address: null,
        city: null,
        state: null,
        postal: null,
        companyPhone: null,
        companyEmail: null,

        selectedState: null,
        selectedCountry: null
      },
    }
  },

  computed: {
    data () {
      return this.$store.state.client.single.registration
    },

    worksheet () {
      return this.data.Worksheet
    },

    worksheetData () {
      return this.worksheet ? JSON.parse(this.worksheet.data) : null
    },

    general () {
      if (!this.data) return []
      const companyInfo = [
        {
          name: 'Client Company Name',
          value: this.data.company,
          key: 'company',
          validation: this.$v.form.company,
          input: 'text'
        },
        {
          name: 'Client Company Type',
          value: window.companyTypes[this.data.companyType],
          key: 'companyType',
          validation: this.$v.form.companyType,
          input: 'select',
          options: this.companyTypeOptions
        },
        {
          name: 'Country',
          value: window.countries[this.data.country],
          key: 'selectedCountry',
          validation: this.$v.form.country,
          input: 'select',
          options: this.countryOptions
        }
      ]
      if (this.worksheetData) {
        companyInfo.push({
          name: 'Email',
          value: this.worksheetData ? this.worksheetData.email : 'N/A',
          key: 'companyEmail',
          validation: this.$v.form.companyEmail,
          input: 'text'
        })
      }

      return [
        {
          name: 'Client Company Information',
          items: companyInfo
        },
        {
          name: 'Client Company Address Information',
          items: [
            {
              name: 'Address',
              value: this.data.address,
              key: 'address',
              validation: this.$v.form.address,
              input: 'text'
            },
            {
              name: 'City',
              value: this.data.city,
              key: 'city',
              validation: this.$v.form.city,
              input: 'text'
            },
            {
              name: 'State/Province',
              value: ['CA', 'US'].includes(this.data.country) ? window.states[this.data.country].find(state => state.id === this.data.state).text : 'N/A',
              key: 'selectedState',
              validation: this.$v.form.state,
              input: 'select',
              disabled: !this.stateOptions,
              options: this.stateOptions
            },
            {
              name: 'ZIP/Postal Code',
              value: this.data.postal,
              key: 'postal',
              validation: this.$v.form.postal,
              input: 'text'
            },
            {
              name: 'Phone Number',
              value: this.data.companyPhone,
              key: 'companyPhone',
              validation: this.$v.form.companyPhone,
              input: 'text'
            }
          ]
        }
      ]
    },

    companyTypeOptions () {
      return Object.keys(window.companyTypes).map(key => {
        return {
          id: key,
          text: window.companyTypes[key]
        }
      })
    },

    stateOptions () {
      return (['CA', 'US'].includes(this.form.country)) ? window.states[this.form.country].map(state => {
        return {
          id: state.id,
          text: `${state.id} - ${state.text}`
        }
      }) : null
    }
  },

  watch: {
    'form.selectedState': {
      handler (value) {
        if (value) {
          this.form.state = this.getInitials(value)
        }
      }
    },

    'form.selectedCountry': {
      handler (value) {
        if (value) {
          this.form.country = this.getInitials(value)
          this.selectedState = null
          this.form.state = null
        }
      }
    }
  },

  methods: {
    ...mapActions({
      update: 'client/single/update',
      updateChecklist: 'client/single/updateChecklist'
    }),

    async save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          const loading = this.$loading()

          let payload = { ...this.form }
          delete payload.companyEmail
          this.update({ id: this.data.id, payload })

          payload = {
            data: JSON.stringify(Object.assign(this.worksheetData, { email: this.form.companyEmail }))
          }
          await this.updateChecklist({ type: 'Worksheet', id: this.worksheet.id, payload })

          this.$message.success('Successfully edited Client Company Info.')
          this.edit = false
          loading.close()
        } catch {
          this.$message.error('There was an error... Please try again.')
        }
      }
    },

    startEdit () {
      this.form = {
        company: this.data.company,
        companyType: this.data.companyType,
        country: this.data.country,
        address: this.data.address,
        city: this.data.city,
        state: this.data.state,
        postal: this.data.postal,
        companyPhone: this.data.companyPhone,
        companyEmail: this.worksheetData ? this.worksheetData.email : null,
        selectedCountry: this.data.country,
        selectedState: null
      }
      this.$nextTick(() => {
        this.form.selectedState = this.data.state
        this.form.state = this.data.state
      })
      this.edit = true
    },

    getInitials (value) {
      return value.split('').splice(0, 2).join('')
    }
  },
  
  validations () {
    const rules = {
      form: {
        company: { required },
        companyType: { required },
        country: { required },
        address: { required },
        city: { required },
        postal: { required },
        companyPhone: { required, phone },
        companyEmail: { required, email },
      }
    }

    if (this.stateOptions) {
      rules.form.state = { required }
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
  .buttons {
    margin-bottom: 20px;
  }
  .m-0 {
    margin: 0;
  }
</style>