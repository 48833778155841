<template>
  <div class="page-form">
    <p class="text-center">Family Reunification – {{ title }}</p>
    
    <p><b>PERSONAL INFORMATION</b></p>

    <p>{{ client.firstName }} - {{ client.lastName }} - {{ age }} - {{ client.nationality | country }} - {{ client.residence | country }}</p>

    <p>Do you have a criminal record? <ex-select v-model="form.criminal_record" :options="yesNoOptions" no-style /></p>

    <div v-for="(sponsor, key) in form.sponsors" :key="`sponsors_${key}`">
      <p><b>SPONSOR {{ key + 1 }}</b></p>

      <p>
        <template v-if="key >= 1">
          Age <ex-input v-model.number="form.sponsors[key].age" type="number" :min="0" no-style />
          Country of Residence <ex-select v-model="form.sponsors[key].sponsor_country" :options="countriesOptions" no-style /> 
        </template>
        Canadian Status <ex-select v-model="form.sponsors[key].sponsor_canadian_status" :options="residenceStatus" no-style />
      </p>
    
      <p v-if="key >= 1">Do you have a criminal record? <ex-select v-model="form.sponsors[key].sponsor_criminal_record" :options="yesNoOptions" no-style /></p>

      <p>Marital status <ex-select v-model="form.sponsors[key].sponsor_marital_status" :options="maritialStatusOptions" no-style /></p>

      <p>Medical issues <ex-select v-model="form.sponsors[key].sponsor_medical_issues" :options="yesNoOptions" no-style style="width: 95px"/> 
      Children under 18 y/o <ex-select v-model="form.sponsors[key].sponsor_children" :options="yesNoOptions" no-style style="width: 95px"/> 
      How many <ex-input v-model.number="form.sponsors[key].sponsor_children_num" :min="0" type="number" no-style style="width: 95px"/> 
      Granted custody <ex-select v-model="form.sponsors[key].sponsor_granted_custody" :options="yesNoOptions" no-style style="width: 95px"/></p>

      <p>Source of income <ex-input v-model="form.sponsors[key].sponsor_source_of_income" no-style /> 
      Yearly income <ex-input v-model="form.sponsors[key].sponsor_yearly_income" @blur="formatMoney('sponsor_yearly_income')" no-style /></p>
    </div>

    <el-button v-if="form.sponsors.length < 2" size="small" type="primary" icon="el-icon-plus" @click="addSponsor()">Add Sponsor</el-button> 

    <div v-for="(person, k) in form.sponsored" :key="`sponsored_${k}`">

      <p><b>SPONSORED PERSON {{ k + 1 }}</b></p>

      <p>Relationship with Sponsor 1 <ex-select v-model="form.sponsored[k].sponsor_two_relationship" :options="sponsorRelationship" no-style /></p>

      <p v-if="form.sponsors.length > 1">Relationship with Sponsor 2 <ex-select v-model="form.sponsored[k].sponsor_relationship" :options="sponsorRelationship" no-style /></p>

      <p>Age <ex-input v-model.number="form.sponsored[k].sponsored_age" type="number" :min="0" no-style /> 
      Country of Residence <ex-select v-model="form.sponsored[k].sponsored_country" :options="countriesOptions" no-style /> 
      Canadian Status <ex-select v-model="form.sponsored[k].sponsored_canadian_status" :options="residenceStatus" no-style /></p>
      
      <p>Do you have a criminal record? <ex-select v-model="form.sponsored[k].sponsored_criminal_record" :options="yesNoOptions" no-style /></p>

      <p>Marital status <ex-select v-model="form.sponsored[k].sponsored_marital_status" :options="maritialStatusOptions" no-style /></p>

      <p>Medical issues <ex-select v-model="form.sponsored[k].sponsored_medical_issues" :options="yesNoOptions" no-style style="width: 95px"/> 
      Children under 18 y/o <ex-select v-model="form.sponsored[k].sponsored_children" :options="yesNoOptions" no-style style="width: 95px"/> 
      How many <ex-input v-model.number="form.sponsored[k].sponsored_children_num" :min="0" type="number" no-style style="width: 95px"/>
      Granted custody <ex-select v-model="form.sponsored[k].sponsored_granted_custody" :options="yesNoOptions" no-style style="width: 95px"/></p>

      <p>Source of income <ex-input v-model="form.sponsored[k].sponsored_source_of_income" no-style /> 
      Yearly income <ex-input v-model="form.sponsored[k].sponsored_yearly_income" @blur="formatMoney('sponsor_yearly_income')" no-style /></p>

    </div>

    <el-button v-if="form.sponsored.length < 20" size="small" type="primary" icon="el-icon-plus" @click="addSponsoredPerson()">Add Sponsored Person</el-button>

    <p><b>ADDITIONAL INFORMATION:</b></p>

    <ex-textarea v-model="form.additional_info" :rows="4" :maxlength='500' no-style full />

  </div>
</template>

<script>
import numeral from 'numeral'

import Datepicker from 'vuejs-datepicker'
import ExInput from '../../../common/Input'
import ExSelect from '../../../common/Select'
import ExTextarea from '../../../common/Textarea'

const sponsorsItems = {
  sponsor_country: null,
  sponsor_canadian_status: null,
  sponsor_criminal_record: null,
  sponsor_marital_status: null,
  sponsor_medical_issues: null,
  sponsor_children: null,
  sponsor_children_num: null,
  sponsor_granted_custody: null,
  sponsor_source_of_income: null,
  sponsor_yearly_income: null,
  sponsor_relationship: null,
  sponsor_two_relationship: null,
}
const sponsoredItems = {
  sponsored_age: null,
  sponsored_country: null,
  sponsored_canadian_status: null,
  sponsored_criminal_record: null,
  sponsored_marital_status: null,
  sponsored_medical_issues: null,
  sponsored_children: null,
  sponsored_children_num: null,
  sponsored_granted_custody: null,
  sponsored_source_of_income: null,
  sponsored_yearly_income: null,
  additional_info: null
}

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    category: {
      type: String,
      default: null
    },

    client: {
      type: Object,
      required: true
    }
  },

  components: {
    ExInput,
    ExSelect,
    Datepicker,
    ExTextarea
  },

  data () {
    return {
      form: this.value,

      yesNoOptions: [
        {
          id: 'yes',
          text: 'Yes'
        },
        {
          id: 'no',
          text: 'No'
        }
      ]
    }
  },

  computed: {
    residenceStatus () {
      return window.residenceStatus
    },

    maritialStatusOptions () {
      return window.maritialStatusOptions
    },

    sponsorRelationship () {
      return window.sponsorRelationship
    },

    countriesOptions () {
      return window.countriesOptions
    },

    age () {
      return moment().diff(moment(this.client.dob), 'years')
    },

    title () {
      const titles = {
        spouse_sponsorship: 'Spouse Sponsorship',
        parents_sponsorship: 'Parents Sponsorship',
        children_sponsorship: 'Children Sponsorship'
      }
      return titles[this.category]
    },
  },

  watch: {
    form: {
      handler (form) {
        this.$emit('input', form)
      },
      deep: true,
      immediate: true
    }
  },

  created () {
    if (!Object.keys(this.form).length) {
      this.form = {
        criminal_record: null,
        canadian_status: null,
        marital_status: null,
        medical_issues: null,
        children: null,
        children_num: null,
        granted_custody: null,
        source_of_income: null,
        yearly_income: null,
        age: null,
        sponsors: [{ ...sponsorsItems }],
        sponsored: [{ ...sponsoredItems }]
      }
    }
  },

  methods: {
    formatMoney (key) {
      this.form[key] = numeral(this.form[key]).format('$0')
    },

    addSponsor () {
      this.form.sponsors.push({ ...sponsorsItems })
    },

    addSponsoredPerson () {
      this.form.sponsored.push({ ...sponsoredItems })
    }
  }
}
</script>
