import factory from '../factory'
import scopes from './scopes'
import trackers from './trackers'

const store = factory('Project')

store.mutations.addTrackerToProject = (state, { id, data }) => {
  const idx = state.data.findIndex(item => item.id == id)
  state.data[idx].trackers.push(data)
}

export default {
  ...store,
  modules: {
    scopes,
    trackers
  }
}