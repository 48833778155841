<template>
  <div>
    <div class="buttons">
      <el-button
        size="mini"
        icon="el-icon-plus"
        type="primary"
        :disabled="addNew"
        @click="addNew = true">
        New
      </el-button>
    </div>

    <div class="table-wrapper">
      <div class="info-box">
        <h3>Client File Setup</h3>
      </div>

      <el-table
        v-loading="loading"
        :data="files"
        style="min-width: 100%"
        stripe>
        <el-table-column label="ID" width="90">
          <template slot-scope="scope">
            {{ scope.row ? scope.row.customId : nextId || 'N/A' }}
          </template>
        </el-table-column>

        <el-table-column label="Status">
          <template slot-scope="scope">
            <div class="status">
              <i :class="{
                'el-icon-s-claim': scope.row,
                'el-icon-s-release': !scope.row,
                success: isSigned(scope.row),
                warning: scope.row && !isSigned(scope.row)
              }" />
            </div>
          </template>
        </el-table-column>

        <el-table-column label="Filename">
          <template slot-scope="scope">
            <template v-if="scope.row && scope.row.signedFile">
              <a :href="`/download/ClientDocument/${scope.row.signedFile.id}`" target="_blank">
                {{ scope.row.signedFile.file }}
              </a>
            </template>
            <template v-else>N/A</template>
          </template>
        </el-table-column>

        <el-table-column label="Created By">
          <template slot-scope="scope">
            <template v-if="!scope.row">N/A</template>
            <template v-else>{{ scope.row.createdBy.firstName }} {{ scope.row.createdBy.lastName }}</template>
          </template>
        </el-table-column>

        <el-table-column label="Created">
          <template slot-scope="scope">
            <template v-if="!scope.row">N/A</template>
            <template v-else>
              {{ scope.row.createdAt | date }}
            </template>
          </template>
        </el-table-column>

        <el-table-column label="Signed">
          <template slot-scope="scope">
            <template v-if="!isSigned(scope.row)">N/A</template>
            <template v-else>
              {{ scope.row.signedAt | date }}
            </template>
          </template>
        </el-table-column>

        <el-table-column label="Completed by">
          <template slot-scope="scope">
            <template v-if="!scope.row || !scope.row.completedBy">N/A</template>
            <template v-else>
              {{ scope.row.completedBy.firstName }} {{ scope.row.completedBy.lastName }}
            </template>
          </template>
        </el-table-column>

        <el-table-column
          label="Actions"
          align="right"
          width="300">
          <template slot-scope="scope">
            <span v-if="scope.row && !isSigned(scope.row)">
              <el-button size="mini" icon="el-icon-s-promotion" @click="shareModal = scope.row">Share</el-button>
            </span>
            
            <template v-if="!isSigned(scope.row)">
              <el-button size="mini" type="danger" @click="cancel(scope.row)">Cancel</el-button>
              <el-button
                size="mini"
                :type="!scope.row ? 'primary' : 'default'"
                @click="completeForm = !scope.row ? true : scope.row">
                {{ scope.row ? 'Edit' : 'Create' }}
              </el-button>
            </template>
            <template v-if="scope.row && !isSigned(scope.row)">
              <a :href="`/client/file-setup/${scope.row.id}/pdf`" target="_blank">
                <el-button size="mini">View</el-button>
              </a>
              <el-button size="mini" :disabled="!scope.row" type="primary" icon="el-icon-edit" @click="chooseFile(scope.row.id)">Upload signed</el-button>
              <input
                :ref="`upload-${scope.row.id}`"
                v-show="false"
                type="file"
                @change="uploadFile($event, scope.row.id)" />
            </template>
            
            <template v-if="isSigned(scope.row)">
              <a :href="`/download/ClientDocument/${scope.row.signedFile.id}`" target="_blank">
                <el-button size="mini">View Signed</el-button>
              </a>
              <el-button size="mini" type="danger" @click="remove(scope.row)">Remove Signed</el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <client-file-setup-modal
      v-if="completeForm"
      :data="completeForm === true ? null : completeForm"
      :next-id="nextId"
      @add="addNew = false"
      @close="completeForm = null" />

    <share
      v-if="shareModal"
      :data="shareModal"
      :action="action"
      :use-link="false"
      @close="shareModal = null" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import helperMixin from '../common/helpers-mixin'

import Share from './setup-checklist/evaluation/Share'
import ClientFileSetupModal from './setup-checklist/ClientFileSetupModal'

export default {
  name: 'ClientFiles',

  mixins: [helperMixin],

  components: {
    Share,
    ClientFileSetupModal
  },

  data () {
    return {
      type: 'ClientFileSetup',
      completeForm: null,
      shareModal: null,
      loading: false,
      addNew: false
    }
  },

  computed: {
    files () {
      const data = this.$store.state.client.fileSetup.data
      return this.addNew ? [...data, null] : data
    },

    nextId () {
      const last = this.files?.reduce((file, acc) => {
        if (!file) return acc
        if (!acc) return file
        return parseInt(file?.customId) > parseInt(acc?.customId) ? file : acc
      }, null)
     return last ? parseInt(last.customId) + 1 : 1
    },

    registration () {
      return this.$store.state.client.single.registration
    }
  },

  methods: {
    ...mapActions({
      action: 'client/fileSetup/action',
      updateFile: 'client/fileSetup/update',
      createDocument: 'client/single/createDocument',
      uploadDocument: 'client/single/uploadDocument',
      deleteFile: 'client/fileSetup/delete'
    }),

    async cancel (item) {
      if (!item) return this.addNew = false

      try {
        await this.$confirm('Are you sure you want to cancel a Client File Setup?')
        try {
          this.loading = true
          console.log(item.id)
          await this.deleteFile(item.id)
          this.$message.success('Client File Setup cancelled successfully.')
        } catch {} finally {
          this.loading = false
        }
      } catch {}
    },
      
    chooseFile (id) {
      this.$refs[`upload-${id}`].click()
    },

    isSigned (file) {
      return file?.signedAt && file?.signedFile
    },

    async remove (file) {
      this.loading = true
      await this.updateFile({
        id: file.id,
        type: this.type,
        payload: {
          signedAt: null,
          signedFile: null,
          completedBy: null
        }
      })
      
      this.loading = false
    },

    async uploadFile ($e, id) {
      let file = $e.target.files[0]
      if (file) {
        this.loading = true
        file = this.changeFileName(file, `${this.registration.firstName}-${this.registration.lastName}-client-file-setup`)
        const document = await this.createDocument({
          type: 'Client File Setup',
          clientRegId: this.registration.id,
          createdAt: 'CURRENT_TIMESTAMP'
        })
        await this.uploadDocument({ id: document.id, file })
        await this.updateFile({
          id,
          type: this.type,
          payload: {
            signedAt: 'CURRENT_TIMESTAMP',
            signedFile: document.id,
            completedBy: window.appData.currentUser.id
          }
        })
        this.loading = false
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.table-wrapper {
  margin-top: 20px;
}
.status {
  font-size: 30px;
  margin-right: 10px;
  .success {
    color: #67c23a;
  }
  .warning {
    color: orange;
  }
}
</style>