<template>
  <div class="page-form">
    <p class="text-center">Permanent Residence – PEQ</p>
    
    <p><b>PERSONAL INFORMATION</b></p>

    <p>{{ client.firstName }} - {{ client.lastName }} - {{ age }} - {{ client.nationality | country }} - {{ client.residence | country }}</p>

    <p>Do you have a criminal record? <span class="answer">{{ formatYN(value.criminal_record) }}</span></p>

    <p>Are you able to communicate in French? <span class="answer">{{ formatYN(value.french) }}</span> Do you have a valid CSQ? <span class="answer">{{ formatYN(value.valid_csq) }}</span>
      <br>Are you a Temporary worker in Quebec <span class="answer">{{ formatYN(value.worker_in_quebec) }}</span></p>

    <p>How long have you been working in Quebec? <span class="answer">{{ value.working_months }}</span> months</p>

    <p>Do you work
      <span class="checkbox-group"><input type="checkbox" :disabled="true" :checked="value.full_time"> Full time</span>
      <span class="checkbox-group"><input type="checkbox" :disabled="true" :checked="value.part_time"> Part time</span></p>

    <p>How did you obtain your work permit? <span class="answer">{{ value.permit }}</span>

    <p><b>ADDITIONAL INFORMATION:</b></p>
    <span class="answer">{{ value.additional_info }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    client: {
      type: Object,
      required: true
    }
  },

  computed: {
    age () {
      return moment().diff(moment(this.client.dob), 'years')
    }
  },

  methods: {
    formatYN (val) {
      return val === 'yes' ? 'Yes' : 'No'
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox-group {
  margin-left: 50px;
  input {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
}
</style>