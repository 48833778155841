<template>
  <div>
    <ch-button
      size="sm"
      :disabled="disablePrev"
      @click="prev()">
      <
    </ch-button>
    <div class="calendar-selected-date">
      {{ monthName }}
      <template v-if="day">
        {{ day }}
      </template>
      <select :value="year" @change="updateYear($event.target.value)">
        <option
          v-for="y in yearsOptions"
          :key="y"
          :value="y">
          {{ y }}
        </option>
      </select>
    </div>
    <ch-button
      size="sm"
      :disabled="disableNext"
      @click="next()">
      >
    </ch-button>
  </div>
</template>

<script>
import ChButton from '../common/ChButton'

export default {
  name: 'CalendarDateSelect',

  components: {
    ChButton
  }, 

  props: {
    year: {
      type: Number,
      required: true
    },

    month: {
      type: Number,
      required: true
    },

    day: {
      type: Number,
      default: null
    }
  },
  
  data () {
    return {
      yearFrom: 2018,
      currentYear: (new Date()).getFullYear()
    }
  },

  computed: {
    disablePrev () {
      return this.year === this.yearFrom && this.month === 0
    },

    disableNext () {
      return this.year === this.currentYear + 1 && this.month === 11
    },

    monthName () {
      return window.months[this.month]
    },

    yearsOptions () {
      const data = []
      for(let i = this.currentYear + 1; i >= this.yearFrom; i--)
      data.push(i)
      return data
    },

    daysInMonth() {
      return this.getDaysInMonth(this.year, this.month)
    },

    daysInLastMonth() {
      let date = moment(`${this.year}-${this.month}`, 'YYYY-MM').subtract(1, 'months')
      return this.getDaysInMonth(date.format('YYYY'), date.format('MM'))
    }
  },

  methods: {
    getDaysInMonth (year, month) {
      return 32 - new Date(year, month, 32).getDate()
    },

    updateYear (year) {
      this.$emit('update:year', parseInt(year))
    },

    updateMonth (month) {
      this.$emit('update:month', month)
    },

    setDay (day) {
      this.$emit('day', day)
    },

    next () {
      if (this.day) {
        if (this.day + 1 > this.daysInMonth)
          this.setDay(1)
        else
          return this.setDay(this.day + 1)
      }
      if (this.month + 1 > 11) {
        this.updateMonth(0)
        this.updateYear(this.year + 1)
      } else {
        this.updateMonth(this.month + 1)
      }
    },

    prev () {
      if (this.day) {
        if (this.day - 1 < 1)
          this.setDay(this.daysInLastMonth)
        else
          return this.setDay(this.day - 1)
      }
      if (this.month - 1 < 0) {
        this.updateMonth(11)
        this.updateYear(this.year - 1)
      } else {
        this.updateMonth(this.month - 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .calendar-selected-date {
    display: inline-block;
    vertical-align: middle;
    line-height: 30px;
    width: 190px;
    margin: 0 5px;
    select {
      float: right;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 0 10px;
      border: 1px solid #b2b2b2;
      height: 30px;
      outline: none;
    }
  }
</style>


