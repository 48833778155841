<template>
  <div class="page-form">
    <p class="text-center">Family Reunification</p>
    
    <p><b>PERSONAL INFORMATION</b></p>

    <p>{{ client.firstName }} - {{ client.lastName }} - {{ age }} - {{ client.nationality | country }} - {{ client.residence | country }}</p>

    <p>Do you have a criminal record? <span class="answer">{{ formatYN(value.criminal_record) }}</span></p>

    <div v-for="(sponsor, key) in value.sponsors" :key="`sponsors_${key}`">
      <p><b>SPONSOR {{ key + 1 }}</b></p>

      <p>
        <template v-if="key >= 1">
          Age <span class="answer">{{ value.sponsors[key].age }}</span>
          Country of Residence <span class="answer">{{ formatCountriesOptions(value.sponsors[key].sponsor_country) }}</span>
        </template>
        Canadian Status <span class="answer">{{ formatResidenceStatus(value.sponsors[key].sponsor_canadian_status) }}</span>
      </p>
    
      <p v-if="key >= 1">Do you have a criminal record? <span class="answer">{{ formatYN(value.sponsors[key].sponsor_criminal_record) }}</span></p>

      <p>Marital status <span class="answer">{{ formatMaritialStatus(value.sponsors[key].sponsor_marital_status) }}</span></p>

      <p>Medical issues <span class="answer">{{ formatYN(value.sponsors[key].sponsor_medical_issues) }}</span>
      Children under 18 y/o <span class="answer">{{ formatYN(value.sponsors[key].sponsor_children) }}</span>
      How many <span class="answer">{{ value.sponsors[key].sponsor_children_num }}</span>
      Granted custody <span class="answer">{{ formatYN(value.sponsors[key].sponsor_granted_custody) }}</span></p>

      <p>Source of income <span class="answer">{{ value.sponsors[key].sponsor_source_of_income }}</span> 
      Yearly income <span class="answer">{{ value.sponsors[key].sponsor_yearly_income }}</span></p>
    </div>

    <div v-for="(person, k) in value.sponsored" :key="`sponsored_${k}`">

      <p><b>SPONSORED PERSON {{ k + 1 }}</b></p>

      <p>Relationship with Sponsor {{ k }} <span class="answer">{{ formatSponsorRelationship(value.sponsored[k].sponsor_two_relationship) }}</span></p>

      <p v-if="value.sponsors.length > 1">Relationship with Sponsor 2 <span class="answer">{{ formatSponsorRelationship(value.sponsored[k].sponsor_relationship) }}</span></p>

      <p>Age <span class="answer">{{ value.sponsored[k].sponsored_age }}</span> 
      Country of Residence <span class="answer">{{ formatCountriesOptions(value.sponsored[k].sponsored_country) }}</span> 
      Canadian Status <span class="answer">{{ formatResidenceStatus(value.sponsored[k].sponsored_canadian_status) }}</span></p>
      
      <p>Do you have a criminal record? <span class="answer">{{ formatYN(value.sponsored[k].sponsored_criminal_record) }}</span></p>

      <p>Marital status <span class="answer">{{ formatYN(value.sponsored[k].sponsored_marital_status) }}</span></p>

      <p>Medical issues <span class="answer">{{ formatYN(value.sponsored[k].sponsored_medical_issues) }}</span> 
      Children under 18 y/o <span class="answer">{{ formatYN(value.sponsored[k].sponsored_children) }}</span> 
      How many <span class="answer">{{ value.sponsored[k].sponsored_children_num }}</span>
      Granted custody <span class="answer">{{ formatYN(value.sponsored[k].sponsored_granted_custody) }}</span></p>

      <p>Source of income <span class="answer">{{ value.sponsored[k].sponsored_source_of_income }}</span> 
      Yearly income <span class="answer">{{ value.sponsored[k].sponsored_yearly_income }}</span></p>

    </div>

    <p><b>ADDITIONAL INFORMATION:</b></p>

    <span class="answer">{{ value.additional_info }}</span>

  </div>
</template>

<script>
import numeral from 'numeral'

import Datepicker from 'vuejs-datepicker'
import ExInput from '../../../common/Input'
import ExSelect from '../../../common/Select'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    category: {
      type: String,
      default: null
    },

    client: {
      type: Object,
      required: true
    }
  },
  
  computed: {
    age () {
      return moment().diff(moment(this.client.dob), 'years')
    },

    sponsoredPersons () {
      const numOfPersons = []
      const splited =  Object.keys(this.value).map(key => {
        return key.split('_')[0] + '_' + key.split('_')[1]
      });
      [].forEach.call(splited, el => {
        if (el === 'sponsored_canadian') numOfPersons.push(el)
      })
      return numOfPersons
    },

    title () {
      const titles = {
        spouse_sponsorship: 'Spouse Sponsorship',
        parents_sponsorship: 'Parents Sponsorship',
        children_sponsorship: 'Children Sponsorship'
      }
      return titles[this.category]
    },
  },

  methods: {
    formatYN (val) {
      return val === 'yes' ? 'Yes' : 'No'
    },

    formatResidenceStatus(status) {
      const residenceStatus = window.residenceStatus
      return residenceStatus.find( item => item.id === status).text
    },

    formatMaritialStatus(status) {
      const maritialStatusOptions = window.maritialStatusOptions
      return maritialStatusOptions.find( item => item.id === status).text
    },

    formatCountriesOptions(status) {
      const countriesOptions = window.countriesOptions
      return countriesOptions.find( item => item.id === status).text
    },

    formatSponsorRelationship(status) {
      console.log(this.sponsoredPersons)
      const sponsorRelationship = window.sponsorRelationship
      return sponsorRelationship.find( item => item.id === status).text
    },
  }
}
</script>
