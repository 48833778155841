<template>
  <modal
    title="Create New User"
    :width="800"
    @close="$emit('close')">
    <form class="form">
      <ex-input v-model="form.firstName" label="First Name"  :validator="$v.form.firstName" />
      <ex-input v-model="form.lastName" label="Last Name"  :validator="$v.form.lastName" />
      <ex-input v-model="form.email" label="Email" type="email" :validator="$v.form.email" />
      <ex-input v-model="form.phone" label="Phone" type="number" :validator="$v.form.phone" />
      <ex-input v-model="form.phoneExt" label="Phone Ext" type="number" :validator="$v.form.phoneExt" />
    </form>

    <div class="buttons">
      <el-button @click="$emit('close')">Cancel</el-button>
      <el-button type="primary" @click="save()">Create</el-button>
    </div>
  </modal>
</template>

<script>
import Modal from '../common/Modal'
import ExInput from '../common/Input'
import ExSelect from '../common/Select'

import { phone } from '../common/validators'
import { required, numeric, email, alpha } from 'vuelidate/lib/validators'

export default {
  components: {
    Modal,
    ExInput,
    ExSelect
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      form: {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        phoneExt: null
      }
    }
  },

  methods: {
    async save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const loading = this.$loading()
        const payload = {
          ...this.form,
          role: 'client',
          created: 'CURRENT_TIMESTAMP',
          uuid: 'GET_USER_UUID',
          activationCode: 'USER_ACTIVATION_CODE',
          activationCodeExpires: 'USER_ACTIVATION_CODE_EXPIRES',
          createdBy: this.id
        }
        const user = (await api.create('User', payload, true)).item
        await api.action('User', user.id, 'WelcomeEmail')
        this.$alert(`New user successfully created.<br><br>Generated Activation Code is: <b>${user.activationCode}</b>`, 'New User Created', {
          confirmButtonText: 'OK',
          dangerouslyUseHTMLString: true
        })
        loading.close()
        this.$emit('save')      
      }
    }
  },

  validations: {
    form: {
      firstName: { required, alpha },
      lastName: { required, alpha },
      email: { required, email },
      phone: { required }
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: center;
}
</style>
