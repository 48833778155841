export default {
  computed: {
    isUserAdmin () {
      return ['system-admin', 'admin'].includes(this.user.role)
    },

    user () {
      return window.appData.currentUser
    },
    
    countryOptions () {
      const options = window.countriesOptions.map(country => {
        return {
          id: country.id,
          text: country.text
        }
      })
      options.sort(function(a, b) {
        var textA = a.text.toUpperCase();
        var textB = b.text.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      })
      return options
    },

    serviceOptions () {
      return Object.keys(window.services).map(key => {
        return {
          id: key,
          text: this.$tc(`services.${key}`)
        }
      })
    }
  },

  methods: {
    getAlphabeticalOL (number) {
      const alphabet = '-abcdefghijklmnopqrstuvwxyz'.split('')
      const prefix =  number <= 26 ? null : Math.floor((number - 1) / 26)
      let index =  number <= 26 ? number : (number) % 26
      index = index === 0 ? 26 : index
      return prefix !== null ? alphabet[prefix] + alphabet[index] : alphabet[index]
    },

    changeFileName (file, name) {
      const ext = file.name.substr(file.name.lastIndexOf('.') + 1)
      const blob = file.slice(0, file.size, file.type);
      const fileName = name + '.' + ext
      return new File([blob], fileName, { type: file.type })
    },

    getUrl (name) {
      let url = location.href
      name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]")
      var regexS = "[\\?&]"+name+"=([^&#]*)"
      var regex = new RegExp( regexS )
      var results = regex.exec( url )
      return results == null ? null : results[1]
    },

    isImage (file) {
      var parts = file.name.split('.');
      const ext = parts[parts.length - 1];
      const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(ext)
      if (!isImage)
        this.$message.error('Selected file type is not accepted. Accpted file types are: JPG, JPEG, PNG, GIF')
      return isImage
    },

    getMax (arr) {
      return arr.reduce((a, b) => Math.max(parseInt(a), b))
    }
  }
}
