<template>
  <div class="page-form">
    <p class="text-center">Legal - Authorization to Return to Canada</p>
    
    <p><b>PERSONAL INFORMATION</b></p>

    <p>{{ client.firstName }} - {{ client.lastName }} - {{ age }} - {{ client.nationality | country }} - {{ client.residence | country }}</p>

    <p>Do you have a criminal record? <ex-select v-model="form.criminal_record" :options="yesNoOptions" no-style /></p>

    <p><b>MAIN APPLICANT</b></p>

    <p>When was your last entry to Canada? <datepicker v-model="form.entry_canada" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="Date" style="width: 155px; display: inline-block"/> When did you leave Canada? <datepicker v-model="form.leave_canada" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="Date" style="width: 155px; display: inline-block"/></p>

    <p>What was your status when you left Canada? <ex-select v-model="form.current_status" :options="currentStatus" no-style /></p>

    <p>Which type of removal order did you receive? <ex-select v-model="form.removal_order" :options="removalOrder" no-style /></p>

    <p>Did you try to return to Canada? <ex-select v-model="form.return_to_Canada" :options="yesNoOptions" no-style /></p>

    <p><b>ADDITIONAL INFORMATION:</b></p>

    <ex-textarea v-model="form.additional_info" :rows="4" :maxlength='500' no-style full />

  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import ExInput from '../../../common/Input'
import ExSelect from '../../../common/Select'
import ExTextarea from '../../../common/Textarea'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    client: {
      type: Object,
      required: true
    }
  },

  components: {
    ExInput,
    ExSelect,
    Datepicker,
    ExTextarea
  },

  data () {
    return {
      form: this.value,

      yesNoOptions: [
        {
          id: 'yes',
          text: 'Yes'
        },
        {
          id: 'no',
          text: 'No'
        }
      ]
    }
  },

  computed: {
    currentStatus () {
      return window.currentStatus
    },

    removalOrder () {
      return window.removalOrder
    },

    age () {
      return moment().diff(moment(this.client.dob), 'years')
    }
  },

  watch: {
    form: {
      handler (form) {
        this.$emit('input', form)
      },
      deep: true,
      immediate: true
    }
  },

  created () {
    if (!Object.keys(this.form).length) {
      this.form = {
        criminal_record: null,
        entry_canada: null,
        leave_canada: null,
        current_status: null,
        removal_order: null,
        return_to_Canada: null,
        additional_info: null
      }
    }
  }
}
</script>
