<template>
  <div class="panel" :class="{ border }">
    <div v-if="title" class="panel-header">
      <h3>{{ title }}</h3>
    </div>
    <div class="panel-body" :style="{ padding }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },

    padding: {
      type: String,
      default: '15px 20px'
    },

    border: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss">
  .panel {
    width: 100%;
    background-color: #fff;
    &.border {
      border: 1px solid rgba(0, 0, 0, .1);
    }
    .panel-header {
      padding: 15px 20px;
      background-color: #000;
      text-align: center;
      font-size: 22px;
      color: #fff;
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      margin: 0;
    }
  }
</style>