<template>
  <div class="page-form">
    <p class="text-center">Legal - Authorization to Return to Canada</p>
    
    <p><b>PERSONAL INFORMATION</b></p>

    <p>{{ client.firstName }} - {{ client.lastName }} - {{ age }} - {{ client.nationality | country }} - {{ client.residence | country }}</p>

    <p>Do you have a criminal record? <span class="answer">{{ formatYN(value.criminal_record) }}</span></p>

    <p><b>MAIN APPLICANT</b></p>

    <p>When was your last entry to Canada? <span class="answer">{{ value.entry_canada | date('ll') }}</span> When did you leave Canada? <span class="answer">{{ value.leave_canada | date('ll') }}</span></p>

    <p>What was your status when you left Canada? <span class="answer">{{ formatCurrentStatus(value.current_status) }}</span></p>

    <p>Which type of removal order did you receive? <span class="answer">{{ formatRemovalOrder(value.removal_order) }}</span></p>

    <p>Did you try to return to Canada? <span class="answer">{{ formatYN(value.return_to_Canada) }}</span></p>

    <p><b>ADDITIONAL INFORMATION:</b></p>

    <span class="answer">{{ value.additional_info }}</span>

  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    client: {
      type: Object,
      required: true
    }
  },

  computed: {
    age () {
      return moment().diff(moment(this.client.dob), 'years')
    }
  },

  methods: {
    formatYN (val) {
      return val === 'yes' ? 'Yes' : 'No'
    },

    formatCurrentStatus (status) {
      const currentStatus = window.currentStatus
      return currentStatus.find( item => item.id === status).text
    },

    formatRemovalOrder(status) {
      const removalOrder = window.removalOrder
      return removalOrder.find( item => item.id === status).text
    }
  }
}
</script>
