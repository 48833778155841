<template>
  <modal
    title="Reset Password"
    :width="400"
    @close="$emit('close')">
    <div class="content">
      <div class="data">
        Account ID: <b>{{ user.uuid }}</b><br>
        Username: <b>{{ user.email }}</b>
      </div>

      <label class="label">Set Password:</label>
      <input v-model="form.password" type="password" class="form-inp" ref="input" />
      <span v-if="showError($v.form.password)" class="input-error">Password needs to contain small letter, capital letter, number, symbol and at least 6 characters.</span>

      <label class="label">Confirm Password:</label>
      <input v-model="form.confirmPassword" type="password" class="form-inp" />
      <span v-if="showError($v.form.confirmPassword)" class="input-error">Passwords do not match.</span>

      <button class="login-button" @click="reset()">Reset</button>

      <div class="sublink">
        <a @click="$emit('close')">Cancel</a>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from './Modal'

import { required, sameAs } from 'vuelidate/lib/validators'
import { passwordValidation } from './validators'

export default {
  props: {
    user: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      form: {
        password: null,
        confirmPassword: null
      }
    }
  },

  components: {
    Modal
  },

  mounted () {
    this.$refs.input.focus()
  },

  methods: {
    showError (item) {
      return item.$dirty && item.$invalid
    },

    async reset () {
      if (!this.$v.$invalid) {
        const payload = { password: this.form.password }
        try {
          await api.action('User', this.user.id, 'SetPassword', payload)
          this.$message.success('You have changed your password successfully. You can login now.')
          this.$emit('reset')
        } catch (e) {
          this.$message.error('There was an error... Please try again.')
        }
      } else {
        this.$v.$touch()
      }
    }
  },

  validations: {
    form: {
      password: { required, passwordValidation },
      confirmPassword: { required, sameAsPassword: sameAs('password') }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 15px 30px;
  text-align: center;
  .data {
    margin-bottom: 20px;
    line-height: 1.5;
  }
  .label {
    display: block;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .form-inp {
    display: block;
    text-align: center;
  }
  .sublink {
    margin-top: 10px;
  }
}
</style>