<template>
  <modal
    :title="`${text} Subscription`"
    :width="400"
    @close="$emit('close')">
    <div class="form-group">
      <ex-select v-model="form.day" :options="dayOptions" :validator="$v.form.day" label="Day" no-margin full />
    </div>
    <div class="form-group">
      <ex-select v-model="form.frequency" :options="frequencyOptions" :validator="$v.form.frequency" label="Frequency" no-margin full />
    </div>
    <div class="form-group">
      <ex-textarea v-model="form.note" :validator="$v.form.note" label="Note" rows="3" :maxlength="250" full no-margin />
    </div>
    <div class="form-group">
      <ex-input v-model.number="form.amount" :validator="$v.form.amount" label="Amount" full no-margin @blur="formatCurrency()" />
    </div>

    <div class="buttons">
      <el-button @click="$emit('close')">Cancel</el-button>
      <el-button type="primary" @click="save()">{{ text }}</el-button>
    </div>
  </modal>
</template>

<script>
import numeral from 'numeral'
import { required } from 'vuelidate/lib/validators'

import Modal from '../../common/Modal'
import ExInput from '../../common/Input'
import ExSelect from '../../common/Select'
import ExTextarea from '../../common/Textarea'

export default {
  components: {
    Modal,
    ExInput,
    ExSelect,
    ExTextarea
  },

  props: {
    edit: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      form: {
        day: null,
        frequency: null,
        note: null,
        amount: null
      },
    }
  },
  
  computed: {
    client () {
      return this.$store.state.client.single.registration
    },

    text () {
      return this.edit ? 'Edit' : 'Create'
    },

    frequencyOptions () {
      return [
        {
          id: 'monthly',
          text: 'Monthly'
        }
      ]
    },

    dayOptions () {
      const arr = []
      for (let i = 1; i <= 31; i++) arr.push(i)
      return arr.map(i => {
        return {
          id: i,
          text: i
        }
      })
    }
  },

  created () {
    if (this.edit) Object.keys(this.form).forEach(key => this.form[key] = this.edit[key])
  },

  methods: {
    async save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const payload = { ...this.form }
        try {
          if (this.edit) {
            await this.$store.dispatch('client/subscriptions/update', { id: this.edit.id, payload })
          } else {
            payload.client = this.client.id
            payload.createdAt = 'CURRENT_TIMESTAMP'
            payload.createdBy = window.appData.currentUser.id
            await this.$store.dispatch('client/subscriptions/create', payload)
          }
          this.$message.success(`Subscription successfully ${this.edit ? 'edited' : 'created'}.`)
          this.$emit('close')
        } catch (e) {
          this.$message.error('There was an error... Please try again.')
        }
      }
    },

    formatCurrency () {
      this.form.amount = numeral(this.form.amount).format('$0.0000')
    }
  },

  validations () {
    const rules = {
      form: {
        day: { required },
        frequency: { required },
        note: { required },
        amount: { required },
      }
    }
    return rules
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: center;
}
</style>