import jQuery from 'jquery'
window.$=jQuery

import moment from 'moment'
window.moment = moment

import Vue from 'vue'

window.Vue=Vue

import Vuelidate from 'vuelidate'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import VueTextareaAutosize from 'vue-textarea-autosize'

// import 'element-ui/lib/theme-chalk/index.css';
import './lib/date-formatter'
import './lib/globals'
import './lib/api'
import './lib/login'
import './lib/filters'

var VueComponents = {}

var vueComponentList = [
  require.context("./components", false, /.+\.vue$/),
  require.context("./components/auth", false, /.+\.vue$/),
  require.context("./components/system", false, /.+\.vue$/),
  require.context("./components/users", false, /.+\.vue$/),
  require.context("./components/clients", false, /.+\.vue$/),
  require.context("./components/common", false, /.+\.vue$/),
  require.context("./components/tools", false, /.+\.vue$/),
  // require.context("./components/calendar", false, /.+\.vue$/)
];

Vue.use(VueTextareaAutosize)
Vue.use(ElementUI, { locale })
Vue.use(Vuelidate)

for(var vueComponentListItem of vueComponentList) {
  for(var key of vueComponentListItem.keys()) {
    let actualKey=key.replace(/\.vue|.*\//g, "")
    let actualComponent=vueComponentListItem(key).default
    VueComponents[actualKey]=actualComponent
    Vue.component(actualKey, actualComponent)
  }
}

import './lib/vuedyn'
