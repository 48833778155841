<template>
  <div>
    <div class="space-between mb-20">
      <h1 class="page-title">{{ title }}</h1>

      <el-button
        v-if="filtersMap"
        size="medium"
        icon="el-icon-s-operation"
        @click="openFilters = true" />
    </div>

    <applied-filters
      v-if="filters"
      :filters="filters"
      :map="filtersMap"
      @remove="removeFilter($event)"
      @clear="clearFilters()" />

    <filters-drawer
      v-if="openFilters"
      :filters="filters"
      :map="filtersMap"
      @close="openFilters = false"
      @apply="applyFilters" />
  </div>
</template>

<script>
import helpersMixin from '../common/helpers-mixin'

import AppliedFilters from './AppliedFilters'
import FiltersDrawer from '../common/FiltersDrawer'

export default {
  mixins: [helpersMixin],

  components: {
    FiltersDrawer,
    AppliedFilters
  },

  props: {
    title: {
      type: String,
      default: ''
    },

    filters: {
      type: Object,
      default: null
    },

    filtersMap: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      openFilters: false
    }
  },

  methods: {
    applyFilters (filters) {
      this.$emit('apply', filters)
      this.openFilters = false
    },

    removeFilter (filter) {
      console.log(filter)
      const newFilters = JSON.parse(JSON.stringify(this.filters))
      newFilters[filter.key] = undefined
      this.applyFilters(newFilters)
    },

    clearFilters () {
      this.applyFilters({})
    }
  }
}
</script>