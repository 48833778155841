<template>
<div class="form-popup popup-detail activex" :class="{ active: value }" v-if="value">
  <div class="ncontent appnat" :style="autoWidth ? 'width: auto;' : null">
    <div class="title"><a class="close" @click.prevent="$emit('close')">&times;</a></div>
    <slot />
  </div>
</div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    
    autoWidth: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-popup {
    position: fixed;
    background: rgba(0,0,0,0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    z-index: 10;
  }

  .form-popup>div {
    margin: auto;
    position: relative;
  }

  .form-popup>div>.ncontent>.close {
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    background: #b6b6b6;
    border-radius: 12px;
    font-size: 16px;
    color: white;
    line-height: 24px;
    text-align: center;
  }

  .form-popup>div>.ncontent>h3 {
    text-align: center;
  }


  .content-table tr.selected-plan td {
    background: #91e863;
  }

  .appf .notes.important {
    color: #ff4d4d;
  }



  .autocomplete {
    position: relative;
    width: 100%;
  }
  .autocomplete input {
    width: 100%;
  }
  .autocomplete .options {
    position: absolute;
    left: 0;
    top: 100%;
    margin-top: -1px;
    background: white;
    border: 1px solid #999;
    color: #666;
    min-width: 100%;
    box-sizing: border-box;
    z-index: 20;
    text-align: left;
  }
  .autocomplete .options .option {
    padding: 0 5px;
  }
  .autocomplete .options .option.current {
    background: #ced2fd;
  }


  .content-table.file-list td a[href].button {
    display: block;
    background: #357aba;
    padding: 4px 8px;
    color: white;
    margin-right: 4px;
    box-shadow: 0px 0px 0px 1px white;
  }


  .appf .column-form li div.regions-list label:first-of-type,
  .appf .column-form li div.regions-list label {
    width: auto;
    margin-left: 20px;
  }
  .popup-detail .title {
    background: #fff;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    padding: 30px;
    .close {
      display: block;
      float: right;
      width: 24px;
      height: 24px;
      background: #b6b6b6;
      border-radius: 12px;
      font-size: 16px;
      color: #fff;
      line-height: 24px;
      text-align: center;
      cursor: pointer;
    }
  }
  .ncontent {
    position: relative;
    box-sizing: border-box;
    margin: 90px auto;
    background: white;
    max-width: calc(100% - 50px);
    width: 1100px;
    box-shadow: 0px 3px 9px rgba(0,0,0,.1);
    border-radius: 4px;
    border: 1px solid rgba(107, 107, 107, .23);
  }
  .ncontent.wide {
    width: auto;
  }
  .ncontent .header {
    padding: 30px;
  }
  .ncontent .content {
    padding: 30px;
    background: #e6e6e6;
  }
</style>
