<template>
  <div class="page-form">
    <p class="text-center">Permanent Residence – PEQ</p>
    
    <p><b>PERSONAL INFORMATION</b></p>

    <p>{{ client.firstName }} - {{ client.lastName }} - {{ age }} - {{ client.nationality | country }} - {{ client.residence | country }}</p>

    <p>Do you have a criminal record? <ex-select v-model="form.criminal_record" :options="yesNoOptions" no-style /></p>

    <p>Are you able to communicate in French? <ex-select v-model="form.french" :options="yesNoOptions" no-style /> Do you have a valid CSQ? <ex-select v-model="form.valid_csq" :options="yesNoOptions" no-style />
      <br>Are you a Temporary worker in Quebec <ex-select v-model="form.worker_in_quebec" :options="yesNoOptions" no-style /></p>

    <p>How long have you been working in Quebec? <ex-input v-model.number="form.working_months" :disabled="!isWorkingInQuebec" type="number" no-style /> (months)</p>

    <p>Do you work
      <span class="checkbox-group"><input type="checkbox" :disabled="!isWorkingInQuebec" :checked="full_time" @change="onChange('full_time', $event)"> Full time</span>
      <span class="checkbox-group"><input type="checkbox" :disabled="!isWorkingInQuebec" :checked="part_time" @change="onChange('part_time', $event)"> Part time</span></p>

    <p>How did you obtain your work permit? <ex-input v-model="form.permit" :disabled="!isWorkingInQuebec" :maxlength="150" full no-style /></p>

    <p><b>ADDITIONAL INFORMATION:</b></p>

    <ex-textarea v-model="form.additional_info" :rows="4" no-style full />
  </div>
</template>

<script>
import numeral from 'numeral'

import ExInput from '../../../common/Input'
import ExSelect from '../../../common/Select'
import ExTextarea from '../../../common/Textarea'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    client: {
      type: Object,
      required: true
    }
  },

  components: {
    ExInput,
    ExSelect,
    ExTextarea
  },

  data () {
    return {
      form: this.value,

      full_time: false,
      part_time: false,
  
      yesNoOptions: [
        {
          id: 'yes',
          text: 'Yes'
        },
        {
          id: 'no',
          text: 'No'
        }
      ]
    }
  },

  computed: {
    isWorkingInQuebec () {
      return this.form.worker_in_quebec === 'yes'
    },

    age () {
      return moment().diff(moment(this.client.dob), 'years')
    }
  },

  watch: {
    form: {
      handler (form) {
        this.$emit('input', form)
      },
      deep: true,
      immediate: true
    },

    'form.worker_in_quebec' () {
      this.form.working_months = null
      this.form.work_permit = null
      this.form.full_time = false
      this.form.part_time = false
      this.full_time = false
      this.part_time = false
    }
  },

  created () {
    if (!Object.keys(this.form).length) {
      this.form = {
        criminal_record: null,
        french: null,
        valid_csq: null,
        worker_in_quebec: null,
        working_months: null,
        full_time: false,
        part_time: false,
        work_permit: null,
        additional_info: null
      }
    }
    this.full_time = this.form.full_time
    this.part_time = this.form.part_time
  },

  methods: {
    onChange (key, event) {
      const other = key === 'full_time' ? 'part_time' : 'full_time'
      this[key] = !this[key]
      this.form[key] = this[key]
      if (this[key] || this[other]) {
        this[other] = !this[key]
        this.form[other] = !this[key]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox-group {
  margin-left: 50px;
  input {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
}
</style>