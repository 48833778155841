<template>
  <layout active="/scheduler/add-event">
    <div>
      <schedulerMenu :page="currentPage" />

      <panel :title="currentPage.text">
        <div class="scheduler-panel">
          <div class="panel-title">
            <div class="panel-date">
              <chButton color="gray" @click="clear()">Clear Event</chButton>
              <chButton @click="save()">Save Event</chButton>
            </div>
          </div>

          <createEventForm type="SCHEDULER" ref="addEvent" />
        </div>
      </panel>
    </div>
  </layout>
</template>

<script>
import SchedulerMenu from './SchedulerMenu'
import ChButton from '../common/ChButton'
import CreateEventForm from '../calendar/CreateEventForm'
import Layout from '../common/Layout'

export default {
  name: 'SchedulerAddEvent',

  components: {
    SchedulerMenu,
    ChButton,
    CreateEventForm,
    Layout
  },

  data () {
    return {
      currentPage: {
        text: 'Add New Event',
        breadcrumbs: [{
          text: 'Calendar',
          to: '/tools/scheduler/calendar'
        }]
      }
    }
  },

  methods: {
    save () {
      this.$refs.addEvent.save()
    },

    clear () {
      this.$refs.addEvent.clear()
    }
  }
}
</script>
