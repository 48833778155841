<template>
<div class="wcontent dashboard">
  <div class="dash-row">
    <div class="col" />
    <div class="col">
      <table class="breakdown-table">
        <thead>
          <tr>
            <th colspan="2">My Calendar</th>
          </tr>
          <tr><th colspan="2" class="spacer"></th></tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="2">
              <div class="font-16 mb-10">Todays Events:</div>
              <div class="event-list">
                <template v-if="events">
                  <ul v-if="events.length" class="list">
                    <li
                      v-for="event in events"
                      :key="event.id"
                      class="list-item">
                      <div
                        class="circle"
                        :class="event.color ? `event-${event.color.toLowerCase()}` : 'event-blue'" />
                      <b>{{ formatEventTime(event) }}</b>: {{ event.subject }}
                      <a :href="getUrl(event.id)">
                        <chButton class="floatr" size="xs">View</chButton>
                      </a>
                    </li>
                  </ul>
                  <span v-else>No Events Today.</span>
                </template>
                <div v-else>
                  Loading...
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col" />
  </div>
</div>
</template>

<script>
import ChButton from '../common/ChButton'

export default {
  name: 'SchedulerDashboard',

  data () {
    return {
      events: null
    }
  },

  computed: {
    today () {
      return moment().format('YYYY-MM-DD')
    }
  },

  created() {
    this.loadEvents()
  },

  methods: {
    getUrl (id) {
      let url = `/tools/scheduler/calendar/date-view/${this.today}`
      return id ? `${url}?event=${id}` : url
    },

    isAssigned (item) {
      return ['AMA11', 'AMA12', 'AMA13'].includes(item.status)
    },

    async loadEvents () {
      const userId = window.appData.currentUser.id
      const payload = {
        type: 'SCHEDULER',
        createdBy: userId,
        date_from: ["LIKE", `${this.today}%`]
      }
      this.events = (await api.list("Event", payload)).items
      payload.createdBy = ['!=', userId]
      payload['employees.user_id'] = userId
      this.events = this.events.concat((await api.list("Event", payload)).items)
    },

    formatEventTime (event) {
      const from = moment(event.date_from, 'YYYY-MM-DD HH:mm:ss').format('h:mm A')
      const to = moment(event.date_to, 'YYYY-MM-DD HH:mm:ss').format('h:mm A')
      return `${from} - ${to}`
    }
  }
}
</script>

<style lang="scss" scoped>
  .dash-row {
  display: flex;
  margin: 0 -15px;
  }
  .dash-row + .dash-row {
    margin-top: 30px;
  }
  .dash-row .col {
    padding: 0 15px;
    flex-grow: 1;
    width: 33.3333%;
    flex-shrink: 1;
    flex-grow: 0;
  }
  .dash-row .col table + table {
    margin-top: 30px;
  }
  .dashboard {
    margin: auto;
    padding: 20px;
    max-width: 1080px;
    width: 100%; 
    background: white;
    margin-top: 30px;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px 50px 50px;
    h2 {
    font-size: 38px;
    margin: 10px 0 24px;
    }
    .breakdown-table { 
      width: 100%; 
      border: 1px solid rgba(0, 0, 0, 0.2);
      th {
      height: 42px;
      font-size: 18px;
      font-weight: bold;
      line-height: 42px;
      text-transform: uppercase;
      background-color: #000;
      color: #ffffff;
        &:first-of-type {
          text-align: left;
          padding-left: 16px;
        }
        &.spacer { 
          height: 10px; 
          background-color: #ffffff;
        }
      }
      td{
        height: 30px;
        font-weight: 700;
        text-align: center;
        background: #e5e5e5;
        &:first-of-type {
          text-align: left;
          padding-left: 16px;
          font-size: 12px;
          font-weight: 400;
          margin-left: 1px;
          .font-16 { font-size: 16px; }
          .mb-10 { margin-bottom: 10px; }
        }
      }
    }
  }
</style>
