<template>
  <div class="applied-filters">
    <el-tag
      v-for="filter in appliedFilters"
      :key="filter.key"
      type="info"
      effect="plain"
      class="applied-filter"
      size="small"
      closable
      @close="$emit('remove', filter)">
      {{ filter.label }}: {{ filter.text }}
    </el-tag>

    <el-button
      v-if="appliedFilters.length > 1"
      size="mini"
      type="info"
      round
      @click="$emit('clear')">
      <i class="el-icon-close" /> Clear Filters
    </el-button>
  </div>
</template>

<script>
export default {
  props: {
    filters: {
      type: Object,
      required: true
    },

    map: {
      type: Array,
      required: true
    }
  },

  computed: {
    appliedFilters () {
      return Object.keys(this.filters).filter(key => this.isNotEmpty(key, this.filters[key])).map(key => ({
        key,
        label: this.getFilterField(key).label,
        text: this.getFieldText(this.getFilterField(key), this.filters[key])
      }))
    }
  },

  methods: {
    isNotEmpty (key, value) {
      const field = this.getFilterField(key)

      if (field.type === 'range_datepicker') return !!value?.to || !!value?.from

      return !!value
    },

    getFilterField (key) {
      return this.map.find(item => item.key === key)
    },

    getFieldText (field, value) {
      if (!field) return value

      if (field.type === 'select') {
        return (field.options.find(opt => opt.id === value))?.text
      }

      if (field.type === 'datepicker') {
        return this.formatDate(value)
      }

      if (field.type === 'range_datepicker') {
        let text = ''
        if (value.from) text = `From ${this.formatDate(value.from)} `
        if (value.to) text += `To ${this.formatDate(value.to)}`
        return text
      }

      return value
    },

    formatDate (date) {
      return date ? moment(date).format('LL') : null
    }
  }
}
</script>

<style lang="scss" scoped>
  .applied-filters {
    padding: 10px 30px;
    .el-tag {
      margin: 3px;
    }
  }
</style>
