<template>
  <div class="page-form">
    <p class="text-center">Legal - PRAA</p>
    
    <p><b>PERSONAL INFORMATION</b></p>

    <p>{{ client.firstName }} - {{ client.lastName }} - {{ age }} - {{ client.nationality | country }} - {{ client.residence | country }}</p>

    <p>Do you have a criminal record? <ex-select v-model="form.criminal_record" :options="yesNoOptions" no-style /></p>

    <p><b>MAIN APPLICANT</b></p>

    <p>Are you currently living in Canada <ex-select v-model="form.living_in_canada" :options="yesNoOptions" no-style /></p>

    <p v-if="isLivingInCanada">Date of entry to Canada <datepicker v-model="form.entry_to_canada" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="Date" style="width: 155px; display: inline-block"/></p>

    <div v-else>
        <p>Date of last entry to Canada <datepicker v-model="form.last_entry_to_canada" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="Date" style="width: 155px; display: inline-block"/></p>
        <p>Date of last refugee claim <datepicker v-model="form.last_refugee_claim" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="Date" style="width: 155px; display: inline-block"/></p>
        <p>Date of leave from Canada <datepicker v-model="form.leave_from_canada" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="Date" style="width: 155px; display: inline-block"/></p>
    </div>

    <p><b>ADDITIONAL INFORMATION:</b></p>

    <ex-textarea v-model="form.additional_info" :rows="4" :maxlength='500' no-style full />

  </div>
</template>

<script>
import numeral from 'numeral'

import Datepicker from 'vuejs-datepicker'
import ExInput from '../../../common/Input'
import ExSelect from '../../../common/Select'
import ExTextarea from '../../../common/Textarea'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    client: {
      type: Object,
      required: true
    }
  },

  components: {
    ExInput,
    ExSelect,
    Datepicker,
    ExTextarea
  },

  data () {
    return {
      form: this.value,

      yesNoOptions: [
        {
          id: 'yes',
          text: 'Yes'
        },
        {
          id: 'no',
          text: 'No'
        }
      ]
    }
  },

  computed: {
    isLivingInCanada () {
      return this.form.living_in_canada === 'yes'
    },

    age () {
      return moment().diff(moment(this.client.dob), 'years')
    }
  },

  watch: {
    form: {
      handler (form) {
        this.$emit('input', form)
      },
      deep: true,
      immediate: true
    }
  },

  created () {
    if (!Object.keys(this.form).length) {
      this.form = {
        criminal_record: null,
        living_in_canada: null,
        entry_to_canada: null,
        last_entry_to_canada: null,
        last_refugee_claim: null,
        leave_from_canada: null,
        additional_info: null
      }
    }
  }
}
</script>
