<template>
  <layout active="/dashboard">
    Dashboard
  </layout>
</template>

<script>
import Layout from './common/Layout'

export default {
  name: 'Dashboard',

  components: { Layout }
}
</script>