import { render, staticRenderFns } from "./ClientAddUser.vue?vue&type=template&id=3f64f476&scoped=true&"
import script from "./ClientAddUser.vue?vue&type=script&lang=js&"
export * from "./ClientAddUser.vue?vue&type=script&lang=js&"
import style0 from "./ClientAddUser.vue?vue&type=style&index=0&id=3f64f476&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f64f476",
  null
  
)

export default component.exports