<template>
  <div>
    <div class="buttons">
      <el-button v-if="!edit" size="mini" icon="el-icon-edit" type="primary" @click="startEdit()">Edit</el-button>
      <template v-if="edit">
        <el-button size="mini" icon="el-icon-edit" type="success" @click="save()">Save</el-button>
        <el-button size="mini" icon="el-icon-edit" @click="edit = false">Cancel</el-button>
      </template>
    </div>

    <div class="info-box">
      <h3>General Information</h3>
      <div class="col">
        <span>Account ID</span>
        <b>{{ data.uuid }}</b>
      </div>
      <div class="col">
        <span>First Name</span>
        <b v-if="!edit">{{ data.firstName }}</b>
        <ex-input v-else v-model="form.firstName" :validator="$v.form.firstName" class="m-0" full no-style />
      </div>
      <div class="col">
        <span>Last Name</span>
        <b v-if="!edit">{{ data.lastName }}</b>
        <ex-input v-else v-model="form.lastName" :validator="$v.form.lastName" class="m-0" full no-style />
      </div>
      <div class="col">
        <span>Job Title</span>
        <b v-if="!edit">{{ data.job_title || 'N/A' }}</b>
        <ex-input v-else v-model="form.job_title" :validator="$v.form.job_title" class="m-0" full no-style />
      </div>
      <div class="col">
        <span>Role</span>
        <b v-if="!edit">{{ data.role | role }}</b>
        <ex-select v-else v-model="form.role" :validator="$v.form.role" :options="roleOptions" class="m-0" full no-style />
      </div>
    </div>
    <div class="info-box">
      <h3>Contact Information</h3>
      <div class="col">
        <span>Email</span>
        <b>{{ data.email }}</b>
      </div>
      <div class="col">
        <span>Phone</span>
        <b v-if="!edit">{{ data.phone }}-{{ data.phoneExt }}</b>
        <div v-else class="flex">
          <ex-input v-model="form.phone" type="number" :validator="$v.form.phone" class="m-0" full no-style />
          <ex-input v-model="form.phoneExt" type="number" class="m-0" full no-style />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { required, alpha } from 'vuelidate/lib/validators'

import ExInput from '../../common/Input'
import ExSelect from '../../common/Select'

export default {
  name: 'GeneralInfo',

  components: {
    ExInput,
    ExSelect
  },

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      edit: false,
      roles: [],

      form: {
        firstName: null,
        lastName: null,
        job_title: null,
        role: null,

        phone: null,
        phoneExt: null,
      },
    }
  },

  computed: {
    roleOptions () {
      if (!this.roles) return []

      return this.roles.filter(role => role.user_group === 'staff').map(role => {
        return {
          id: role.name,
          text: window.roles[role.name]
        }
      })
    }
  },

  async created () {
    this.load()
  },

  methods: {
    ...mapActions({
      update: 'users/update'
    }),

    async load () {
      const loading = this.$loading()
      try {
        this.roles = (await api.list('Role')).items
      } finally {
        loading.close()
      }
    },

    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      const loading = this.$loading()
      try {          
        this.update({ id: this.data.id, payload: this.form })
        this.$message.success('Successfully edited main contact.')
        this.edit = false
      } catch {
        this.$message.error('There was an error... Please try again.')
      } finally {
        loading.close()
      }
    },

    startEdit () {
      this.form = {
        firstName: this.data.firstName,
        lastName: this.data.lastName,
        job_title: this.data.job_title,
        role: this.data.role,
        phone: this.data.phone,
        phoneExt: this.data.phoneExt
      }
      this.edit = true
    }
  },

  validations () {
   return {
      form: {
        firstName: { required, alpha },
        lastName: { required, alpha },
        phone: { required },
        role: { required }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .buttons {
    margin-bottom: 20px;
  }
  .m-0 {
    margin: 0;
  }
</style>
