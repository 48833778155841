<template>
  <div>
    <div class="buttons">
      <el-button
        size="mini"
        icon="el-icon-plus"
        type="primary"
        :disabled="addNew"
        @click="addNew = true">
        New
      </el-button>
    </div>

    <div class="table-wrapper">
      <div class="info-box">
        <h3>Consultations</h3>
      </div>
      <el-table :data="agreements" v-loading="loading" style="min-width: 100%" stripe>
        <el-table-column label="ID" width="90">
          <template slot-scope="scope">
            {{ scope.row ? scope.row.customId : 'N/A' }}
          </template>
        </el-table-column>

        <el-table-column label="Status" width="90">
          <template slot-scope="scope">
            <div class="status">
              <i :class="{
                  'el-icon-s-claim success': scope.row,
                  'el-icon-s-release': !scope.row,
                  warning: !isSigned(scope.row) && scope.row
                }" />
            </div>
          </template>
        </el-table-column>

        <el-table-column label="Filename">
          <template slot-scope="scope">
            <a v-if="isSigned(scope.row)" :href="signedFileUrl(scope.row)" target="_blank">{{ scope.row.signedFile.file }}</a>
            <template v-else-if="scope.row && scope.row.file">{{ scope.row.file.file }}</template>
            <template v-else>N/A</template>
          </template>
        </el-table-column>

        <el-table-column label="Created At">
          <template slot-scope="scope">
            <template v-if="scope.row">{{ scope.row.createdAt | date('ll') }}</template>
            <template v-else>N/A</template>
          </template>
        </el-table-column>

        <el-table-column label="Upload Signed At">
          <template slot-scope="scope">
            <template v-if="!isSigned(scope.row)">N/A</template>
            <template v-else>
              {{ scope.row.signedAt | date }}
            </template>
          </template>
        </el-table-column>

        <el-table-column label="Consultant Fee">
          <template slot-scope="scope">
            <template v-if="!scope.row">
              <ex-input v-model="fee" no-style @blur="formatCurrency()" style="width: 100px;" />
              <el-button :type="confirmed ? 'info' : 'success'" size="mini" :disabled="confirmed" icon="el-icon-check" @click="confirmed = true" />
            </template>
            <template v-else>{{ scope.row.fee }}</template>
          </template>
        </el-table-column>

        <el-table-column label="Actions" align="right" width="200">
          <template slot-scope="scope">
            <el-button size="mini" type="danger" @click="addNew = false">Cancel</el-button> 
            <el-button
              v-if="!scope.row"
              :disabled="!confirmed"
              size="mini"
              type="primary"
              @click="create()">
              Create
            </el-button>

            <template v-else>
              <el-button size="mini" type="danger" @click="remove(scope.row)">{{ !isSigned(scope.row) ? 'Remove' : 'Remove Signed' }}</el-button>
              <a :href="viewUrl(scope.row)" target="_blank">
                <el-button size="mini" icon="el-icon-document">View</el-button>
              </a>

              <template v-if="!isSigned(scope.row)">
                <el-button size="mini" icon="el-icon-s-promotion" @click="shareModal = scope.row">Share</el-button>
                <el-button size="mini" icon="el-icon-edit" @click="$refs[`upload-${scope.row.id}`].click()">Upload signed</el-button>
              </template>
              <input v-show="false" :ref="`upload-${scope.row.id}`" type="file" @change="uploadFile($event, scope.row.id)" />
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <share
      v-if="shareModal"
      :data="shareModal"
      :action="action"
      :use-link="false"
      @close="shareModal = null" />
  </div>
</template>

<script>
import numeral from 'numeral'
import { mapActions } from 'vuex'
import helperMixin from '../common/helpers-mixin'

import ExInput from '../common/Input'
import Share from './setup-checklist/evaluation/Share'

export default {
  name: 'Consultations',

  mixins: [ helperMixin ],

  components: {
    Share,
    ExInput
  },

  data () {
    return {
      fee: '$200.00',
      confirmed: false,
      addNew: false,
      loading: false,
      shareModal: null
    }
  },

  computed: {
    agreements () {
      const data = this.$store.state.client.consultations.data
      return this.addNew ? [...data, null] : data
    },

    registration () {
      return this.$store.state.client.single.registration
    }
  },

  watch: {
    fee () {
      this.confirmed = false
    }
  },

  methods: {
    ...mapActions({
      action: 'client/consultations/action',
      createAgreement: 'client/consultations/create',
      updateAgreement: 'client/consultations/update',
      deleteAgreement: 'client/consultations/delete',
      generatePdf: 'client/single/generateCCAgreementPdf',
      createDocument: 'client/single/createDocument',
      uploadDocument: 'client/single/uploadDocument',
      loadChecklist: 'client/single/loadChecklist'
    }),

    isSigned (item) {
      return !!item?.signedAt && !!item?.signedFile
    },
    
    signedFileUrl (item) {
      if (!item || !item.signedFile) return null
      return `/download/ClientDocument/${item.signedFile.id}`
    },

    viewUrl (item) {
      if (!item || !item.file) return null
      return `/download/ClientDocument/${item.file.id}`
    },

    async remove (item) {
      if (!item?.signedAt || !item?.signedFile) return await this.deleteAgreement(item.id)
      const payload = {
        signedAt: null,
        signedFile: null
      }
      return await this.updateAgreement({ id: item.id, payload })
     
    },

    async create () {
      this.loading = true
      const payload = {
        client: this.registration.id,
        customId: 'CONSULTATION_CUSTOM_ID',
        fee: this.fee,
        isSetup: false,
        createdAt: 'CURRENT_TIMESTAMP',
        createdBy: window.appData.currentUser.id,
        signedAt: null,
        signedFile: null
      }
      const id = (await this.createAgreement(payload)).id
      this.addNew = false
      await this.generatePdf(id)
      await this.loadChecklist({ type: 'ClientConsultationAgreement', id: this.registration.id, force: true })
      this.loading = false
    },

    async uploadFile ($e, id) {
      let file = $e.target.files[0]
      if (file) {
        this.loading = true
        file = this.changeFileName(file, `${this.registration.firstName} ${this.registration.lastName} - Client Consultation Agreement Signed`)
        const document = await this.createDocument({
          type: 'Client Consultation Agreement',
          clientRegId: this.registration.id,
          createdAt: 'CURRENT_TIMESTAMP'
        })
        await this.uploadDocument({ id: document.id, file })
        await this.updateAgreement({
          id,
          payload: {
            signedAt: 'CURRENT_TIMESTAMP',
            signedFile: document.id,
          }
        })
        this.loading = false
      }
    },

    formatCurrency () {
      this.fee = numeral(this.fee).format('$0.00')
    }
  }
}
</script>

<style lang="scss" scoped>
.table-wrapper {
  margin-top: 20px;
}
.status {
  font-size: 30px;
  margin-right: 10px;
  .success {
    color: #67c23a;
  }
  .warning {
    color: orange;
  }
}
</style>