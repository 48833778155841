<template>
  <div>
    <div class="buttons">
      <el-button v-if="!edit" size="mini" icon="el-icon-edit" type="primary" @click="startEdit()">Edit</el-button>
      <template v-if="edit">
        <el-button size="mini" icon="el-icon-edit" type="success" @click="save()">Save</el-button>
        <el-button size="mini" icon="el-icon-edit" @click="edit = false">Cancel</el-button>
      </template>
    </div>

    <div v-for="(info, idx) in general" :key="idx" class="info-box">
      <h3>{{ info.name }}</h3>
      <div v-for="(item, index) in info.items" :key="index" class="col">
        <span>{{ item.name }}</span>

        <template v-if="edit">
          <template v-if="['email', 'text'].includes(item.input)">
            <ex-input v-model="form[item.key]" :type="item.input" :validator="item.validation" class="m-0" full no-style />
          </template>
          <ex-select v-else-if="item.input === 'select'" v-model="form[item.key]" :disabled="item.disabled || false" :validator="item.validation" :options="item.options" class="m-0" full no-style />
          <datepicker
            v-else-if="item.input === 'datepicker'"
            v-model="form[item.key]"
            :class="{ error: item.validation.$dirty && item.validation.$invalid }"
            calendar-button
            calendar-button-icon="el-icon-date"
            placeholder="item.name" />
        </template>

        <b v-else>{{ item.value }}</b>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { phone } from '../common/validators'
import helperMixin from '../common/helpers-mixin'
import { required, email } from 'vuelidate/lib/validators'

import ExInput from '../common/Input'
import ExSelect from '../common/Select'
import Datepicker from 'vuejs-datepicker'

export default {
  components: {
    ExInput,
    ExSelect,
    Datepicker
  },

  mixins: [ helperMixin ],

  data () {
    return {
      edit: false,

      form: {
        firstName: null,
        lastName: null,
        dob: null,
        state: null,
        nationality: null,
        residence: null,        

        email: null,
        phone: null,
        company: null,
        companyType: null,
        address: null,
        city: null,
        postal: null,
        otherPhone: null,

        selectedState: null,
        selectedNationality: null,
        selectedResidence: null
      },
    }
  },

  computed: {
    data () {
      return this.$store.state.client.single.registration
    },

    general () {
      if (!this.data) return []
      return [
        {
          name: 'Main Contact',
          items: [
            {
              name: 'First Name',
              value: this.data.firstName,
              key: 'firstName',
              validation: this.$v.form.firstName,
              input: 'text'
            },
            {
              name: 'Last Name',
              value: this.data.lastName,
              key: 'lastName',
              validation: this.$v.form.lastName,
              input: 'text'
            },
            {
              name: 'Date of Birth',
              value: moment(this.data.dob).format('LL'),
              key: 'dob',
              validation: this.$v.form.dob,
              input: 'datepicker'
            },
            {
              name: 'Country of Nationality',
              value: window.countries[this.data.nationality],
              key: 'selectedNationality',
              validation: this.$v.form.nationality,
              input: 'select',
              options: this.countryOptions
            },
            {
              name: 'Country of Residence',
              value: window.countries[this.data.residence],
              key: 'selectedResidence',
              validation: this.$v.form.residence,
              input: 'select',
              options: this.countryOptions
            }
          ]
        },
        {
          name: 'Address Information',
          items: [
            {
              name: 'Address',
              value: this.data.address,
              key: 'address',
              validation: this.$v.form.address,
              input: 'text'
            },
            {
              name: 'City',
              value: this.data.city,
              key: 'city',
              validation: this.$v.form.city,
              input: 'text'
            },
             {
              name: 'State/Province',
              value: ['CA', 'US'].includes(this.data.residence) ? window.states[this.data.residence].find(state => state.id === this.data.state)?.text : 'N/A',
              key: 'selectedState',
              validation: this.$v.form.state,
              input: 'select',
              disabled: !this.stateOptions,
              options: this.stateOptions
            },
            {
              name: 'Postal Code',
              value: this.data.postal,
              key: 'postal',
              validation: this.$v.form.postal,
              input: 'text'
            },
            {
              name: 'Phone Number',
              value: this.data.phone,
              key: 'phone',
              validation: this.$v.form.phone,
              input: 'text'
            },
            {
              name: 'Other Phone Number',
              value: this.data.otherPhone || 'N/A',
              key: 'otherPhone',
              validation: this.$v.form.otherPhone,
              input: 'text'
            },
            {
              name: 'Email Address',
              value: this.data.email,
              key: 'email',
              validation: this.$v.form.email,
              input: 'email'
            }
          ]
        }
      ]
    },

    stateOptions () {
      return (['CA', 'US'].includes(this.form.residence)) ? window.states[this.form.residence].map(state => {
        return {
          id: state.id,
          text: `${state.id} - ${state.text}`
        }
      }) : null
    }
  },

  watch: {
    'form.selectedState': {
      handler (value) {
        if (value) {
          this.form.state = this.getInitials(value)
        }
      }
    },

    'form.selectedNationality' (value) {
      if (value) this.form.nationality = this.getInitials(value)
    },

    'form.selectedResidence' (value) {
      this.form.state = null
      this.form.selectedState = null
      if (value) this.form.residence = this.getInitials(value)
    }
  },

  methods: {
    ...mapActions({
      update: 'client/single/update'
    }),

    async save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          const payload = { ...this.form }
          payload.dob = moment(payload.dob).format('YYYY-MM-DD')
          
          this.update({ id: this.data.id, payload })
          this.$message.success('Successfully edited main contact.')
          this.edit = false
        } catch {
          this.$message.error('There was an error... Please try again.')
        }
      }
    },

    startEdit () {
      this.form = {
        firstName: this.data.firstName,
        lastName: this.data.lastName,
        dob: this.data.dob,
        state: this.data.state,
        nationality: this.data.nationality,
        residence: this.data.residence,
        address: this.data.address,
        city: this.data.city,
        postal: this.data.postal,
        phone: this.data.phone,
        otherPhone: this.data.otherPhone,
        email: this.data.email,
        service: this.data.service,

        selectedState: this.data.state,
        selectedNationality: this.data.nationality,
        selectedResidence: this.data.residence
      }
      this.edit = true
    },

    getInitials (value) {
      return value.split('').splice(0, 2).join('')
    }
  },

  validations () {
    const rules = {
      form: {
        firstName: { required },
        lastName: { required },
        dob: { required },
        nationality: { required },
        residence: { required },

        address: { required },
        city: { required },
        postal: { required },
        phone: { required, phone },
        email: { required, email },
      }
    }

    if (this.stateOptions) {
      rules.form.state = { required }
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
  .buttons {
    margin-bottom: 20px;
  }
  .m-0 {
    margin: 0;
  }
</style>
