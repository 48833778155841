<template>
  <div>
    <div class="buttons">
      <el-button size="mini" icon="el-icon-plus" type="primary" @click="openForm = true">New</el-button>
    </div>

    <div class="table-wrapper">
      <el-table :data="invoices" style="min-width: 100%" stripe>
        <el-table-column label="Invoice #">
          <template slot-scope="scope">
            {{ scope.row.id }}
          </template>
        </el-table-column>

        <el-table-column label="Filename">
          <template v-if="scope.row.file" slot-scope="scope">
            <a :href="`/download/ClientDocument/${scope.row.file.id}`" target="_blank">
              {{ scope.row.file.file }}
            </a>
          </template>
        </el-table-column>

        <el-table-column label="Date Start">
          <template slot-scope="scope">
            {{ scope.row.startDate | date('LL') }}
          </template>
        </el-table-column>

        <el-table-column label="Date End">
          <template slot-scope="scope">
            {{ scope.row.endDate | date('LL') }}
          </template>
        </el-table-column>

        <el-table-column label="Actions" align="right">
          <template slot-scope="scope">
            <el-button size="mini" icon="el-icon-s-promotion" @click="shareModal = scope.row">Share</el-button>
            <span>
              <el-button size="mini" icon="el-icon-edit" type="primary" @click="openPdf(scope.row.file.id)">View</el-button>
            </span>
            <el-button v-if="isUserAdmin" size="mini" icon="el-icon-delete" type="danger" @click="remove(scope.row.id)">
              Remove
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <billing-invoice-form
      v-if="openForm"
      @close="openForm = false"
      @saved="openForm = false; loadInvoices()"/>

    <share
      v-if="shareModal"
      :data="shareModal"
      :action="action"
      :use-link="false"
      @close="shareModal = null" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import helperMixin from "../../common/helpers-mixin"

import Share from '../setup-checklist/evaluation/Share'
import BillingInvoiceForm from "./BillingInvoiceForm"

export default {
  name: "BillingInvoices",

  mixins: [helperMixin],

  components: {
    Share,
    BillingInvoiceForm,
  },

  data() {
    return {
      openForm: false,
      shareModal: null
    }
  },

  computed: {
    ...mapState({
      client: state => state.client.single.registration,
      invoices: state => state.client.invoices.data
    })
  },

  created() {
    this.loadInvoices();
  },

  methods: {
    ...mapActions({
      action: 'client/invoices/action'
    }),

    openPdf(documentId) {
      window.open(`/download/ClientDocument/${documentId}`, '_blank');
    },

    loadInvoices() {
      this.$store.dispatch('client/invoices/load', {params: {client: this.client.id}});
    },

    async remove(id) {
      const loading = this.$loading();
      try {
        await this.$confirm('Are you sure you want to delete this invoice?');
        try {
          await this.$store.dispatch('client/invoices/delete', id);
          this.$message.success('Invoice deleted.');
        } catch (e) {
          console.log(e.message);
          this.$message.error('Something went wrong');
        }
      } catch {}

      loading.close();
    },
  }

}
</script>

<style lang="scss" scoped>
  .buttons {
    margin-bottom: 20px;
  }
</style>