<template>
  <layout active="/system">
    System
  </layout>
</template>

<script>
import Layout from '../common/Layout'

export default {
  name: 'System',

  components: {
    Layout
  }
}
</script>