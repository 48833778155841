<template>
  <div>
    <client-assign />

    <client-consultation-agreement />

    <client-evaluation />

    <client-file-setup />
  </div>
</template>

<script>
import ClientAssign from './ClientAssign'
import ClientEvaluation from './ClientEvaluation'
import ClientFileSetup from './ClientFileSetup'
import ClientConsultationAgreement from './ClientConsultationAgreement'

export default {
  name: 'SetupChecklist',

  components: {
    ClientAssign,
    ClientFileSetup,
    ClientEvaluation,
    ClientConsultationAgreement
  },

  props: {
    id: {
      type: String,
      required: true
    }
  }
}
</script>
