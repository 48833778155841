<template>
<div class="wrapper">
    <panel title="Client Notes">
        <ul>
            <li v-for="note in notes" :key="note.id" class="note-item">
                <b>
                    {{ note.createdAt }}
                    <em class="link">
                        <template v-if="note.createdBy">
                            {{ note.createdBy.firstName }} {{ note.createdBy.lastName }}
                        </template>
                        <template v-else>
                            System
                        </template>
                    </em>:
                </b>
                {{ note.note }}
                <el-button
                    v-if="note.createdBy &&note.createdBy.id === currentUser.id"
                    class="floatr"
                    size="mini"
                    type="danger"
                    icon="el-icon-delete"
                    circle
                    @click="remove(note.id)"/>
            </li>
        </ul>
    </panel>

    <panel title="Add Note">
        <ex-textarea v-model="form.note" :validator="$v.form.note" rows="1" label="Note" no-style full />
        <div class="button">
          <el-button type="primary" @click="save()">Create</el-button>
        </div>
    </panel>
</div>
</template>

<script>
import Panel from '../common/Panel'
import ExTextarea from '../common/Textarea'

import { required } from 'vuelidate/lib/validators'

export default {
    name: 'Notes',

    data() {
        return {
            form: {
                note: null
            }
        }
    },

    components: {
        Panel,
        ExTextarea
    },

    computed: {
        currentUser () {
            return window.appData.currentUser
        },

        notes () {
            return this.$store.state.client.notes.data
        },

        data () {
            return this.$store.state.client.single.registration
        }
    },

    created() {
        this.$store.dispatch('client/notes/load', { params: { clientRegId: this.data.id }})
    },

    methods: {
        clear() {
            this.form.note = null
            this.$v.form.$reset()
        },

        async save () {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                try {
                const loading = this.$loading()
                const payload = { 
                    ...this.form, 
                    client: this.data.id,
                    createdAt: 'CURRENT_TIMESTAMP',
                    createdBy: window.appData.currentUser.id
                }
                await this.$store.dispatch('client/notes/create', payload)
                this.clear()
                this.$message.success('Note successfully added.')
                loading.close()
                } catch {}
            }
        },

        async remove (ind) {
            try {
                await this.$confirm('Are you sure you want to delete note?')
                await this.$store.dispatch('client/notes/delete', ind)
                this.$message.error('Note successfully deleted.')
            } catch {}
        },
    },

    validations() {
        const res = {
            form: {
                note: { required }
            }
        }

        return res
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep .panel-header h3 {
        text-align: left;
    }   

    .panel:nth-of-type(2) {
        margin-top: 20px;
    }

    .button {
        margin-top: 20px; 
    }

    .link {
        color: #8432ff;
    }
     
    .note-item {
        height: 35px; 
    }
</style>