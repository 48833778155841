import moment from 'moment';

window.vueMixins=window.vueMixins || {}

window.serverDiff=Math.round((moment().unix()-moment(serverNow).unix())/60)*60;

window.vueMixins.dateFormatter={
  methods: {
    formatDate(source, format) {
      if (!source)
        return "";
      return moment(source).format(format);
    },
    agoColor(source) {
      var t=moment(source);
      var n=moment().subtract(serverDiff, "s");
      if (n.unix()-t.unix()<0)
        return "";
      if (n.unix()-t.unix()<12*3600)
        return "#32cd32";
      if (n.unix()-t.unix()<24*3600)
        return "#ffff00";
      return "#ff4d4d";
    },
    agoClass(source, now) {
      if (typeof(now)=="number")
        now=undefined;
      var t=moment(source);
      var n=moment(now).subtract(serverDiff, "s");
      if (n.unix()-t.unix()<0)
        return "";
      if (n.unix()-t.unix()<12*3600)
        return "ago12";
      if (n.unix()-t.unix()<24*3600)
        return "ago24";
      return "ago";
    },
    agoTimer(source, now) {
      if (typeof(now)=="number")
        now=undefined;
      var t=moment(source);
      var n=moment(now).subtract(serverDiff, "s");
      if (n.unix()-t.unix()<0)
        return "00:00:00";
      var diff=n.unix()-t.unix();
      var h=Math.floor(diff/3600);
      var m=Math.floor(diff/60)%60;
      var s=Math.floor(diff)%60;
      return (h<10?"0":"")+h+":"+(m<10?"0":"")+m+":"+(s<10?"0":"")+s;
    },
  },
};
