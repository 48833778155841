<template>
  <div class="page-form">
    <p class="text-center">Documents – {{ title }}</p>
    
    <p><b>PERSONAL INFORMATION</b></p>

    <p>{{ client.firstName }} - {{ client.lastName }} - {{ age }} - {{ client.nationality | country }} - {{ client.residence | country }}</p>

    <p>Do you have a criminal record? <ex-select v-model="form.criminal_record" :options="yesNoOptions" no-style /></p>

    <p><b>MAIN APPLICANT</b></p>

    <p>What is your current status in Canada? <ex-select v-model="form.current_status" :options="currentStatus" no-style /></p>

    <p>What service is requested? <ex-select v-model="form.requested_service" :options="requestedService" no-style /></p>

    <p v-if="!travelDocSelected">Document Type <ex-select v-model="form.document_type" :options="documentType" no-style /></p>

    <p v-else>Document Type <ex-select v-model="form.document_type" :options="travelDocuments" no-style /></p>

    <p><b>ADDITIONAL INFORMATION:</b></p>

    <ex-textarea v-model="form.additional_info" :rows="4" :maxlength='500' no-style full />

  </div>
</template>

<script>
import numeral from 'numeral'

import Datepicker from 'vuejs-datepicker'
import ExInput from '../../../common/Input'
import ExSelect from '../../../common/Select'
import ExTextarea from '../../../common/Textarea'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    client: {
      type: Object,
      required: true
    },

    category: {
      type: String,
      default: null
    }
  },

  components: {
    ExInput,
    ExSelect,
    Datepicker,
    ExTextarea
  },

  data () {
    return {
      form: this.value,

      yesNoOptions: [
        {
          id: 'yes',
          text: 'Yes'
        },
        {
          id: 'no',
          text: 'No'
        }
      ]
    }
  },

  computed: {
    currentStatus () {
      return window.currentStatus
    },

    requestedService () {
      return window.requestedService
    },

    documentType () {
      return window.documentType
    },

    travelDocuments () {
      return window.travelDocuments
    },

    travelDocSelected () {
      return this.form.requested_service === 'travel_documents'
    },

    title () {
      const titles = {
        document_replacement: 'Document Replacement',
        document_extension: 'Document Extension',
        travel_documents: 'Travel Documents'
      }
      return titles[this.category]
    },

    age () {
      return moment().diff(moment(this.client.dob), 'years')
    }
  },

  watch: {
    form: {
      handler (form) {
        this.$emit('input', form)
      },
      deep: true,
      immediate: true
    }
  },

  created () {
    if (!Object.keys(this.form).length) {
      this.form = {
        criminal_record: null,
        current_status: null,
        requested_service: null,
        document_type: null,
        additional_info: null
      }
    }
  }
}
</script>
