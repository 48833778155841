import $ from 'jquery';


$(function($) {
  
  $(".form-login").each(function() {
    $('input[name=agentId]').focus();
    $(this).on('submit', function(e) {
      e.preventDefault();
      var self=$(this);
      $.post(self.attr("action"), self.serialize(), function(res) {
        console.log(res);
        if (res.success==false) {
          if (res.initialPassword) {
            userResetPw.showPopup({
              agentId: $("[name=agentId]").val(),
              username: $("[name=username]").val(),
              oldPassword: $("[name=password]").val(),
              initial: true,
            });
          } else if (res.passwordChange) {
            userResetPw.showPopup({
              agentId: $("[name=agentId]").val(),
              username: $("[name=username]").val(),
              oldPassword: $("[name=password]").val(),
            });
          } else
            $('.error-msg').html(res.blocked?"Your account has been locked for security reasons.  Please contact support at "+window.supportEmail+" to restore your access.":"Invalid username or password").show();
        } else
          window.location.href=$("base").attr("href");
      });
    });
    
    if (window.resetPw) {
      var self=$(this);
      $.post(self.attr("action"), window.resetPw, function(res) {
        if (res.success==false) {
          if (res.initialPassword) {
            userResetPw.showPopup({
              agentId: window.resetPw.agentId,
              username: window.resetPw.username,
              oldPassword: window.resetPw.password,
              initial: true,
            });
          } else if (res.passwordChange) {
            userResetPw.showPopup({
              agentId: window.resetPw.agentId,
              username: window.resetPw.username,
              oldPassword: window.resetPw.password,
            });
          }
        }
      }, "json");
    }
  });
  
});

