<template>
  <div class="page-form">
    <p class="text-center">Legal - Immigration Detention</p>
    
    <p><b>PERSONAL INFORMATION</b></p>

    <p>{{ client.firstName }} - {{ client.lastName }} - {{ age }} - {{ client.nationality | country }} - {{ client.residence | country }}</p>

    <p>Do you have a criminal record? <ex-select v-model="form.criminal_record" :options="yesNoOptions" no-style /></p>

    <p><b>MAIN APPLICANT</b></p>

    <p>Did the detained person already have a hearing? <ex-select v-model="form.have_hearing" :options="yesNoOptions" no-style /></p>

    <p>Date of next hearing <datepicker v-model="form.next_hearing" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="Date" style="width: 155px; display: inline-block"/></p>

    <p><b>ADDITIONAL INFORMATION:</b></p>

    <ex-textarea v-model="form.additional_info" :rows="4" :maxlength='500' no-style full />

  </div>
</template>

<script>
import numeral from 'numeral'

import Datepicker from 'vuejs-datepicker'
import ExInput from '../../../common/Input'
import ExSelect from '../../../common/Select'
import ExTextarea from '../../../common/Textarea'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    client: {
      type: Object,
      required: true
    }
  },

  components: {
    ExInput,
    ExSelect,
    Datepicker,
    ExTextarea
  },

  data () {
    return {
      form: this.value,

      yesNoOptions: [
        {
          id: 'yes',
          text: 'Yes'
        },
        {
          id: 'no',
          text: 'No'
        }
      ]
    }
  },

  computed: {
    age () {
      return moment().diff(moment(this.client.dob), 'years')
    }
  },

  watch: {
    form: {
      handler (form) {
        this.$emit('input', form)
      },
      deep: true,
      immediate: true
    }
  },

  created () {
    if (!Object.keys(this.form).length) {
      this.form = {
        criminal_record: null,
        have_hearing: null,
        next_hearing: null,
        additional_info: null,
      }
    }
  }
}
</script>
