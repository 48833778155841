<template>
  <form-item :label="label" :validator="validator" :class="{ full }" :no-style="noStyle">
    <select v-model="val" :disabled="disabled">
      <option :value="null"><slot>{{ placeholder || `Select ${label || '' }` }}</slot></option>
      <option v-for="option in options" :key="option.id" :value="option.id" :disabled="option.disabled">{{ option.text }}</option>
    </select>
  </form-item>
</template>

<script>
import inputMixin from './input-mixin.js'

export default {
  mixins: [inputMixin],

  props: {
    options: {
      type: Array,
      default: () => {
        return []
      }
    },

    placeholder: {
      type: String,
      default: null
    },

    full: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    noStyle: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .full {
    width: 100%;
  }
</style>