<template>
  <div class="page-form">
    <p class="text-center">Legal - Refugee Claim</p>
    
    <p><b>PERSONAL INFORMATION</b></p>

    <p>{{ client.firstName }} - {{ client.lastName }} - {{ age }} - {{ client.nationality | country }} - {{ client.residence | country }}</p>

    <p>Do you have a criminal record? <ex-select v-model="form.criminal_record" :options="yesNoOptions" no-style /></p>

    <p><b>MAIN APPLICANT</b></p>

    <p>Are you currently living in Canada <ex-select v-model="form.living_in_canada" :options="yesNoOptions" no-style /></p>

    <p v-if="isLivingInCanada">What is your status in Canada? <ex-select v-model="form.status_in_canada" :options="statusInCanada" no-style /></p>

    <p>Do you have family in Canada? <ex-select v-model="form.family_in_canada" :options="yesNoOptions" no-style /></p>
    
    <p>Will you be claiming refugee with other members of your family? <ex-select v-model="form.other_members_of_family" :options="yesNoOptions" no-style /></p>
    
    <p>Are you travelling with your children? <ex-select v-model="form.with_children" :options="yesNoOptions" no-style /></p>

    <p v-if="travelingWithChildren">Are they in your custody? <ex-select v-model="form.custody" :options="yesNoOptions" no-style /></p>

    <p>Did you claim refugee in another country? <ex-select v-model="form.another_country" :options="yesNoOptions" no-style /></p>

    <p>Have you been removed from another country? <ex-select v-model="form.removed_from_another_country" :options="yesNoOptions" no-style /></p>

    <p>Do you or any of your family members suffer from an important medical condition? <ex-select v-model="form.medical_condition" :options="yesNoOptions" no-style /></p>

    <p>List the countries where you believe that you are at risk of serious harm <ex-select v-model="form.seriousHarm" :options="countriesOptions" no-style /></p>

    <div v-for="(country,k) in countries" :key="k">
      <p v-if="k < 2"><ex-select v-model="form[`seriousHarm${k + 1}`]" :options="countriesOptions" no-style style="margin-left: 508px;"/></p>
    </div>

    <el-button size="small" type="primary" icon="el-icon-plus" @click="addCountry()">Add Country</el-button> 

    <p>Did you ask any authorities in your country for protection? <ex-select v-model="form.authorities_for_protection" :options="yesNoOptions" no-style /></p>

    <p>Did you move to another part of the country to seek safety? <ex-select v-model="form.move_to_another_country" :options="yesNoOptions" no-style /></p>

    <p>Did you move to another country (other than Canada), to seek safety? <ex-select v-model="form.another_country_safety" :options="yesNoOptions" no-style /></p>

    <p><b>ADDITIONAL INFORMATION:</b></p>

    <ex-textarea v-model="form.additional_info" :rows="4" :maxlength='500' no-style full />

  </div>
</template>

<script>
import numeral from 'numeral'

import Datepicker from 'vuejs-datepicker'
import ExInput from '../../../common/Input'
import ExSelect from '../../../common/Select'
import ExTextarea from '../../../common/Textarea'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    client: {
      type: Object,
      required: true
    }
  },

  components: {
    ExInput,
    ExSelect,
    Datepicker,
    ExTextarea
  },

  data () {
    return {
      form: this.value,

      countries: [],

      yesNoOptions: [
        {
          id: 'yes',
          text: 'Yes'
        },
        {
          id: 'no',
          text: 'No'
        }
      ]
    }
  },

  computed: {
    travelingWithChildren () {
      return this.form.with_children === 'yes'
    },

    isLivingInCanada () {
      return this.form.living_in_canada === 'yes'
    },

    statusInCanada () {
      return window.statusInCanada
    },

    countriesOptions () {
      return window.countriesOptions
    },

    age () {
      return moment().diff(moment(this.client.dob), 'years')
    }
  },

  watch: {
    form: {
      handler (form) {
        this.$emit('input', form)
      },
      deep: true,
      immediate: true
    }
  },

  created () {
    if (!Object.keys(this.form).length) {
      this.form = {
        criminal_record: null,
        living_in_canada: null,
        status_in_canada: null,
        family_in_canada: null,
        other_members_of_family: null,
        with_children: null,
        custody: null,
        another_country: null,
        removed_from_another_country: null,
        medical_condition: null,
        seriousHarm: null,
        seriousHarm1: null,
        seriousHarm2: null,
        authorities_for_protection: null,
        move_to_another_country: null,
        another_country_safety: null,
      }
    }
  },

  methods: {
    addCountry () {
      this.countries.push({ seriousHarm: null })
    }
  }
}
</script>
