<template>
  <layout active="/system/document-depot">
    <document-depot-menu />

    <panel title="System NDA">
      <div class="form">
        <div class="col">
          <ex-input v-model="form.firstName" label="First Name" :validator="$v.form.firstName" full no-style />
        </div>
        <div class="col">
          <ex-input v-model="form.lastName" label="Last Name" :validator="$v.form.lastName" full no-style />
        </div>
        <div class="col">
          <ex-input v-model="form.jobTitle" label="Job Title" :validator="$v.form.jobTitle" full no-style />
        </div>
        <div class="col w100">
          <ex-input v-model="form.company" label="Client Company Name" :validator="$v.form.company" full no-style />
        </div>
        <div class="col">
          <ex-select v-model="selectedCountry" label="Country" :validator="$v.form.country" :options="countryOptions" full no-style />
        </div>
        <div class="col">
          <ex-select v-model="selectedState" label="State/Province" :disabled="!stateOptions" :validator="$v.form.country" :options="stateOptions" full no-style />
        </div>
        <div class="col">
          <ex-input v-model="form.address" label="Address" :validator="$v.form.address" full no-style />
        </div>
        <div class="col">
          <ex-input v-model="form.city" label="City" :validator="$v.form.city" full no-style />
        </div>
        <div class="col">
          <ex-input v-model="form.postal" label="ZIP/Postal code" :validator="$v.form.postal" full no-style />
        </div>
        <div class="col no-padding" />
        <div class="col pt-10">Signer:</div>
        <div class="col">
          <ex-input v-model="form.signerFirstName" label="First Name" :validator="$v.form.signerFirstName" full no-style />
        </div>
        <div class="col">
          <ex-input v-model="form.signerLastName" label="Last Name" :validator="$v.form.signerLastName" full no-style />
        </div>
        <div class="col">
          <ex-input v-model="form.signerJobTitle" label="Job Title" :validator="$v.form.signerJobTitle" full no-style />
        </div>
        <div class="col no-padding">
          <el-button class="save" size="small" type="primary" icon="el-icon-check" :loading="loading" @click="save()">Submit</el-button>
        </div>
      </div>

      <div class="table-wrapper">
        <div class="info-box">
          <h3>NDA History</h3>
        </div>
        <el-table
          v-loading="loading"
          :data="data" style="min-width: 100%" stripe>
          <el-table-column label="Created by">
            <template slot-scope="scope">
              {{ scope.row.createdBy.firstName }} {{ scope.row.createdBy.lastName }}
            </template>
          </el-table-column>
          
          <el-table-column label="Company name">
            <template slot-scope="scope">
            {{ scope.row.company }}
            </template>
          </el-table-column>

          <el-table-column label="Created">
            <template slot-scope="scope">
              {{ scope.row.createdAt | date }}
            </template>          
          </el-table-column>

          <el-table-column label="Actions" align="right" width="350">
            <template slot-scope="scope">
              <a :href="viewUrl(scope.row.file)" target="_blank">
                <el-button size="mini" icon="el-icon-document">View</el-button>
              </a>
              <el-button size="mini" icon="el-icon-delete" type="danger" @click="remove(scope.row.id)">Delete</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </panel>
  </layout>
</template>

<script>
import Layout from '../common/Layout'
import Panel from '../common/Panel'
import ExInput from '../common/Input'
import ExSelect from '../common/Select'
import DocumentDepotMenu from './DocumentDepotMenu'

import { required, numeric, email, alpha } from 'vuelidate/lib/validators'

export default {
  name: 'SystemNda',

  components: {
    DocumentDepotMenu,
    Panel,
    Layout,
    ExInput,
    ExSelect
  },

  data () {
    return {
      data: [],

      form: {
        firstName: null,
        lastName: null,
        jobTitle: null,
        company: null,
        country: null,
        address: null,
        city: null,
        state: null,
        postal: null,
        signerFirstName: null,
        signerLastName: null,
        signerJobTitle: null
      },

      selectedState: null,
      selectedCountry: null,

      loading: false
    }
  },

  computed: {
    countryOptions () {
      const options = window.countriesOptions.map(country => {
        return {
          id: country.id,
          text: `${country.id} - ${country.text}`
        }
      }) 
      options.splice(2, 0, {
        id: null,
        disabled: true,
        text: '---------------------------------'
      });
      return options
    },

    stateOptions () {
      return (['CA', 'US'].includes(this.form.country)) ? window.states[this.form.country].map(state => {
        return {
          id: state.id,
          text: `${state.id} - ${state.text}`
        }
      }) : null
    }
  },

  watch: {
    selectedState (value) {
      if (value) this.form.state = this.getInitials(value)
    },

    selectedCountry (value) {
      if (value) {
        this.form.country = this.getInitials(value)
        this.selectedState = null
        this.form.state = null
      } else {
        this.form.country = null
      }
    }
  },

  created () {
    this.load()
  },

  methods: {
    viewUrl (file) {
      if (!file) return null
      return `/download/ClientDocument/${file.id}`
    },

    async load () {
      this.data = (await api.list('NDA', { clientRegId: ["IS NULL"] })).items
    },

    async save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.loading = true
        try {
          const payload = {
            createdAt: 'CURRENT_TIMESTAMP',
            createdBy: window.appData.currentUser.id,
            signedAt: null,
            signedFile: null,
            ...this.form
          }

          const id = (await api.create('NDA', payload)).id
          await api.request('POST', `/nda/${id}/pdf`, {}, true)
          this.load()          
          this.$message.success('NDA created successfully.')      
          this.clear()
          this.$v.$reset()
        } finally {
          this.loading = false
        }
      }
    },

    async remove (id) {
      try {
        await this.$confirm(`Are you sure to delete this NDA?`, 'Warning', {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          type: 'error'
        })

        await api.delete('NDA', id)
        const idx = this.data.findIndex(item => item.id === id)
        this.data.splice(idx, 1)
        this.$message.success('NDA deleted successfully.')
      } catch {}
    },

    clear () {
      Object.keys(this.form).forEach(key => this.form[key] = null)
      this.selectedState = null
      this.selectedCountry = null
    },

    getInitials (value) {
      return value.split('').splice(0, 2).join('')
    }
  },

  validations () {
    const rules = {
      form: {
        firstName: { required },
        lastName: { required },
        jobTitle: { required },
        company: { required },
        country: { required },
        address: { required },
        city: { required },
        postal: { required },
        signerFirstName: { required },
        signerLastName: { required },
        signerJobTitle: { required }
      }
    }

    if (this.stateOptions) {
      rules.form.state = { required }
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
  .col {
    width: 20%;
    padding: 0 20px;
    box-sizing: border-box;
    .save {
      margin-top: 17px;
      float: right;
    }
    &.no-padding {
      padding: 0;
    }
  }
  .pt-10 {
    padding-top: 10px;
  }
</style>