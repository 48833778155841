<template>
  <div class="page-form">
    <p class="text-center">Citizenship - Citizenship Application;</p>
    
    <p><b>PERSONAL INFORMATION</b></p>

    <p>{{ client.firstName }} - {{ client.lastName }} - {{ age }} - {{ client.nationality | country }} - {{ client.residence | country }}</p>

    <p>Do you have a criminal record? <ex-select v-model="form.criminal_record" :options="yesNoOptions" no-style /></p>

    <p><b>MAIN APPLICANT</b></p>

    <p>Are you able to communicate in
      <span class="checkbox-group"><input type="checkbox" :checked="form.main_communicate === 'English'" value="English" @change="onCheckbox($event)"> English</span>
      <span class="checkbox-group"><input type="checkbox" :checked="form.main_communicate === 'French'" value="French" @change="onCheckbox($event)"> French</span>
      <span class="checkbox-group"><input type="checkbox" :checked="form.main_communicate === 'Both'" value="Both" @change="onCheckbox($event)"> Both</span>
      <span class="checkbox-group"><input type="checkbox" :checked="form.main_communicate === 'Neither'" value="Neither" @change="onCheckbox($event)"> Neither</span>
    </p>

    <p>Source of income for the past 5 years <ex-input v-model="form.main_source_of_income" no-style /></p>

    <p>Countries that you are citizen <ex-select v-for="(country, k) in form.main_countries" :key="k" v-model="form.main_countries[k].main_countries" :options="countriesOptions" no-style style="margin-right: 5px;"/></p>
    
    <el-button v-if="form.main_countries.length < 3" size="small" type="primary" icon="el-icon-plus" @click="addmainCountries()">Add Country</el-button>

    <p>Since when are you a Canadian Permanent Resident <datepicker v-model="form.main_since_resident" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="Date" style="width: 155px; display: inline-block"/></p>

    <p>How many days have you been outside Canada in the past 5 years? <ex-input v-model.number="form.main_outside_canada" type="number" :min="0" no-style /></p> 




    <el-button v-if="form.applicants.length < 20" size="small" type="primary" icon="el-icon-plus" @click="addApplicant()">Add Applicant</el-button>

    <template v-if="form.applicants.length">
      <div v-for="(applicant, key) in form.applicants" :key="key">
        <p><b>APPLICANT {{ key + 2 }}</b></p>

        <p>
          First Name <ex-input v-model="form.applicants[key].applicant_first_name" no-style />  
          Last Name <ex-input v-model="form.applicants[key].applicant_last_name" no-style />
          Age <ex-input v-model.number="form.applicants[key].applicant_age" type="number" :min="0" no-style />
          <br> 
          Country of Birth <ex-select v-model="form.applicants[key].applicant_birth" :options="countriesOptions" no-style /> 
          Country of Residence <ex-select v-model="form.applicants[key].applicant_residence" :options="countriesOptions" no-style /> 
        </p>

        <p>Do you have a criminal record? <ex-select v-model="form.applicants[key].applicant_criminal_record" :options="yesNoOptions" no-style /></p>

        <p>Are you able to communicate in
          <span class="checkbox-group"><input type="checkbox" :checked="form.applicants[key].applicant_communicate === 'English'" value="English" @change="onCheckboxApplicant($event, key)"> English</span>
          <span class="checkbox-group"><input type="checkbox" :checked="form.applicants[key].applicant_communicate === 'French'" value="French" @change="onCheckboxApplicant($event, key)"> French</span>
          <span class="checkbox-group"><input type="checkbox" :checked="form.applicants[key].applicant_communicate === 'Both'" value="Both" @change="onCheckboxApplicant($event, key)"> Both</span>
          <span class="checkbox-group"><input type="checkbox" :checked="form.applicants[key].applicant_communicate === 'Neither'" value="Neither" @change="onCheckboxApplicant($event, key)"> Neither</span>
        </p>

        <p>Source of income for the past 5 years <ex-input v-model="form.applicants[key].applicant_source_of_income" no-style /></p>

        <p>Countries that you are citizen <ex-select v-for="(country, i) in form.applicants[key].applicant_countries" :key="i" v-model="form.applicants[key].applicant_countries[i]" :options="countriesOptions" no-style style="margin-right: 5px;"/></p>
      
        <el-button v-if="form.applicants[key].applicant_countries.length < 3" size="small" type="primary" icon="el-icon-plus" @click="form.applicants[key].applicant_countries.push(null)">Add Country</el-button>

        <p>Since when are you a Canadian Permanent Resident <datepicker v-model="form.applicants[key].applicant_since_resident" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="Date" style="width: 155px; display: inline-block"/></p>

        <p>How many days have you been outside Canada in the past 5 years? <ex-input v-model.number="form.applicants[key].applicant_outside_canada" type="number" :min="0" no-style /></p>
      </div>
    </template>

    <p><b>ADDITIONAL INFORMATION:</b></p>

    <ex-textarea v-model="form.additional_info" :rows="4" :maxlength='500' no-style full />



  </div>
</template>

<script>
import numeral from 'numeral'

import Datepicker from 'vuejs-datepicker'
import ExInput from '../../../common/Input'
import ExSelect from '../../../common/Select'
import ExTextarea from '../../../common/Textarea'

const mainCountries = {
  main_countries: null
}

const applicants = {
  applicant_first_name: null,
  applicant_last_name: null,
  applicant_age: null,
  applicant_birth: null,
  applicant_residence: null,
  applicant_criminal_record: null,
  applicant_communicate: null,
  applicant_source_of_income: null,
  applicant_countries: [],
  applicant_since_resident: null,
  applicant_outside_canada: null
}

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    client: {
      type: Object,
      required: true
    },

    category: {
      type: String,
      default: null
    }
  },

  components: {
    ExInput,
    ExSelect,
    Datepicker,
    ExTextarea
  },

  data () {
    return {
      form: this.value,

      yesNoOptions: [
        {
          id: 'yes',
          text: 'Yes'
        },
        {
          id: 'no',
          text: 'No'
        }
      ]
    }
  },

  computed: {
    age () {
      return moment().diff(moment(this.client.dob), 'years')
    },

    countriesOptions () {
      return window.countriesOptions
    },
  },

  watch: {
    form: {
      handler (form) {
        this.$emit('input', form)
      },
      deep: true,
      immediate: true
    }
  },

  created () {
    if (!Object.keys(this.form).length) {
      this.form = {
        criminal_record: null,
        main_communicate: null,
        main_source_of_income: null,
        main_countries: [{}],
        main_since_resident: null,
        main_outside_canada: null,
        applicants: [],
        additional_info: null
      }
    }
  },

  methods: {
    onCheckboxApplicant (event, index) {
      this.form.applicants[index].applicant_communicate = event.target.value
    },

    onCheckbox (event) {
      if (event.target.checked) this.form.main_communicate = event.target.value
    },

    addmainCountries () {
      this.form.main_countries.push({ ...mainCountries })
    },

    addApplicant () {
      const applicant = { ...applicants }
      applicant.applicant_countries = [null]
      this.form.applicants.push(applicant)
    }
  }
}
</script>