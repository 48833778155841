<template>
<div v-if="employees">
  <div class="form-group">
    <label>Tag Employees:</label>
    <select v-if="availableEmployees.length && !readonly" v-model="selectEmployee">
      <option v-for="employee in availableEmployees" :key="employee.id" :value="employee.id">{{ employee.firstName }} {{ employee.lastName }}</option>
    </select>
    <span v-if="!employees.length">There are no Active employees</span>
  </div>
  <div class="tags">
    <el-tag
      v-for="id in selectedEmployees"
      :key="id"
      effect="dark"
      class="mr-5"
      size="mini"
      :closable="!readonly || (selfRemove && getEmployee(id).userId.id === userId)"
      @close="removeEmployee(id)">
      {{ getEmployee(id).firstName }} {{getEmployee(id).lastName }}
    </el-tag>
  </div>
</div>
</template>

<script>
export default {
  name: 'TagEmployees',

  props: {
    value: {
      type: Array,
      required: true
    },

    readonly: {
      type: Boolean,
      default: false
    },

    selfRemove: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      selectEmployee: null,
      employees: null,
      roles: []
    }
  },

  computed: {
    userId () {
      return window.appData.currentUser.id
    },

    selectedEmployees: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },

    staff () {
      return this.roles ? this.roles.filter(role => role.user_group === 'staff').map(role => role.name) : []
    },

    staffEmployees () {
      return this.employees ? this.employees.filter(user => this.staff.includes(user.role)) : []
    },

    availableEmployees () {
      return this.staffEmployees ? this.staffEmployees.filter(employee => employee.active === 'Yes' && !this.selectedEmployees.includes(employee.id)) : []
    }
  },

  watch: {
    selectEmployee (value) {
      if (value) {
        this.selectedEmployees.push(value)
        this.selectEmployee = null
      }
    }
  },

  async created () {
    this.roles = (await api.list('Role')).items
    this.employees = (await api.list('User')).items
  },

  methods: {
    removeEmployee (id) {
      if (this.readonly) {
        this.$emit('remove', this.getEmployee(id))
      } else {
        const idx = this.selectedEmployees.findIndex(employeeId => employeeId === id)
        this.selectedEmployees.splice(idx, 1)
      }
    },

    getEmployee (id) {
      return this.employees ? this.employees.find(employee => employee.id === id) : null
    }
  },
}
</script>
