<template>
<form class="form">
  <div class="form-group form-subject">
    <label>Subject:</label>
    <ex-input v-model="form.subject" :validator="$v.form.subject" :disabled="readonly" no-style full />
  </div>
  <div class="inline-form-group">
    <div class="form-group">
      <label>Date:</label>
        <datepicker
          v-model="form.date"
           :class="{ error: $v.form.date.$dirty && $v.form.date.$invalid }"
          calendar-button
          calendar-button-icon="el-icon-date"
          maxlength="100"
          format="d MMM yyyy"
          :open-date="new Date()"
          :disabled="readonly"
          :disabledDates="disabledDates"/>
    </div>
    <div class="form-group">
      <label>Time from:</label>
      <ex-select class="time-select" v-model="form.time_from_hours" :options="hourOptions" :validator="$v.form.time_from_hours" :disabled="readonly" no-style full />
      
      <ex-select class="time-select" v-model="form.time_from_time" :options="timeOptionsOpt" :validator="$v.form.time_from_time" :disabled="readonly" no-style full />
      
      <ex-select class="time-select" v-model="form.time_from_am_pm" :options="amPmOptions" :validator="$v.form.time_from_am_pm" :disabled="readonly" no-style full />
    </div>
    <div class="form-group">
      <label>Time to</label>
      <ex-select class="time-select" v-model="form.time_to_hours" :options="hourOptions" :validator="$v.form.time_to_hours" :disabled="readonly" no-style full />
      
      <ex-select class="time-select" v-model="form.time_to_time" :options="timeOptionsOpt" :validator="$v.form.time_to_time" :disabled="readonly" no-style full />
      
      <ex-select class="time-select" v-model="form.time_to_am_pm" :options="amPmOptions" :validator="$v.form.time_to_am_pm" :disabled="readonly" no-style full />
    </div>
    <div class="form-group">
      <label>Timezone:</label>
      <ex-select v-model="form.timezone" label="Timezone" :options="timeZoneOptions" :validator="$v.form.timezone" :disabled="readonly" no-style full />
    </div>
    <div class="form-group">
      <label>Color Code:</label>
      <ex-select v-model="form.color" label="Color" :options="colorOptions" :validator="$v.form.color" :disabled="readonly" no-style full />
    </div>
  </div>

  <tagEmployees
    ref="tagEmployees"
    v-if="isScheduler"
    v-model="selectedEmployees"
    :readonly="readonly"
    :self-remove="selfTagRemove"
    @remove="$emit('remove', $event)" />

  <div class="form-group form-note">
    <label>Note:</label>
    <ex-textarea v-model="form.note" maxlength="500" :disabled="readonly" full no-margin />
  </div>
</form>
</template>

<script>
import TagEmployees from './TagEmployees'
import Datepicker from 'vuejs-datepicker'
import ExInput from '../common/Input'
import ExSelect from '../common/Select'
import ExTextarea from '../common/Textarea'

import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CreateEventForm',

  components: {
    TagEmployees,
    Datepicker,
    ExInput,
    ExSelect,
    ExTextarea
  },

  props: {
    preselectedDate: {
      type: Date,
      default: null
    },

    event: {
      type: Object,
      default: null
    },

    readonly: {
      type: Boolean,
      default: false
    },

    type: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      form: {},
      selectedEmployees: [],
      hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      timezones: [
        'Ottawa, ON, Canada (GMT-4)',
        'Newfoundland (GMT-2:30)',
        'Halifax, NS, Canada (GMT-3)',
        'Winnipeg (GMT-5)',
        'Regina/Edmonton (GMT-6)',
        'Vancouver (GMT-7)'
      ],
      timeOptions: ['00', 15, 30, 45],
      colors: ['Red', 'Green', 'Orange', 'Yellow'],
      amPm: ['AM', 'PM'],
      format: 'YYYY-MM-DD HH:mm:ss',
      disabledDates: {
        to: new Date(Date.now() - 8640000)
      }
    }
  },

  computed: {
    userId () {
      return window.appData.currentUser.id
    },

    isScheduler () {
      return this.type === 'SCHEDULER'
    },

    selfTagRemove () {
      return this.event ? this.event.createdBy.id !== this.userId : false
    },

    hourOptions () {
      return this.hours.map(hour => {
        return {
          id: hour,
          text: hour
        }
      })
    },

    timeOptionsOpt () {
      return this.timeOptions.map(time => {
        return {
          id: time,
          text: time
        }
      })
    },

    amPmOptions () {
      return this.amPm.map(amPm => {
        return {
          id: amPm,
          text: amPm
        }
      })
    },

    timeZoneOptions () {
      return this.timezones.map(timezone => {
        return {
          id: timezone,
          text: timezone
        }
      })
    },

    colorOptions () {
      return this.colors.map(color => {
        return {
          id: color,
          text: color
        }
      })
    }
  },

  watch: {
    event: {
      handler () {
        this.clear()
      },
      immediate: true
    }
  },

  methods: {
    async save () {
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        const loading = this.$loading()
        try {
          const from = this.getFormatedDatetime (this.form.date, this.form.time_from_hours, this.form.time_from_time, this.form.time_from_am_pm)
          const to = this.getFormatedDatetime (this.form.date, this.form.time_to_hours, this.form.time_to_time, this.form.time_to_am_pm)
  
          if (moment(from, this.format).diff(moment(to, this.format)) > 0) return loading.close('"Time to" must be greater than "Time from"', 'error')
          
          const overlap = await this.overlapCheck()
          if (overlap) {
            this.$message.error(`There is already event happening from ${moment(overlap.date_from).format('hh:mm A')} to ${moment(overlap.date_to).format('hh:mm A')}`)
            return loading.close()
          }
  
          const payload = {
            subject: this.form.subject,
            note: this.form.note,
            timezone: this.form.timezone,
            color: this.form.color,
            date_from: from,
            date_to: to,
            type: this.type
          }
          if (!this.event) {
            payload.created = 'CURRENT_TIMESTAMP'
            payload.createdBy = appData.currentUser.id
          }
  
          const event = !this.event ? (await api.create("Event", payload, true)).item : (await api.update("Event", this.event.id, payload, true)).item
          
          if (this.isScheduler) {
            if (!this.selectedEmployees.length && this.event) {
              await api.update("Event", this.event.id, { employees: {} })
            }
            if (this.selectedEmployees.length) {
              const allEmployees = this.$refs.tagEmployees.employees
              const props = this.selectedEmployees.map(id => {
                return { user_id: id }
              })
              await api.update("Event", event.id, { employees: props })
            }
          }
          
          this.$emit('save', event)
          this.clear()
          this.$v.form.$reset()
          loading.close()
          this.$message.success('Saved')
        } catch (e) {
          loading.close()
          this.$message.error(e, 'error')
        }
      }
    },

    async overlapCheck () {
      const from = this.getFormatedDatetime (this.form.date, this.form.time_from_hours, this.form.time_from_time, this.form.time_from_am_pm, true)
      const to = this.getFormatedDatetime (this.form.date, this.form.time_to_hours, this.form.time_to_time, this.form.time_to_am_pm, false)
      let res = (await api.list("Event", { type: this.type, date_from: ["<", to], date_to: [">", from]})).items
      if (this.event) res = res.filter(event => event.id !== this.event.id)
      const overlap = res.length ? res[0] : null
      return overlap
    },

    getFormatedDatetime (date, time_from_hours, time_from_time, time_from_am_pm, check = null) {
      const dt = moment(date).format('YYYY-MM-DD')
      const md = moment(`${dt}, ${time_from_hours}:${time_from_time} ${time_from_am_pm}`, 'YYYY-MM-DD, hh:mm A')
      if (check !== null) {
        if (check)
          md.add(1, 'minutes')
        else
          md.subtract(1, 'minutes')
      }

      return md.format(this.format)
    },

    clear () {
      if (this.event) {
        const date = moment(this.event.date_from)
        const dateTo = moment(this.event.date_to)
        
        this.form = {
          date: date.format('YYYY-MM-DD'),
          subject: this.event.subject,
          color: this.event.color,
          time_from_hours: date.format('h'),
          time_from_time: date.format('mm'),
          time_from_am_pm: date.format('A'),
          time_to_hours: dateTo.format('h'),
          time_to_time: dateTo.format('mm'),
          time_to_am_pm: dateTo.format('A'),
          timezone: this.event.timezone,
          note: this.event.note
        }
        if (this.isScheduler && this.event.employees) {
          this.selectedEmployees = this.event.employees.map(emp => emp.user_id.id)
        }
      } else {
        const currentDate = new Date()
        const date = this.preselectedDate > currentDate ? moment(this.preselectedDate) : moment()
        this.form = {
          date: date.format('YYYY-MM-DD'),
          subject: null,
          color: null,
          time_from_hours: null,
          time_from_time: null,
          time_from_am_pm: null,
          time_to_hours: null,
          time_to_time: null,
          time_to_am_pm: null,
          timezone: null,
          note: null
        }
        this.selectedEmployees = []
      }
      this.$v.form.$reset()
    }
  },

  validations () {
    const rules = {
      form: {
        subject: { required },
        date: { required },
        time_from_hours: { required },
        time_from_time: { required },
        time_from_am_pm: { required },
        time_to_hours: { required },
        time_to_time: { required },
        time_to_am_pm: { required },
        timezone: { required },
        color: { required },
      }
    }
    return rules
  }
}
</script>

<style lang="scss" scoped>
  .form .form-group {
    display: block;
    margin-bottom: 10px;
    width: unset; 
  }
  .form .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  .time-select {
    margin-right: 5px !important;
  }
  .form .form-group textarea {
    display: block;
    width: 100%;
    resize: vertical;
    box-sizing: border-box;
    min-height: 100px;
    padding: 6px 10px;
    border: 1px solid #d6d6d6;
    margin: 6px 0 16px;
    border-radius: 3px;
  }
  .inline-form-group { 
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .inline-form-group .form-group {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
  }
  .form-subject { width: 100% !important; }
  .form-note { width: 100% !important; }
</style>
