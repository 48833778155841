<<template>
  <layout active="/system/information">
    <div class="wrapper">
      <panel title="System Information">
        <form class="form">
          <template v-for="key in Object.keys(form)">
            <ex-input v-model="form[key]" :key="key" class="item" :label="`System ${labels[key]}`" />
          </template>
        </form>

        <div class="images">
          <div class="col">
            <label>System Logo</label>
            <image-upload v-model="logo" :base="base" />
          </div>
          <div class="col">
            <label>System Mail Logo</label>
            <image-upload v-model="mailLogo" :base="base" />
          </div>
        </div>

        <div class="buttons">
          <el-button type="primary" :loading="loading" @click="save()">Save</el-button>
        </div>
      </panel>
    </div>
  </layout>
</template>

<script>
import Layout from '../common/Layout'
import Panel from '../common/Panel'
import ExInput from '../common/Input'
import ExSelect from '../common/Select'
import ImageUpload from '../common/ImageUpload'

export default {
  name: 'SystemInformation',

  components: {
    Panel,
    Layout,
    ExInput,
    ExSelect,
    ImageUpload
  },

  data () {
    return {
      form: {
        name: null,
        url: null,
        CompanyName: null,
        dbaName: null,
        address: null,
        city: null,
        state: null,
        postal: null,
        phone: null,
        supportPhone: null,
        email: null,
        supportEmail: null,
        website: null,
        mailName: null,
        mailAddress: null
      },

      labels: {
        name: 'Name',
        url: 'URL',
        CompanyName: 'Company Name',
        dbaName: 'DBA Name',
        address: 'Address',
        city: 'City',
        state: 'Province/State',
        postal: 'Postal Code/Zip',
        phone: 'Main Phone',
        supportPhone: 'Support Phone',
        email: 'Main Email',
        supportEmail: 'Support Email',
        website: 'Company Website',
        mailName: 'Mail From Name',
        mailAddress: 'Mail From Address'
      },

      base: '/files/system/',
      logo: null,
      mailLogo: null,

      loading: false
    }
  },

  computed: {
    data () {
      return this.$store.state.system.system
    }
  },

  watch: {
    data (data) {
      Object.keys(this.form).forEach(key => this.form[key] = data[key])
      this.logo = data.logo
      this.mailLogo = data.mailLogo
    }
  },

  created () {
    this.$store.dispatch('system/get')
  },

  methods: {
    async save () {
      this.loading = true
      const payload = { ...this.form }

      if (this.logo !== this.data.logo) {
        if (this.logo) 
          await this.$store.dispatch('system/upload', { file: this.logo })
        else
          payload.logo = null
      }

      if (this.mailLogo !== this.data.mailLogo) {
        if (this.mailLogo)
          await this.$store.dispatch('system/upload', { file: this.mailLogo, params: { field: 'mailLogo' } })
        else
          payload.mailLogo = null
      }

      await this.$store.dispatch('system/update', payload)
      this.$message.success('Successfully updated system information.')
      this.loading = false
    }
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 1024px;
  max-width: 100%;
  margin: 0 auto;
  .images {
    margin: 0 auto;
    width: 300px;
    display: flex;
    justify-content: space-between;
    .col {
      text-align: center;
      display: block;
      font-size: 14px;
      font-weight: 300;
      color: #000;
      margin-bottom: 5px;
    }
  }
  .buttons {
    margin-top: 20px;
    text-align: center;
  }
  .item {
    margin-bottom: 20px;
  }
}
</style>
