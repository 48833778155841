<template>
  <div class="page-form">
    <p class="text-center">Temporary Residence – {{ title }}</p>
    
    <p><b>PERSONAL INFORMATION</b></p>

    <p>{{ client.firstName }} - {{ client.lastName }} - {{ age }} - {{ client.nationality | country }} - {{ client.residence | country }}</p>

    <p>Do you have a criminal record? <ex-select v-model="form.criminal_record" :options="yesNoOptions" no-style /></p>

    <p><b>MAIN APPLICANT</b></p>

    <p>Marital Status <ex-select v-model="form.maritial_status" :options="maritialStatusOptions" no-style /></p>

    <p>Medical issues <ex-select v-model="form.medical_issues" :options="yesNoOptions" no-style /></p>

    <p>Status <ex-select v-model="form.status" :options="residenceStatus" no-style /></p>

    <p>Children under 18 <ex-select v-model="form.children" :options="yesNoOptions" no-style /> How many <ex-input v-model.number="form.children_num" type="number" no-style /> Granted custody <ex-select v-model="form.granted_custody" :options="yesNoOptions" no-style /> </p>

    <p>Source of income <ex-input v-model="form.source_of_income" no-style /> Yearly income <ex-input v-model="form.yearly_income" @blur="formatMoney('yearly_income')" no-style /> Personal Net Worth <ex-input v-model="form.personal_net_worth" @blur="formatMoney('personal_net_worth')" no-style /></p>

    <p><b>LANGUAGE SKILLS</b></p>

    <p>IELTS <ex-select v-model="form.IELTS" :options="yesNoOptions" no-style /> Date: <datepicker v-model="form.IELTS_date" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="Date" style="width: 155px; display: inline-block"/> TEFAQ/TEF/DALF/SEL <ex-select v-model="form.TEFAQ" :options="yesNoOptions" no-style /> Date: <datepicker v-model="form.TEFAQ_date" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="Date" style="width: 155px; display: inline-block"/></p>
    
    <p>English (1-10)  Writing <ex-input v-model.number="form.english_writing" type="number" :min="1" :max="10" no-style style="width: 135px;"/> Reading <ex-input v-model.number="form.english_reading" type="number" no-style style="width: 135px;"/> Speaking <ex-input v-model.number="form.english_speaking" type="number" no-style style="width: 135px;"/> Listening <ex-input v-model.number="form.english_listening" type="number" no-style style="width: 135px;"/></p>
    
    <p>French (1-10)  Writing <ex-input v-model.number="form.french_writing" type="number" no-style style="width: 135px;"/> Reading <ex-input v-model.number="form.french_reading" type="number" no-style style="width: 135px;"/> Speaking <ex-input v-model.number="form.french_speaking" type="number" no-style style="width: 135px;"/> Listening <ex-input v-model.number="form.french_listening" type="number" no-style style="width: 135px;"/></p>

    <p><b>EDUCATION AND TRAINING</b></p>

    <p>Highest diploma <ex-input v-model="form.highest_diploma" no-style /> Program Duration <ex-input v-model="form.program_duration" no-style /> Language <ex-input v-model="form.language" no-style /></p>

    <p>Inside Canada <ex-select v-model="form.inside_canada" :options="yesNoOptions" no-style /></p>
    
    <p><b>WORK HISTORY (PAID WORK IN YOUR FIELD)</b></p>

    <p>
      <span class="checkbox-group check-line">Self Employed <input type="checkbox" :checked="self_employed" @change="onChange('self_employed', $event)"></span>
      <span class="checkbox-group check-line">Payroll <input type="checkbox" :checked="payroll" @change="onChange('payroll', $event)"></span>
    </p>
    <p>
      <span class="checkbox-group check-line">Full time <input type="checkbox" :checked="full_time" @change="onChangeFullPart('full_time', $event)"></span>
      <span class="checkbox-group check-line">Part time <input type="checkbox" :checked="part_time" @change="onChangeFullPart('part_time', $event)"></span>
      <span class="checkbox-group check-line">Less than 15h a week <input type="checkbox" :checked="less_then_fifeteen" @change="onChangeFullPart('less_then_fifeteen', $event)"></span>
    </p>
    <p>
      <span class="checkbox-group check-line">Inside Canada <input type="checkbox" :checked="inside_canada_work" @change="onChangeInOutCan('inside_canada_work', $event)"></span>
      <span class="checkbox-group check-line">Outside Canada <input type="checkbox" :checked="outside_canada" @change="onChangeInOutCan('outside_canada', $event)"></span>
    </p>

    <p><b>ADDITIONAL INFORMATION:</b></p>

    <ex-textarea v-model="form.additional_info" :rows="4" no-style full />

  </div>
</template>

<script>
import numeral from 'numeral'

import Datepicker from 'vuejs-datepicker'
import ExInput from '../../../common/Input'
import ExSelect from '../../../common/Select'
import ExTextarea from '../../../common/Textarea'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    category: {
      type: String,
      default: null
    },

    client: {
      type: Object,
      required: true
    }
  },

  components: {
    ExInput,
    ExSelect,
    Datepicker,
    ExTextarea
  },

  data () {
    return {
      form: this.value,

      self_employed: false,
      full_time: false,
      inside_canada_work: false,
      payroll: false,
      part_time: false,
      outside_canada: false,
      less_then_fifeteen: false,

      yesNoOptions: [
        {
          id: 'yes',
          text: 'Yes'
        },
        {
          id: 'no',
          text: 'No'
        }
      ]
    }
  },

  computed: {
    residenceStatus () {
      return window.residenceStatus
    },

    maritialStatusOptions () {
      return window.maritialStatusOptions
    },
    
    title () {
      const titles = {
        visitor_visa: 'Visitor Visa',
        supervisa: 'Super Visa',
        student_permit: 'Student Permit',
        temporary_worker: 'Temporary Worker',
        other: 'Other'
      }
      return titles[this.category]
    },

    age () {
      return moment().diff(moment(this.client.dob), 'years')
    }
  },

  watch: {
    form: {
      handler (form) {
        this.$emit('input', form)
      },
      deep: true,
      immediate: true
    }
  },

  created () {
    if (!Object.keys(this.form).length) {
      this.form = {
        criminal_record: null,
        maritial_status: null,
        medical_issues: null,
        status: null,
        children: null,
        children_num: null,
        granted_custody: null,
        source_of_income: null,
        yearly_income: null,
        personal_net_worth: null,
        IELTS: null,
        IELTS_date: null,
        TEFAQ: null,
        TEFAQ_date: null,
        english_writing: null,
        english_reading: null,
        english_speaking: null,
        english_listening: null,
        french_writing: null,
        french_reading: null,
        french_speaking: null,
        french_listening: null,
        highest_diploma: null,
        program_duration: null,
        language: null,
        inside_canada: null,
        self_employed: false,
        full_time: false,
        inside_canada_work: false,
        payroll: false,
        part_time: false,
        outside_canada: false,
        less_then_fifeteen: false,
        additional_info: null
      }
    }
    this.self_employed = this.form.self_employed
    this.full_time = this.form.full_time
    this.inside_canada_work = this.form.inside_canada_work
    this.payroll = this.form.payroll
    this.part_time = this.form.part_time
    this.outside_canada = this.form.outside_canada
    this.less_then_fifeteen = this.form.less_then_fifeteen
  },

  methods: {
    formatMoney (key) {
      this.form[key] = numeral(this.form[key]).format('$0')
    },
    onChange (key, event) {
      const other = key === 'self_employed' ? 'payroll' : 'self_employed'
      this[key] = !this[key]
      this.form[key] = this[key]
      if (this[key] || this[other]) {
        this[other] = !this[key]
        this.form[other] = !this[key]
      }
    },
    onChangeInOutCan (key, event) {
      const other = key === 'inside_canada_work' ? 'outside_canada' : 'inside_canada_work'
      this[key] = !this[key]
      this.form[key] = this[key]
      if (this[key] || this[other]) {
        this[other] = !this[key]
        this.form[other] = !this[key]
      }
    },
    onChangeFullPart (key, event) {
      if(key === 'full_time') {
        var second = 'part_time'
        var third = 'less_then_fifeteen'
      }
      if(key === 'part_time') {
        var second = 'full_time'
        var third = 'less_then_fifeteen'
      }
      if(key === 'less_then_fifeteen') {
        var second = 'full_time'
        var third = 'part_time'
      }
      this[key] = !this[key]
      this.form[key] = this[key]
      if (this[key] || this[second] || this[third]) {
        this[second] = !this[key]
        this[third] = !this[key]
        this.form[second] = !this[key]
        this.form[third] = !this[key]
      }
    },
  }
}
</script>
<style lang="scss" scoped>
  .check-line {
    display: inline-block; 
    width: 200px;  
  }
</style>
