<template>
  <modal
    title="New Invoice"
    :width="800"
    @close="$emit('close')">
    <div class="layout">
      <div class="col-6">
        <ex-input v-model="form.firstName" label="First Name" :validator="$v.form.firstName" full />
      </div>
      <div class="col-6">
        <ex-input v-model="form.lastName" label="Last Name" :validator="$v.form.lastName" full />
      </div>
      <div class="col-12">
        <ex-input v-model="form.address" label="Address" :validator="$v.form.address" full />
      </div>
      <div class="col-6">
        <ex-select v-model="form.country" label="Country" :validator="$v.form.country" :options="countryOptions" full />
      </div>
      <div class="col-6">
        <ex-select v-model="state" label="State/Province" :disabled="!stateOptions" :validator="$v.form.state" :options="stateOptions" full />
      </div>
      <div class="col-6">
        <ex-input v-model="form.city" label="City" :validator="$v.form.city" full />
      </div>
      <div class="col-6">
        <ex-input v-model="form.postal" label="Postal code" :validator="$v.form.postal" full />
      </div>
      <div class="col-6">
        <ex-input v-model="form.clientId" label="Client ID" full />
      </div>
      <div class="col-6">
        <ex-input v-model="form.accountNumber" label="Account Number" full />
      </div>
      <div class="col-6 mt-20">
        <ex-input
          v-model.number="form.gst"
          :validator="$v.form.gst"
          :label="`GST - ${system.federalTaxNumber}`"
          :placeholder="`GST - ${system.federalTaxNumber} amount`"
          full
          @blur="formatCurrency('gst')" />
      </div>
      <div class="col-6 mt-20">
        <ex-input
          v-model.number="form.qst"
          :validator="$v.form.qst"
          :label="`QST - ${system.provincialTaxNumber}`"
          :placeholder="`QST - ${system.provincialTaxNumber} amount`"
          full
          @blur="formatCurrency('qst')" />
      </div>
    </div>

    
    <div class="px-20">
      <table class="form-table mt-20">
        <tr>
          <td width="150">Date</td>
          <td>Description</td>
          <td width="150">Amount</td>
          <td width="30"></td>
        </tr>
        <tr
          v-for="(item, index) in form.items"
          :key="index">
          <td>
            <datepicker v-model="form.items[index].date" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="Estimated Date of Completion" no-style />
          </td>
          <td>
            <ex-input v-model="form.items[index].description" :validator="$v.form.items.$each[index].description" label="Description" no-style full />
          </td>
          <td>
            <ex-input v-model.number="form.items[index].amount" :validator="$v.form.items.$each[index].amount" label="Amount" no-style full no-margin @blur="formatCurrency(index)" />
          </td>
          <td>
            <el-button size="mini" icon="el-icon-delete" type="danger" @click="remove(index)" />
          </td>
        </tr>
      </table>
    </div>

    <div
      class="flex-center mt-10">
      <el-button
        type="primary"
        icon="el-icon-plus"
        @click="addNewItem()" />
    </div>

    <div class="buttons mt-20">
      <el-button @click="$emit('close')">Cancel</el-button>
      <el-button
        type="primary"
        :loading="loading"
        @click="save()">
        Submit
      </el-button>
    </div>
  </modal>
</template>

<script>
import numeral from 'numeral'
import helperMixin from '../common/helpers-mixin'
import { required } from 'vuelidate/lib/validators'

import Modal from '../common/Modal'
import ExInput from '../common/Input'
import ExSelect from '../common/Select'
import Datepicker from 'vuejs-datepicker'
import ExTextarea from '../common/Textarea'

export default {
  mixins: [ helperMixin ],

  components: {
    Modal,
    ExInput,
    ExSelect,
    Datepicker,
    ExTextarea
  },

  data () {
    return {
      form: {
        firstName: null,
        lastName: null,
        address: null,
        city: null,
        state: null,
        postal: null,
        country: null,
        clientId: null,
        accountNumber: null,
        items: [],
        gst: null,
        qst: null
      },
      state: null,
      loading: false
    }
  },
  
  computed: {
    system () {
      return window.appData.company
    },

    stateOptions () {
      return (['CA', 'US'].includes(this.form.country)) ? window.states[this.form.country].map(state => {
        return {
          id: state.id,
          text: `${state.id} - ${state.text}`
        }
      }) : null
    }
  },

  watch: {
    state (value) {
      if (value) this.form.state = this.getInitials(value)
    }
  },

  created () {
    this.addNewItem()
  },

  methods: {
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.loading = true
      const payload = {
        ...this.form,
        createdAt: 'CURRENT_TIMESTAMP',
        createdBy: window.appData.currentUser.id
      }
      payload.items.map(item => ({
        ...item,
        date: moment(item.date).format('YYYY-MM-DD')
      }))
      try {
        const invoice = await this.$store.dispatch('invoices/create', payload)
        const response = await api.request('POST', `/invoice/${invoice.id}/pdf`)
        await this.$store.commit('invoices/update', response.item)
        this.$message.success(`Inovice successfully created.`)
        this.$emit('close')
      } catch (e) {
        this.$message.error('There was an error... Please try again.')
      } finally {
        this.loading = false
      }
    },

    addNewItem () {
      this.form.items.push({
        date: null,
        description: null,
        amount: null
      })
    },

    remove (index) {
      this.form.items.splice(index, 1)
    },

    getInitials (value) {
      if (!value) return null
      return value.split('').splice(0, 2).join('')
    },

    formatCurrency (index) {
      this.form.items[index].amount = numeral(this.form.items[index].amount).format('$0.00')
    }
  },

  validations () {
    const rules = {
      form: {
        firstName: { required },
        lastName: { required },
        address: { required },
        city: { required },
        postal: { required },
        country: { required },
        items: {
          $each: {
            date: { required },
            description: { required },
            amount: { required }
          }
        },
        gst: { required },
        qst: { required }
      }
    }

    if (['CA', 'US'].includes(this.form.country)) {
      rules.form.state = { required }
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  justify-content: center;
}
</style>