<template>
  <div class="mb-50">
    <table class="form-table">
      <tr class="heading">
        <td colspan="8"><b>7. Information about Family Members:</b> (Husband / Wife / Common-law partner,children, parents, siblings)</td>
      </tr>
    </table>
    
    <table class="form-table">
      <tr>
        <td rowspan="2">Surname</td>
        <td rowspan="2">Name</td>
        <td rowspan="2">Relation type (Mother, Father, Children, etc)</td>
        <td rowspan="2">Marital status (Single, Married, Divorced, Widowed, etc)</td>
        <td rowspan="2">Date of Birth</td>
        <td rowspan="2">Place of Birth</td>
        <td colspan="3">Place where they live</td>
        <td rowspan="2">Date of death (if applicable) (Year/month/day)</td>
        <td v-if="!readonly" rowspan="2" />
      </tr>
      <tr>
        <td>Address</td>
        <td>City</td>
        <td>Country</td>
      </tr>
      <template v-if="readonly">
        <tr
          v-for="document, idx in form.familyMembers"
          :key="idx">
          <td>{{ form.familyMembers[idx].lastName }}</td>
          <td>{{ form.familyMembers[idx].firstName }}</td>
          <td>{{ form.familyMembers[idx].relationType }}</td>
          <td>{{ form.familyMembers[idx].maritalStatus }}</td>
          <td>{{ form.familyMembers[idx].dob | date }}</td>
          <td>{{ form.familyMembers[idx].birthPlace }}</td>
          <td>{{ form.familyMembers[idx].address }}</td>
          <td>{{ form.familyMembers[idx].city }}</td>
          <td>{{ form.familyMembers[idx].country | country }}</td>
          <td>{{ form.familyMembers[idx].deathDate | date }}</td>
        </tr>
      </template>
      <template v-else>
        <tr
          v-for="document, idx in form.familyMembers"
          :key="idx">
          <td><ex-input v-model="form.familyMembers[idx].lastName" no-style full /></td>
          <td><ex-input v-model="form.familyMembers[idx].firstName" no-style full /></td>
          <td><ex-input v-model="form.familyMembers[idx].relationType" no-style full /></td>
          <td><ex-input v-model="form.familyMembers[idx].maritalStatus" no-style full /></td>
          <td><datepicker v-model="form.familyMembers[idx].dob" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="Date of birth" no-style /></td>
          <td><ex-input v-model="form.familyMembers[idx].birthPlace" no-style full /></td>
          <td><ex-input v-model="form.familyMembers[idx].address" no-style full /></td>
          <td><ex-input v-model="form.familyMembers[idx].city" no-style full /></td>
          <td><ex-select v-model="form.familyMembers[idx].country" :options="countryOptions" no-style full /></td>
          <td><datepicker v-model="form.familyMembers[idx].deathDate" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="Date of death" no-style /></td>
          <td>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              round
              :disabled="idx === 0"
              @click="form.familyMembers.splice(idx, 1)" />
          </td>
        </tr>
      </template>
    </table>

    <div class="mt-10">
      <el-button
        v-if="!readonly"
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="$emit('add')">
        Add Family Member Info
      </el-button>
    </div>
  </div>
</template>

<script>
import helperMixin from '../../common/helpers-mixin'

import ExInput from '../../common/Input'
import ExSelect from '../../common/Select'
import Datepicker from 'vuejs-datepicker'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    readonly: {
      type: Boolean,
      default: true
    }
  },

  mixins: [ helperMixin ],

  components: {
    ExInput,
    ExSelect,
    Datepicker
  },

  data () {
    return {
      form: this.value
    }
  }
}
</script>

<style lang="scss" scoped>
  .remove-item {
    float: right;
  }
</style>