<template>
  <div>
    <p>Do {{ isSpouse ? 'they' : 'you' }} have a criminal record? <span class="answer">{{ formatYN(form.criminal_record) }}</span></p>


    <p>Preferred destination in Canada <span class="answer">{{ form.preferred_destination_ca | state }}</span>
      <span class="ml-20">Personal Net Worth <span class="answer">{{ form.net_worth }}</span></span>
    </p>
    
    <p><b>LANGUAGE SKILLS:</b></p>

    <p>IELTS <span class="answer">{{ formatYN(form.ielts) }}</span>
      <span class="ml-20">
        Date: <span class="answer">{{ form.ielts_date | date }}</span>
      </span>
      <span class="ml-20">
        TEFAQ/TEF/DALF/SEL <span class="answer">{{ formatYN(form.tefaq_tef_dalf_sel) }}</span>
      </span>
      <span class="ml-20">
        Date: <span class="answer">{{ form.tefaq_tef_dalf_sel_date | date }}</span>
      </span>
    </p>

    <p>English (1-10)
      <span v-for="(name, key) in lang_skils" :key="key" class="ml-20">
        {{ name }} <span class="answer">{{ form[`english_${key}`] }}</span>
      </span>
    </p>

    <p>French (1-10)
      <span v-for="(name, key) in lang_skils" :key="key" class="ml-20">
        {{ name }} <span class="answer">{{ form[`french_${key}`] }}</span>
      </span>
    </p>

    <p><b>EDUCATION AND TRAINING:</b></p>
    <p>
      <span>Highest diploma <span class="answer">{{ form.diploma }}</span></span>
      <span class="ml-20">Program Duration <span class="answer">{{ form.program_duration }}</span></span>
      <span class="ml-20">Language <span class="answer">{{ form.language }}</span></span>
      <br>Inside Canada <span class="answer">{{ formatYN(form.inside_canada) }}</span>
    </p>

    <p><b>WORK HISTORY (PAID WORK IN YOUR FIELD)</b></p>
    <p>
      <span>Self-employed <span class="checkbox-group"><input :checked="form.self_employed" type="checkbox" disabled></span></span>
      <span class="ml-20">Payroll <span class="checkbox-group"><input :checked="form.payroll" type="checkbox" disabled></span></span>
      <br><span>Full time <span class="checkbox-group"><input :checked="form.full_time" type="checkbox" disabled></span></span>
      <span class="ml-20">Part time <span class="checkbox-group"><input :checked="form.part_time" type="checkbox" disabled></span></span>
      <span class="ml-20">Less than 15h a week <span class="checkbox-group"><input :checked="form.less_than_15h_week" type="checkbox" disabled></span></span>
      <br><span>Inside Canada <span class="checkbox-group"><input :checked="form.work_inside_canada" type="checkbox" disabled></span></span>
      <span class="ml-20">Outside Canada <span class="checkbox-group"><input :checked="form.work_outside_canada" type="checkbox" disabled></span></span>
    </p>

    <p><b>FAMILY INFORMATION</b></p>

    <p>
      Family in Canada 18+ <span class="answer">{{ formatYN(form.familiy_in_ca) }}</span>
      <span class="ml-20">Family in Manitoba 18+ <span class="answer">{{ formatYN(form.familiy_in_manitoba) }}</span></span>
      <br><span>Children aged less than 18 y/o <span class="answer">{{ formatYN(form.children_aged_less_18) }}</span></span>
      <span class="ml-20">Children aged less than 6 y/o <span class="answer">{{ formatYN(form.children_aged_less_6) }}</span></span>
      <template v-if="!isSpouse">
        <br><span>Marital status <span class="answer">{{ form.marital_status | maritialStatus }}</span></span>
      </template>
    </p>
  </div>
</template>

<script>
import numeral from 'numeral'

import Datepicker from 'vuejs-datepicker'
import ExInput from '../../../common/Input'
import ExSelect from '../../../common/Select'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    isSpouse: {
      type: Boolean,
      default: false
    },

    client: {
      type: Object,
      required: true
    }
  },

  components: {
    ExInput,
    ExSelect,
    Datepicker
  },

  data () {
    return {
      form: this.value,
  
      lang_skils: {
        writing: 'Writing',
        reading: 'Reading',
        speaking: 'Speaking',
        listening: 'Listening'
      }
    }
  },

  computed: {
    age () {
      return moment().diff(moment(this.client.dob), 'years')
    }
  },

  methods: {    
    formatYN (val) {
      return val === 'yes' ? 'Yes' : 'No'
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox-group {
  margin-left: 10px;
  input {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
}
</style>