<template>
  <div class="page-form">
    <p class="text-center">Legal - Refugee Claim</p>
    
    <p><b>PERSONAL INFORMATION</b></p>

    <p>{{ client.firstName }} - {{ client.lastName }} - {{ age }} - {{ client.nationality | country }} - {{ client.residence | country }}</p>

    <p>Do you have a criminal record? <span class="answer">{{ formatYN(value.criminal_record) }}</span></p>

    <p><b>MAIN APPLICANT</b></p>

    <p>Are you currently living in Canada <span class="answer">{{ formatYN(value.living_in_canada) }}</span></p>

    <p v-if="isLivingInCanada">What is your status in Canada? <span class="answer">{{ formatStatusInCanada(value.status_in_canada) }}</span></p>

    <p>Do you have family in Canada? <span class="answer">{{ formatYN(value.family_in_canada) }}</span></p>
    
    <p>Will you be claiming refugee with other members of your family? <span class="answer">{{ formatYN(value.other_members_of_family) }}</span></p>
    
    <p>Are you travelling with your children? <span class="answer">{{ formatYN(value.with_children) }}</span></p>

    <p v-if="travelingWithChildren">Are they in your custody? <span class="answer">{{ formatYN(value.custody) }}</span></p>

    <p>Did you claim refugee in another country? <span class="answer">{{ formatYN(value.another_country) }}</span></p>

    <p>Have you been removed from another country? <span class="answer">{{ formatYN(value.removed_from_another_country) }}</span></p>

    <p>Do you or any of your family members suffer from an important medical condition? <span class="answer">{{ formatYN(value.medical_condition) }}</span></p>

    <p>List the countries where you believe that you are at risk of serious harm <span class="answer">{{ formatSeriousHarm(value.seriousHarm) }}</span></p>

    <p><span v-if="value.seriousHarm1 !== null" class="answer" style="margin-left: 508px;">{{ formatSeriousHarm(value.seriousHarm1) }}</span></p>

    <p><span v-if="value.seriousHarm2 !== null" class="answer" style="margin-left: 508px;">{{ formatSeriousHarm(value.seriousHarm1) }}</span></p>

    <p>Did you ask any authorities in your country for protection? <span class="answer">{{ formatYN(value.authorities_for_protection) }}</span></p>

    <p>Did you move to another part of the country to seek safety? <span class="answer">{{ formatYN(value.move_to_another_country) }}</span></p>

    <p>Did you move to another country (other than Canada), to seek safety? <span class="answer">{{ formatYN(value.another_country_safety) }}</span></p>

    <p><b>ADDITIONAL INFORMATION:</b></p>

    <span class="answer">{{ value.additional_info }}</span>

  </div>
</template>

<script>
import numeral from 'numeral'

import Datepicker from 'vuejs-datepicker'
import ExInput from '../../../common/Input'
import ExSelect from '../../../common/Select'
import ExTextarea from '../../../common/Textarea'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    client: {
      type: Object,
      required: true
    }
  },

  computed: {
    age () {
      return moment().diff(moment(this.client.dob), 'years')
    }
  },

  methods: {
    formatYN (val) {
      return val === 'yes' ? 'Yes' : 'No'
    },

    formatStatusInCanada(status) {
      const statusInCanada = window.statusInCanada
      return statusInCanada.find( item => item.id === status).text
    },

    formatSeriousHarm(status) {
      const countriesOptions = window.countriesOptions
      return countriesOptions.find( item => item.id === status).text
    }
  }
}
</script>
