<template>
  <layout active="/system/messages">
    <div class="wrapper">
      <panel title="Enter new system message">
        <form class="form">
          <el-input
            v-model="form.message"
            :rows="10"
            type="textarea"
            resize="none"
            placeholder="Enter new system message">
          </el-input>
          <span v-if="$v.form.message.$dirty && $v.form.message.$invalid" class="error">This field is required !</span>
        </form>

        <div class="button">
          <el-button type="primary" @click="save()">Create</el-button>
        </div>
      </panel>

      <div class="table-wrapper">
        <el-table :data="data" style="min-width: 100%" stripe>
          <el-table-column label="Message">
            <template slot-scope="scope">
              {{ scope.row.message }}
            </template>
          </el-table-column>
          <el-table-column label="Created By">
            <template slot-scope="scope">
              {{ scope.row.createdBy.firstName }} {{ scope.row.createdBy.lastName }}
            </template>
          </el-table-column>
          <el-table-column label="Created At">
            <template slot-scope="scope">
              {{ scope.row.createdAt | date }}
            </template>
          </el-table-column>
          <el-table-column label="Actions" align="right">
            <template slot-scope="scope">
              <el-button size="mini" type="danger" icon="el-icon-remove" @click="remove(scope.row.id)">Delete</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </layout>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import Panel from '../common/Panel'
import Layout from '../common/Layout'
import { mapActions } from 'vuex'

export default {
  name: 'system-messages',

  components: {
    Panel,
    Layout
  },

  data () {
    return {
      form: {
        message: null
      }
    }
  },

  computed: {
    data () {
      return this.$store.state.system.messages.data
    }
  },

  created () {
    this.load(['createdAt:DESC'])
  },

  methods: {
    ...mapActions({
      load: 'system/messages/load',
      create: 'system/messages/create',
      deleteMessage: 'system/messages/delete',
    }),

    async save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          const loading = this.$loading()
          const payload = {
            message: this.form.message,
            createdAt: 'CURRENT_TIMESTAMP',
            createdBy: window.appData.currentUser.id
          }
          
          this.create(payload)

          this.$message.success('System message successfully created.')
          this.form.message = null
          this.$v.$reset()
          loading.close()
        } catch {}
      }
    },

    async remove (id) {
      try {
        await this.$confirm(`Are you sure to delete System Message?`, 'Warning', {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          type: 'error'
        })
        this.deleteMessage(id)
        this.$message.success('System Message deleted successfully.')
      } catch {}
    }
  },

  validations: {
    form: {
      message: { required }
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrapper {
    width: 900px;
    margin: 0 auto;
    .form {
      padding: 0;
      justify-content: center;
    }
    .button {
      margin-top: 20px;
      text-align: center;
    }
  }

  .table-wrapper {
    margin-top: 50px;
  }

  .error {
    display: block;
    margin-top: 5px;
    color: #d23131;
  }
</style>