<template>
  <div class="table-wrapper">
    <el-table
      v-loading="loading"
      :data="logs"
      style="min-width: 100%"
      stripe>
      <el-table-column label="First Name">
        <template slot-scope="scope">{{ scope.row.userId.firstName }}</template>
      </el-table-column>
      <el-table-column label="Last Name">
        <template slot-scope="scope">{{ scope.row.userId.lastName }}</template>
      </el-table-column>
      <el-table-column label="Time">
        <template slot-scope="scope">{{ scope.row.recorded | date }}</template>
      </el-table-column>
      <el-table-column label="Data Transfer">
        <template slot-scope="scope">{{ scope.row.transferred }}</template>
      </el-table-column>
      <el-table-column label="IP Adress">
        <template slot-scope="scope">{{ scope.row.ipAddress }}</template>
      </el-table-column>
    </el-table>
 </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },

    logs: {
      type: Array,
      required: true
    }
  }
}
</script>