<template>
  <div>
    <div class="buttons">
      <el-button size="mini" icon="el-icon-plus" type="primary">New</el-button>
      <el-button size="mini" icon="el-icon-edit" type="primary">Edit</el-button>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.buttons {
  margin-bottom: 20px;
}
</style>