<template>
  <div class="mb-50">
    <table class="form-table">
      <tr class="heading">
        <td colspan="8"><b>5. Activity Information:</b> Your activities over the last ten years. Include periods  without  employment, studies, etc.</td>
      </tr>
      <tr>
        <td colspan="8">
          <h2>(Do not leave empty time periods)</h2>
        </td>
      </tr>
      <tr>
        <td>From (Year/Month/Day)</td>
        <td>A (Year/Month/Day)</td>
        <td>Activity</td>
        <td>City</td>
        <td>Country</td>
        <td>Status in  that country</td>
        <td>Company</td>
        <td v-if="!readonly" />
      </tr>
      <template v-if="readonly">
        <tr
          v-for="document, idx in form.activities"
          :key="idx">
          <td>{{ form.activities[idx].from | date }}</td>
          <td>{{ form.activities[idx].to | date }}</td>
          <td>{{ form.activities[idx].activity }}</td>
          <td>{{ form.activities[idx].city }}</td>
          <td>{{ form.activities[idx].country | country }}</td>
          <td>{{ form.activities[idx].status }}</td>
          <td>{{ form.activities[idx].company }}</td>
        </tr>
      </template>
      <template v-else>
        <tr
          v-for="document, idx in form.activities"
          :key="idx">
          <td><datepicker v-model="form.activities[idx].from" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="From" no-style /></td>
          <td><datepicker v-model="form.activities[idx].to" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="A" no-style /></td>
          <td><ex-input v-model="form.activities[idx].activity" no-style full /></td>
          <td><ex-input v-model="form.activities[idx].city" no-style full /></td>
          <td><ex-select v-model="form.activities[idx].country" :options="countryOptions" no-style full /></td>
          <td><ex-input v-model="form.activities[idx].status" no-style full /></td>
          <td><ex-input v-model="form.activities[idx].company" no-style full /></td>
          <td>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              round
              :disabled="idx === 0"
              @click="form.activities.splice(idx, 1)" />
          </td>
        </tr>
      </template>
    </table>

    <div class="mt-10">
      <el-button
        v-if="!readonly"
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="$emit('add')">
        Add Activity Information 
      </el-button>
    </div>
  </div>
</template>

<script>
import helperMixin from '../../common/helpers-mixin'

import ExInput from '../../common/Input'
import ExSelect from '../../common/Select'
import Datepicker from 'vuejs-datepicker'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    readonly: {
      type: Boolean,
      default: true
    }
  },

  mixins: [ helperMixin ],

  components: {
    ExInput,
    ExSelect,
    Datepicker
  },

  data () {
    return {
      form: this.value
    }
  }
}
</script>

<style lang="scss" scoped>
  .remove-item {
    float: right;
  }
  h2 {
    display: block;
    text-align: center;
    font-size: 25px;
  }
</style>