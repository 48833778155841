<template>
  <div class="scheduler-panel">
    <div class="panel-title">
      <div class="panel-date">
        <chButton v-if="!showAddNewTask" @click="showAddNewTask = true">Add New Task</chButton>
        <template v-else>
          <chButton color="gray" @click="showAddNewTask = false">Cancel</chButton>
          <chButton @click="$refs.addTask.save()">Save New Task</chButton>
        </template>
      </div>
    </div>

    <transition name="slide">
      <add-task
        ref="addTask"
        v-show="showAddNewTask"
        :type="type"
        @save="onSave($event)" />    
    </transition>
    
    <span v-if="!tasks">Loading...</span>
    <ul
      v-else-if="tasks && tasks.length"
      class="list">
      <li v-for="(task, index) in tasks" :key="index" class="list-item">
        <div class="display-inline mr-10">
          <chButton v-if="task.done == 0" size="sm" color="success" @click="markAsDone(task.id, index)">&#10003;</chButton>
          <chButton v-if="!(isScheduler && task.createdBy.id !== userId)" size="sm" color="danger" @click="remove(task, index)">&#10006;</chButton>
        </div>
        <span :class="{ 'striked-text': task.done == 1 }">
          {{ formatDate(task.date) }}
          <a v-if="task.employees.length" class="link"> - created by {{ task.createdBy.firstName }}</a>:
          <b>{{ task.text }}</b><br>
          <template v-if="task.employees.length">
            Associated Employees:
            <ul class="tags small">
              <el-tag
                v-for="item in task.employees"
                :key="item.id"
                effect="dark"
                class="mr-5"
                size="mini"
                :closable="task.createdBy.id === userId || item.user_id.id === userId"
                @close="removeEmployee(task, item)">
                {{ item.user_id.firstName }} {{ item.user_id.lastName }}
              </el-tag>
            </ul>
          </template>
        </span>
      </li>
    </ul>
    <span v-else>There are no tasks.</span>
  </div>
</template>

<script>
import Panel from '../common/Panel'
import ChButton from '../common/ChButton'
import AddTask from '../common/AddTask'

export default {
  name: 'TaskList',

  components: {
    Panel,
    ChButton,
    AddTask
  },

  props: {
    type: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      tasks: null,
      showAddNewTask: false,
    }
  },

  computed: {
    userId () {
      return window.appData.currentUser.id
    },

    isScheduler () {
      return this.type === 'SCHEDULER'
    }
  },

  created () {
    this.getData()
  },

  methods: {
    async removeEmployee (task, item) {
      const notTag = (task.createdBy.id === this.userId)
      const msg = notTag
        ? `Are you sure to remove ${item.user_id.first_name} ${item.user_id.lastName} from this task?`
        : 'Are you sure to remove this task from your scheduler?'
      await this.$confirm(msg)
      const loading = this.$loading()
      await api.delete('TaskEmployee', item.id)
      if (notTag) {
        const idx = task.employees.findIndex(emp => emp.id === item.id)
        task.employees.splice(idx, 1)
      } else {
        const idx = this.tasks.findIndex(t => t.id === task.id)
        this.tasks.splice(idx, 1)
      }
      loading.close()
      this.$message.error('Tag removed successfully.')
    },

    async markAsDone (id, index) {
      const loading = this.$loading()
      await api.update('TaskList', id, { done: 1 })
      this.tasks[index].done = 1
      loading.close()
      this.$message.success('Task marked as done.')
    },

    async remove (task, index) {
      await this.$confirm(`Are you sure you want to delete this task: "${task.text}"?`)
      const loading = this.$loading()
      await api.delete('TaskList', task.id)
      this.tasks.splice(index, 1)
      loading.close()
      this.$message.success('Task deleted successfully.')
    },

    async getData () {
      const loading = this.$loading()
      const today = moment().format('YYYY-MM-DD')
      const payload = window.appData.currentUser.groups !== 'admin-super' || this.isScheduler ? { createdBy: this.userId } : {}
      payload.type = this.type
      payload.done = ['=', 0]
      payload.date = ['>=', today]
      const future = (await api.list("TaskList", payload, ['date:ASC'])).items
      payload.date = ['<', today]
      const past = (await api.list('TaskList', payload, ['date:DESC'])).items

      const donePayload = {
        done: ['=', 1],
        type: this.type
      }
      if (payload.createdBy) {
        donePayload.createdBy = payload.createdBy
      }
      const done = (await api.list('TaskList', donePayload, ['date:DESC'])).items

      let doneShared = []
      let pastShared = []
      let furureShared = []
      if (this.isScheduler) {
        payload.createdBy = ['!=', this.userId]
        donePayload.createdBy = ['!=', this.userId]
        payload['employees.user_id'] = this.userId
        donePayload['employees.user_id'] = this.userId
        pastShared = (await api.list("TaskList", payload, ['date:ASC'])).items
        payload.date = ['>=', today]
        furureShared = (await api.list("TaskList", payload, ['date:ASC'])).items
        doneShared = (await api.list('TaskList', donePayload, ['date:DESC'])).items
      }

      this.tasks = [
        ...future,
        ...furureShared,
        ...past,
        ...pastShared,
        ...done,
        ...doneShared
      ]
      loading.close()
    },

    onSave (task) {
      this.getData()
    },

    formatDate (date) {
      return moment(date).format('MM/DD/YYYY hh:mm A')
    }
  }
}
</script>
