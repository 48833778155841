<template>
  <layout v-if="data" active="/system/employees">
    <tabs v-model="activeTab" :tabs="tabs">
      <template v-if="activeTab === 'Access'">
        <el-button :disabled="isActive" size="mini" icon="el-icon-refresh" @click="newCode()">Reset Activation Code</el-button>
        <el-button :disabled="!isActive" size="mini" icon="el-icon-key" @click="resetPassword()">Reset Password</el-button>
        <el-button :disabled="!isActive" size="mini" type="danger" icon="el-icon-remove" @click="block()">{{ isBlocked ? 'Unblock' : 'Block' }}</el-button>
      </template>
    </tabs>
    <panel>
      <template v-if="activeTab === 'Employee Info'">
        <GeneralInfo :data="data" />
      </template>
      <template v-if="activeTab === 'Access'">
        <div class="info-box">
          <h3>Access Information</h3>
          <div class="col">
            <span>Activation Code</span>
            <b v-if="isActive">N/A</b>
            <template v-else>
              <b v-if="!isACExpired">{{ data.activationCode }}</b>
              <b v-else class="red">Activation Code has expired!</b>
            </template>
          </div>
          <div class="col">
            <span>Activation Code Expires</span>
            <b v-if="!isActive && !isACExpired && activationExpires">{{ activationExpires | date }}</b>
            <b v-else>N/A</b>
          </div>
          <div class="col">
            <span>Activation Date</span>
            <b v-if="isActive">{{ data.activationDate | date('PD') }}</b>
            <b v-else>N/A</b>
          </div>
          <div class="col">
            <span>Reset Request</span>
            <b v-if="data.pwResetRequested">{{ data.pwResetRequested | date('PD') }}</b>
            <b v-else>N/A</b>
          </div>
          <div class="col">
            <span>Reset Complete</span>
            <b v-if="data.resetPwDate">{{ data.resetPwDate | date('PD') }}</b>
            <b v-else>N/A</b>
          </div>
          <div class="col">
            <span>Blocked</span>
            <b>{{ isBlocked ? 'Yes' : 'No' }}</b>
          </div>
        </div>
      </template>
      <EmployeeDocuments v-if="activeTab === 'Documents'" :id="id" />
    </panel>
  </layout>
</template>

<script>
import Tabs from '../common/Tabs'
import Panel from '../common/Panel'
import Layout from '../common/Layout'
import GeneralInfo from './components/GeneralInfo'
import EmployeeDocuments from './components/EmployeeDocuments'

export default {
  name: 'Employee',

  components: {
    Tabs,
    Panel,
    Layout,
    GeneralInfo,
    EmployeeDocuments
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      activeTab: 'Employee Info',
      tabs: [
        ['Employee Info', 'Documents', 'Notes & Emails'],
        ['Access', 'Permissions', 'Account Settings', 'User Log']
      ]
    }
  },

  computed: {
    data () {
      return this.$store.state.users.user
    },

    isBlocked () {
      return this.data ? this.data.active === 'Blocked' : false
    },

    isActive () {
      return this.data ? this.data.active !== 'No' : false
    },

    activationExpires () {
      if (!this.data || this.isACExpired || !this.data.activationCodeExpires) return null
      return new Date(parseInt(this.data.activationCodeExpires) * 1000);
    },

    isACExpired () {
      if (!this.data || this.isActive || (this.data && !this.data.activationCodeExpires)) return false
      return this.data.activationCodeExpires < Math.round(new Date().getTime()/1000);
    }
  },

  created () {
    this.load()
  },

  methods: {
    async block () {
      try {
        const prefix = this.isBlocked ? 'un' : ''
        await this.$confirm(`Are you sure you want to ${prefix}block this Employee?`, 'Warning', {
          confirmButtonText: this.isBlocked ? 'Unblock' : 'Block',
          cancelButtonText: 'Cancel',
          type: 'error'
        })
        this.data = (await api.update('User', this.id, { active: this.isBlocked ? 'Yes' : 'Blocked' }, true)).item
        this.$message.success(`Employee successfully ${prefix}blocked.`)
      } catch {}
    },

    async resetPassword () {
      try {
        await this.$confirm('Are you sure you want to reset password?', 'Warning', {
          confirmButtonText: 'Reset',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })
        this.data = (await api.action('User', this.id, 'ResetPassword', true)).item
        this.$message.success(`Password reset successfully completed.`)
      } catch {}
    },
    
    async newCode () {
      if (!this.isActive) {
        try {
          await this.$confirm('Are you sure you want to reset Activation Code?', 'Warning', {
            confirmButtonText: 'Reset',
            cancelButtonText: 'Cancel',
            type: 'warning'
          })
          this.data = (await api.action('User', this.data.id, 'NewActivationCode')).item
          this.$alert(`Activation Code is successfully generated and it's valid for 1 hour.<br><br>New Activation Code is: <b>${this.data.activationCode}</b>`, 'Activation Code', {
            confirmButtonText: 'OK',
            dangerouslyUseHTMLString: true
          })
        } catch {}
      }
    },

    async load () {
      this.$store.dispatch('users/get', this.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.red {
  color: #b43434 !important;
}
</style>