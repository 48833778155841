<template>
  <nav class="submenu" :class="classes">
    <a v-if="back" href="/">
      <button class="back"><i /> Back to Main</button>
    </a>
    <a v-if="dashboard" :href="dashboard.to">
      <button class="back"><i /> {{ dashboard.text }}</button>
    </a>
    <ul class="menu">
      <li v-for="item in menu" :key="item.text">
        <template v-if="item.list">
          <span> {{ item.text }} <i /></span>
          <i class="arrow-up" />
          <ul>
            <li v-for="(subitem, idx) in item.list" :key="idx">
              <a :href="subitem.to">{{ subitem.text }}</a>
            </li>
          </ul>
        </template>
        <a v-else :href="item.to">{{ item.text }}</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    back: {
      type: Boolean,
      default: false
    },

    dashboard: {
      type: Object,
      default: null
    },

    menu: {
      type: Array,
      required: true
    }, 

    classes: {
      type: String,
      default: null 
    }
  }
}
</script>

<style lang="scss" scoped>
.submenu {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 5px;
  background-color: #e5e5e5;
  &.left { 
    justify-content: flex-start; 
  }
  .back {
    display: inline-block;
    vertical-align: top;
    padding: 0 15px;
    height: 50px;
    background-color: #e5e5e5;
    //border-radius: 5px;
    border: none;
    border-right: 1px solid #d5d5d5;
    outline: none;
    font-size: 13px;
    line-height: 50px;
    text-transform: uppercase;
    color: #a0a0a0;
    //margin-right: 20px;
    color: #000;
    i {
      margin-right: 5px;
      width: 13px;
      height: 50px;
      display: inline-block;
      vertical-align: top;
      background-image: url("/assets/images/back-button-icon.png");
      background-repeat: no-repeat;
      background-position: center left;
    }
    &:hover, &:focus {
      color: #5f90ec;
      cursor: pointer;
      i {
        background-position: center right;
      }
    }
  }

  .menu { 
    display: inline-block; 
    vertical-align: top; 
    background-color: #e5e5e5; 
    height: 50px; 
    //border-radius: 5px; 
  }
  .menu li { display: inline-block; border-right: 1px solid #d5d5d5; position: relative; }
  .menu li ul { max-height: 0; overflow: hidden; display: block; position: absolute; top: 50px; right: 0; z-index: 99; transition: all ease .4s; }
  .menu li span, .menu li a { font-size: 16px; display: inline-block; color: #111; height: 50px; line-height: 50px; padding: 0 20px; text-decoration: none; }
  .menu li span:hover { color: #000; cursor: pointer; }
  .menu li a:hover { color: #5f90ec; cursor: pointer; }
  .menu li span i {
    margin-left: 10px;
    width: 13px;
    height: 50px;
    display: inline-block;
    vertical-align: top;
    background-image: url("/assets/images/arrow-down.png");
    background-repeat: no-repeat;
    background-position: center left;
  }
  .menu li:hover span i { background-position: center right; }

  .menu li:hover ul { max-height: 500px; padding: 5px 0;  }
  .menu li .arrow-up {
    display: none;
    width: 15px;
    height: 9px;
    // background-image: url("../img/dropdown-arrow-up.png");
    position: absolute;
    z-index: 100;
    top: 47px;
    right: 15px;
  }
  .menu li:hover .arrow-up { display: block; }
  .menu li ul li {
    display: block;
    background-color: #e5e5e5;
    border: 1px solid #f0f0ef;
    border-top: none;
    text-align: right;
  }
  .menu li ul li:first-of-type { border-top: 1px solid #f0f0ef; border-top-left-radius: 5px; border-top-right-radius: 5px; }
  .menu li ul li:last-of-type { border-bottom-left-radius: 5px; border-bottom-right-radius: 5px; }
  .menu li ul li a { height: 35px; line-height: 35px; white-space: nowrap; }
}
</style>

