<template>
  <subMenu
    :dashboard="{ to: '/tools/scheduler', text:'Scheduler Dashboard' }"
    :menu="menu" />
</template>

<script>
import SubMenu from '../common/SubMenu'
import Panel from '../common/Panel'

export default {
  name: 'SchedulerMenu',

  components: {
    SubMenu,
    Panel
  },

  props: {
    page: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      default: 'Scheduler Dashboard',
      menu: [
        {
          text: 'Calendar',
          list: [
            {
              text: 'View',
              to: '/tools/scheduler/calendar'
            },
            {
              text: 'Add New Event',
              to: '/tools/scheduler/add-event'
            }
          ]
        },
        {
          text: 'Tasks',
          list: [
            {
              text: 'Task List',
              to: '/tools/scheduler/task-list'
            }
          ]
        }
      ]
    }
  },
}
</script>
