<template>
  <layout>
    <div class="wrapper">
      <panel title="My profile" class="profile">
        <div v-if="data">
          <h3>
            {{ data.firstName }} {{ data.lastName }}
          </h3>
          <span class="item">Account ID: <b>{{ data.uuid }}</b></span>
          <span class="item">Role: <b>{{ data.role | role }}</b></span>
          <span class="item">Email: <b>{{ data.email }}</b></span>
          <span class="item">Job Title: <b>{{ data.job_title || 'N/A' }}</b></span>
          <span class="item">Phone: <b>{{ data.phone }}-{{ data.phoneExt }}</b></span>
          <span class="item">
            Last login:
            <b v-if=" data.lastLogin">{{ data.lastLogin | date }}</b>
            <b v-else>N/A</b>
          </span>
        </div>
        <div
          v-else
          class="placeholder"
          v-loading="loading" />
      </panel>
    </div>
  </layout>
</template>

<script>
import Panel from '../common/Panel'
import Layout from '../common/Layout'

export default {
  name: 'MyProfile',

  components: {
    Panel,
    Layout
  },

  data () {
    return {
      loading: true,
      data: null
    }
  },

  created () {
    this.data = window.appData.currentUser
  }
}
</script>

<style lang="scss" scoped>
  .wrapper {
    width: 100%;
    max-width: 685px;
    margin: 0 auto;
    .profile {
      h3 {
        font-size: 19px;
        font-weight: bold;
        .status {
          font-size: 13px;
          text-transform: uppercase;
          color: #b43434;
          &.active {
            color: #17cc68;
          }
        }
      }
      .item {
        display: block;
        margin: 5px 0;
        &.ac-item {
          display: block;
          text-align: center;
          margin: 15px 0 0 0;
          border: 1px dashed rgba(0, 0, 0, .3);
          padding: 20px;
          border-radius: 5px;
          .button {
            display: block;
            margin-top: 15px;
            text-align: center;
          }
        }
      }
    }
  }

  .placeholder {
    width: 100%;
    height: 300px;
  }
</style>