<template>
  <div>
    <p>Do {{ isSpouse ? 'they' : 'you' }} have a criminal record? <ex-select v-model="form.criminal_record" :options="yesNoOptions" :disabled="disabled" no-style /></p>


    <p>Preferred destination in Canada <ex-select v-model="form.preferred_destination_ca" :options="provinceOptions" :disabled="disabled" no-style />
      <span class="ml-20">Personal Net Worth <ex-input v-model="form.net_worth" @blur="formatMoney()" :disabled="disabled" no-style /></span>
    </p>
    
    <p><b>LANGUAGE SKILLS:</b></p>

    <p>IELTS <ex-select v-model="form.ielts" :options="yesNoOptions" class="small-input" :disabled="disabled" no-style />
      <span class="ml-20">
        Date: <span class="datepicker-wrapper">
          <datepicker v-model="form.ielts_date" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" :open-date="new Date()" :disabled="disabled" class="datepicker-small" />
        </span>
      </span>
      <span class="ml-20">
        TEFAQ/TEF/DALF/SEL <ex-select v-model="form.tefaq_tef_dalf_sel" :options="yesNoOptions" class="small-input" :disabled="disabled" no-style />
      </span>
      <span class="ml-20">
        Date: <span class="datepicker-wrapper">
          <datepicker v-model="form.tefaq_tef_dalf_sel_date" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" :open-date="new Date()" :disabled="disabled" class="datepicker-small" />
        </span>
      </span>
    </p>

    <p>English (1-10)
      <span v-for="(name, key) in lang_skils" :key="key" class="ml-20">
        {{ name }} <ex-input v-model.number="form[`english_${key}`]" type="number" :min="1" :max="10" no-style :disabled="disabled" class="small-input" />
      </span>
    </p>

    <p>French (1-10)
      <span v-for="(name, key) in lang_skils" :key="key" class="ml-20">
        {{ name }} <ex-input v-model.number="form[`french_${key}`]" type="number" :min="1" :max="10" no-style :disabled="disabled" class="small-input" />
      </span>
    </p>

    <p><b>EDUCATION AND TRAINING:</b></p>
    <p>
      <span>Highest diploma <ex-input v-model="form.diploma" :disabled="disabled" no-style /></span>
      <span class="ml-20">Program Duration <ex-input v-model="form.program_duration" :disabled="disabled" no-style /></span>
      <span class="ml-20">Language <ex-input v-model="form.language" :disabled="disabled" no-style /></span>
      <br>Inside Canada <ex-select v-model="form.inside_canada" :options="yesNoOptions" class="small-input" :disabled="disabled" no-style />
    </p>

    <p><b>WORK HISTORY (PAID WORK IN YOUR FIELD)</b></p>
    <p>
      <span>Self-employed <span class="checkbox-group"><input v-model="form.self_employed" type="checkbox" :disabled="disabled"></span></span>
      <span class="ml-20">Payroll <span class="checkbox-group"><input v-model="form.payroll" type="checkbox" :disabled="disabled"></span></span>
      <br><span>Full time <span class="checkbox-group"><input v-model="form.full_time" type="checkbox" :disabled="disabled"></span></span>
      <span class="ml-20">Part time <span class="checkbox-group"><input v-model="form.part_time" type="checkbox" :disabled="disabled"></span></span>
      <span class="ml-20">Less than 15h a week <span class="checkbox-group"><input v-model="form.less_than_15h_week" type="checkbox" :disabled="disabled"></span></span>
      <br><span>Inside Canada <span class="checkbox-group"><input v-model="form.work_inside_canada" type="checkbox" :disabled="disabled"></span></span>
      <span class="ml-20">Outside Canada <span class="checkbox-group"><input v-model="form.work_outside_canada" type="checkbox" :disabled="disabled"></span></span>
    </p>

    <p><b>FAMILY INFORMATION</b></p>

    <p>
      Family in Canada 18+ <ex-select v-model="form.familiy_in_ca" :options="yesNoOptions" :disabled="disabled" no-style />
      <span class="ml-20">Family in Manitoba 18+ <ex-select v-model="form.familiy_in_manitoba" :options="yesNoOptions" :disabled="disabled" no-style /></span>
      <br><span>Children aged less than 18 y/o <ex-select v-model="form.children_aged_less_18" :options="yesNoOptions" :disabled="disabled" no-style /></span>
      <span class="ml-20">Children aged less than 6 y/o <ex-select v-model="form.children_aged_less_6" :options="yesNoOptions" :disabled="disabled" no-style /></span>
      <template v-if="!isSpouse">
        <br><span>Marital status <ex-select v-model="form.marital_status" :options="maritialStatusOptions" :disabled="disabled" no-style /></span>
      </template>
    </p>
  </div>
</template>

<script>
import numeral from 'numeral'

import Datepicker from 'vuejs-datepicker'
import ExInput from '../../../common/Input'
import ExSelect from '../../../common/Select'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    isSpouse: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    client: {
      type: Object,
      required: true
    }
  },

  components: {
    ExInput,
    ExSelect,
    Datepicker
  },

  data () {
    return {
      form: this.value,

      yesNoOptions: [
        {
          id: 'yes',
          text: 'Yes'
        },
        {
          id: 'no',
          text: 'No'
        }
      ],

      lang_skils: {
        writing: 'Writing',
        reading: 'Reading',
        speaking: 'Speaking',
        listening: 'Listening'
      }
    }
  },

  computed: {
    maritialStatusOptions () {
      return window.maritialStatusOptions
    },

    provinceOptions () {
      return window.states.CA
    }
  },

  watch: {
    form: {
      handler (form) {
        this.$emit('input', form)
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    formatMoney (spouse = false) {
      if (!spouse) this.form.net_worth = numeral(this.form.net_worth).format('$0')
      else this.form.spouse.net_worth = numeral(this.form.spouse.net_worth).format('$0')
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox-group {
  margin-left: 10px;
  input {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
}

.small-input {
  width: 80px;
}

.datepicker-wrapper {
  display: inline-block;
  width: 140px;
}
</style>