<template>
  <div class="modal">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        :style="{ width: width + 'px' }"
        @click.stop>
        <div v-if="title" class="modal-header">{{ title }}</div>
        <div class="modal-body">
          <slot />
        </div>
      </div>
    </div>
    <div class="close" @click="$emit('close')">
      <img src="/assets/images/close.png">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 690
    },

    title: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, .7);
  display: flex;
  justify-content: center;
  align-items: center;
  .close {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
  }
  .modal-wrapper {
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    padding: 100px 0;
    .modal-container {
      width: 100%;

      max-width: 98%;
      margin: 0 auto;
      background-color: #fff;
      .modal-header {
        padding: 15px 20px;
        background-color: #000;
        text-align: center;
        font-size: 22px;
        color: #fff;
        font-family: "Roboto", sans-serif;
        font-weight: 500;
        margin: 0;
      }
      .modal-body {
        padding: 15px 20px;
      }
    }
  }
}
</style>