import Vue from 'vue'
import moment from 'moment'

Vue.filter('date', (value, format = 'LLL') => {
  const f = format === 'PD' ? 'dddd, MMMM D YYYY hh:mm:ss A' : format
  return moment(value).format(f)
}) 

Vue.filter('program', (value) => window.programs[value])
Vue.filter('programCategory', (value) => window.programCategories[value])

Vue.filter('state', (value) => {
  const states = window.states.CA.concat(window.states.US)
  return states[value]
})

Vue.filter('maritialStatus', (id) => {
  return window.maritialStatusOptions.find(item => item.id === id).text
})

Vue.filter('country', (value) => window.countries[value])

Vue.filter('role', (value) => window.roles[value])

Vue.filter('service', (value) => window.services[value])

Vue.filter('projectStatus', (value) => window.projectStatuses[value])

Vue.filter('uppercase', (value) => value.toUpperCase())

Vue.filter('capitalize', (value) => {
  if (typeof value !== 'string') return ''
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
})