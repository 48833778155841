<template>
  <drawer
    :value="true"
    title="Filters"
    destroy-on-close
    @closed="$emit('close')">

    <div
      v-for="field in map"
      :key="field.key"
      class="form-item">
      <ex-select
        v-if="field.type === 'select'"
        v-model="form[field.key]"
        v-bind="field.props || {}"
        :label="field.label"
        :options="field.options"
        full />

      <ex-input
        v-if="field.type === 'input'"
        v-model="form[field.key]"
        v-bind="field.props || {}"
        :label="field.label"
        full />

      <div v-if="field.type === 'datepicker'">
        <label class="mt-20">{{ field.label }}</label>
        <datepicker
          v-model="form[field.key]"
          v-bind="field.props || {}"
          calendar-button
          calendar-button-icon="el-icon-date"
          :format="customDateFormatter"
        />
      </div>

      <div
        v-if="field.type === 'range_datepicker'"
        class="range-datepicker">
        <label class="mt-20">{{ field.label }}</label>
        <div class="space-between">
          <datepicker
            v-model="form[field.key].from"
            v-bind="field.fromProps || {}"
            calendar-button
            calendar-button-icon="el-icon-date"
            :format="customDateFormatter"            
          />
          <span class="block mr-5 ml-5"> - </span>
          <datepicker
            v-model="form[field.key].to"
            v-bind="field.toProps || {}"
            calendar-button
            calendar-button-icon="el-icon-date"
            :format="customDateFormatter"
          />
        </div>
      </div>
    </div>

    <template #footer>
      <el-button
        class="button mr-5"
        @click="$emit('close')">
        Cancel
      </el-button>
      <el-button
        class="button"
        type="primary"
        @click="filter()">
        Filter
      </el-button>
    </template>
  </drawer>
</template>

<script>
import moment from 'moment'

import Drawer from '../common/Drawer'
import ExInput from '../common/Input'
import ExSelect from '../common/Select'
import Datepicker from 'vuejs-datepicker'

export default {
  components: {
    Drawer,
    ExInput,
    ExSelect,
    Datepicker
  },

  props: {
    filters: {
      type: Object,
      default: null
    },

    map: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      form: {}
    }
  },

  computed: {},

  created () {
    this.setup()
  },

  methods: {
    setup () {
      const form = {}
      this.map.forEach(field => {
        const val = this.filters?.[field.key]
        form[field.key] = val ? typeof val === 'object' ? JSON.parse(JSON.stringify(val)) : val : this.getFilterInitialValue(field)
      })
      this.form = form
    },

    clear () {
      const form = {}
      this.map.forEach(field => {
        form[field.key] = null
      })
      this.form = form
    },

    getFilterInitialValue (field) {
      if (field.type === "range_datepicker") {
        return {
          from: null,
          to: null
        }
      }

      return null
    },

    filter () {
      this.$emit('apply', JSON.parse(JSON.stringify(this.form)))
    },

    customDateFormatter (date) {
      return moment(date).format('YYYY-MM-DD');
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-item {
    display: block;
    margin-bottom: 15px;
    .range-datepicker {
      padding: 0 20px;
    }
  }
</style>