<template>
  <modal
    title="Share"
    :width="700"
    @close="$emit('close')">
    <div
      v-if="useLink"
      class="mb-20">
      <p v-if="link && linkExpired" class="text-danger">Link expired.</p>
      <el-button
        v-if="!link || linkExpired"
        size="small"
        type="primary"
        :loading="buttonLoader"
        @click="generate()">
        Generate New Link
      </el-button>
    </div>
    <b>Choose Share options</b>
    <p>
      <input v-model="sendto" type="radio" :disabled="useLink && (!link || linkExpired)" id="one" value="client_email">
      <label for="one">Send email to {{ client.email }}</label>
    </p>
    <p>
      <input v-model="sendto" type="radio" :disabled="useLink && (!link || linkExpired)" id="two" value="custom_email">
      <label for="two">Send email to <ex-input v-model="email" type="email" /></label>
    </p>
    <p v-if="useLink">
      <input v-model="sendto" type="radio" :disabled="!link || linkExpired" id="three" value="link">
      <label for="three">Direct link: {{ link }}</label>
    </p>

    <div class="flex-center mt-30">
      <el-button
        v-if="sendto"
        size="small"
        type="primary"
        :disabled="$v.$invalid"
        :loading="buttonLoader"
        @click="linkSend()">
        {{ sendto === 'link' ? 'Copy Link' : 'Send' }}
      </el-button>
      <el-button size="small" @click="$emit('close')">Close</el-button>
    </div>
  </modal>
</template>

<script>
import { mapActions } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'

import ExInput from '../../../common/Input'

export default {
  components: {
    ExInput
  },

  props: {
    data: {
      type: Object,
      required: true
    },

    action: {
      type: Function,
      required: true
    },

    link: {
      type: String,
      default: null
    },

    useLink: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      sendto: null,
      email: null,
      buttonLoader: false
    }
  },

  computed: {

    linkExpired () {
      return this.data.linkExpires ? moment() > moment(this.data.linkExpires) : false
    },

    client () {
      return this.$store.state.client.single.registration
    }
  },

  methods: {
    linkSend () {
      if (this.sendto === 'link') return this.copy()
      this.send()
    },

    async send () {
      const email = this.sendto === 'client_email' ? this.client.email : this.email
      try {
        await this.$confirm(`Email will be sent to ${email}`)
        this.buttonLoader = true
        try {
          const data = await this.action({ id: this.data.id, action: 'ShareLinkViaEmail', payload: email })
          this.$emit('update', data)
          this.$emit('close')
          this.$message.success(`Email sent to ${email}.`)
        } catch {
          this.$message.error('There was an error. Please try again.')
        } finally {
          this.buttonLoader = false
        }
      } catch {}
    },

    async generate () {
      this.buttonLoader = true
      try {
        const data = await this.action({ id: this.data.id, action: 'GenerateLink' })
          this.$emit('update', data)
        this.$message.success('Link generated successfully.')
      } catch {
        this.$message.error('There was an error. Please try again.')
      } finally {
        this.buttonLoader = false
      }
    },

    copy () {
      const el = document.createElement('textarea');
      el.value = this.link;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$message.success('Link copied to clipboard.')
      this.$emit('close')
    }
  },

  validations () {
    const rules = {}
    if (this.sendto === 'custom_email') {
      rules.email = { required, email }
    }
    return rules
  }
}
</script>

<style lang="scss" scoped>
p {
  margin: 10px 0;
}
</style>