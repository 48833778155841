export default {
  registration: {
    language: 'Idioma',
    client_registration: 'Registro de clientes',
    client_general_information: 'Información general del cliente',
    first_name: 'Nombre',
    enter_first_name: 'Ingrese el nombre',
    date_of_birth: 'Fecha de nacimiento',
    date_dd: 'DD',
    date_mm: 'MM',
    date_yy: 'AA',
    country_of_nationality: 'País de nacionalidad',
    select_country_of_nationality: 'Seleccionar el país',
    last_name: 'Apellido',
    enter_last_name: 'Ingrese el apellido',
    email_address: 'Correo electrónico',
    enter_email_address: 'Ingrese el correo electrónico',
    country_of_residence: 'País de residencia',
    select_country_of_residence: 'Seleccionar el país',
    client_address_information: 'Información sobre el domicilio del cliente',
    address: 'Dirección',
    enter_address: 'Ingrese la dirección',
    state_province: 'Estado/Provincia',
    select_state_province: 'Seleccione estado/provincia',
    phone_number: 'Número de teléfono',
    enter_phone_number: 'Ingrese el número de teléfono',
    city: 'Ciudad',
    enter_city: 'Ingrese el código postal ',
    postal_code: 'Código postal',
    enter_postal_code: 'Ingrese el código postal',
    other_phone_number: 'Ingrese otro número de teléfono',
    client_service_requested: 'Servicio solicitado',
    service: 'Servicio ',
    select_service: 'Seleccionar servicio',
    do_you_agree_to_our: '¿Está de acuerdo con nuestros?',
    termes_conditions: 'Términos y condiciones',
    privacy: 'Confidencial',
    submit_registration: 'Registrarse',
    user_policy: 'Política del usuario',
    already_a_user_login_to_get_started: '¿Ya eres usuario? Inicia sesión para comenzar',
    trouble_logging_in_please_contact: '¿Problemas de conexión? Contacte:',
    use_of_this_site_is_restricted_for_the_use_of_its_immigration_clients_associates: 'El uso de este sitio está restringido para el uso de Clientes y asociados de  ITS Immigration'
  },

  services: {
    consultation: 'Consulta',
    permanent_residence: 'Residencia permanente',
    temporary_residence: 'Residencia temporal',
    sponsorship: 'Apadrinamiento',
    citizenship_application: 'Solicitud de ciudadanía',
    document_replacement_extension: 'Remplazo y renovación de documentos',
    travel_documents: 'Documentos de viaje',
    legal_advic: 'Consejo Legal'
  }
}
