<template>
  <div>
    <div class="buttons">
      <el-button v-if="data.userId && !edit" size="mini" icon="el-icon-edit" type="primary" @click="startEdit()">Edit</el-button>
      <template v-if="edit">
        <el-button size="mini" icon="el-icon-edit" type="success" @click="save()">Save</el-button>
        <el-button size="mini" icon="el-icon-edit" @click="edit = false">Cancel</el-button>
      </template>
      <a :href="`/client/general/${data.id}/pdf`" target="_blank">
        <el-button size="mini" icon="el-icon-printer" type="secondary">Print</el-button>
      </a>
    </div>

    <div class="info-box">
      <div class="col">
        <span>Client ID</span>
        <b>{{ data.userId ? data.userId.uuid : 'N/A' }}</b>
      </div>
      <div class="col">
        <span>Account ID</span>
        <b>{{ data.userId ? data.userId.accountId : 'N/A' }}</b>
      </div>
      <div class="col">
        <span>Setup Date</span>
        <b v-if="data.userId && data.userId.created">{{ data.userId.created | date }}</b>
        <b v-else>N/A</b>
      </div>
      <div class="col">
        <span>Activation Date</span>
        <b v-if="data.userId && data.userId.activationDate">{{ data.userId.activationDate | date }}</b>
        <b v-else>N/A</b>
      </div>
    </div>

    <div class="info-box">
      <div class="col">
        <span>Account Manager</span>
        <template v-if="edit">
          <ex-select v-model="form.accountManager" :validator="$v.form.accountManager" :options="salesUsers" class="m-0" full />
        </template>
        <b v-else>
          <template v-if="data.userId && clientAssign">
            {{ clientAssign.accountManager.firstName }} {{ clientAssign.accountManager.lastName }}
          </template>
          <template v-else>N/A</template>
        </b>
      </div>
      <div class="col">
        <span>Client Services Rep</span>
        <template v-if="edit">
          <ex-select v-model="form.clientServicesRep" :validator="$v.form.clientServicesRep" :options="clientUsers" class="m-0" full />
        </template>
        <b v-else>
          <template v-if="data.userId && clientAssign">
            {{ clientAssign.clientServicesRep.firstName }} {{ clientAssign.clientServicesRep.lastName }}
          </template>
          <template v-else>N/A</template>
        </b>
      </div>
    </div>
  </div>
</template>

<script>
import ExInput from '../common/Input'
import ExSelect from '../common/Select'

import { phone } from '../common/validators'
import { required, numeric, email, alpha } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    ExInput,
    ExSelect
  },

  data () {
    return {
      edit: false,

      form: {
        accountManager: null,
        clientServicesRep: null
      },
    }
  },

  computed: {
    ...mapGetters({
      getUsersByType: 'users/getUsersByType'
    }),

    salesUsers () {
      return this.getUsersByType('staff')
    },

    clientUsers () {
      return this.getUsersByType('client')
    },

    data () {
      return this.$store.state.client.single.registration
    },

    clientAssign () {
      return this.data.ClientAssign
    }
  },

  methods: {
    ...mapActions({
      update: 'client/single/update'
    }),

    print () {

    },

    async save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
        //   this.update({ id: this.data.id, payload: this.form })
        //   this.$message.success('Successfully edited Client.')
          this.edit = false
        } catch {
          this.$message.error('There was an error... Please try again.')
        }
      }
    },

    startEdit () {
      this.form = {
        accountManager: this.clientAssign.accountManager.id,
        clientServicesRep: this.clientAssign.clientServicesRep.id
      }
      this.edit = true
    }
  },
  
  validations: {
    form: {
      accountManager: { required },
      clientServicesRep: { required }
    }
  }
}
</script>

<style lang="scss" scoped>
  .buttons {
    margin-bottom: 20px;
  }
  .m-0 {
    margin: 0;
  }
</style>