<template>
  <div>
    <div class="buttons">
      <el-button
        size="mini"
        icon="el-icon-plus"
        type="primary"
        :loading="addingNew"
        @click="create()">
        New
      </el-button>
    </div>

    <div class="table-wrapper">
      <div class="info-box">
        <h3>Profile Update</h3>
      </div>
      <el-table
        v-loading="loading"
        :data="data"
        style="min-width: 100%"
        stripe>
        <el-table-column label="ID" width="90">
          <template slot-scope="scope">
            {{ scope.row ? scope.row.customId : 'N/A' }}
          </template>
        </el-table-column>

        <el-table-column label="Status" width="90">
          <template slot-scope="scope">
            <div class="status">
              <i :class="statusIcon(scope.row)" />
            </div>
          </template>
        </el-table-column>

        <el-table-column label="Filename">
          <template slot-scope="scope">
            <a v-if="isSigned(scope.row)" :href="signedFileUrl(scope.row)" target="_blank">{{ scope.row.signedFile.file }}</a>
            <template v-else-if="scope.row && scope.row.file">{{ scope.row.file.file }}</template>
            <template v-else>N/A</template>
          </template>
        </el-table-column>

        <el-table-column label="Name">
          <template slot-scope="scope">
            <template v-if="!scope.row">N/A</template>
            <template v-else>{{ scope.row.name }}</template>
          </template>
        </el-table-column>

        <el-table-column label="Created By">
          <template slot-scope="scope">
            <template v-if="!scope.row">N/A</template>
            <template v-else>{{ scope.row.createdBy.name }}</template>
          </template>
        </el-table-column>

        <el-table-column label="Created At">
          <template slot-scope="scope">
            <template v-if="!scope.row">N/A</template>
            <template v-else>{{ scope.row.createdAt | date }}</template>
          </template>
        </el-table-column>

        <el-table-column label="Signed At">
          <template slot-scope="scope">
            <template v-if="!isSigned(scope.row)">N/A</template>
            <template v-else>
              {{ scope.row.signedAt | date }}
            </template>
          </template>
        </el-table-column>

        <el-table-column label="Actions" align="right">
          <template slot-scope="scope">
            <el-button v-if="!isSubmitted(scope.row)" size="mini" @click="openShareModal(scope.row)">Share</el-button>
            <a v-if="isAccepted(scope.row) || isSubmitted(scope.row)" :href="`/profile-update/${scope.row.id}/pdf`" target="_blank">
              <el-button size="mini" type="primary" @click="viewPdf(scope.row)">{{ isAccepted(scope.row) ? 'View' : 'Review' }}</el-button>
            </a>
            <el-button v-else size="mini" type="primary" @click="start(scope.row)">Start</el-button>
            <el-button size="mini" type="danger" @click="cancel(scope.row.id)">Cancel</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <modal
      v-if="openForm"
      :width="1360"
      @close="close()">
      <profile-update-form
        v-model="form"
        :readonly="!editMode" />

      <div class="mt-20 text-center">
        <template v-if="!isSubmitted(openForm)">
          <el-button size="small" type="primary" :loading="buttonLoader" @click="save()">Save</el-button>
          <el-button size="small" type="success" :loading="buttonLoader" @click="submit()">Submit</el-button>
        </template>
        <template v-else-if="!isAccepted(openForm)">
          <template v-if="!editMode">
            <el-button size="small" type="primary" @click="editMode = true">Edit</el-button>
            <el-button size="small" type="success" @click="accept()">Accept</el-button>
          </template>
          <el-button v-else size="small" type="primary" :loading="buttonLoader" @click="editSave()">Save</el-button>
        </template>
        <el-button size="small" @click="close()">Close</el-button>
      </div>
    </modal>

    <share
      v-if="shareModal"
      :data="shareModalData"
      :action="action"
      :link="link"
      @update="updateStore($event)"
      @close="shareModal = false" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ProfileUpdateForm from './ProfileUpdateForm'
import Share from './setup-checklist/evaluation/Share'

export default {
  components: {
    Share,
    ProfileUpdateForm
  },

  data () {
    return {
      addingNew: false,
      form: null,
      openForm: false,

      shareModal: false,
      shareModalData: null,

      editMode: false,
      buttonLoader: false,
      loading: false
    }
  },

  computed: {
    link () {
      if (!this.shareModalData) return null
      const url = window.appData.apiRoot.replace('/api', '/profile-update-form/')
      return this.shareModalData.link ? url + this.shareModalData.link : null
    },

    client () {
      return this.$store.state.client.single.registration
    },

    data () {
      return this.$store.state.client.profileUpdates.data
    }
  },

  async created () {
    if (!this.data) {
      this.loading = true
      await this.$store.dispatch('client/profileUpdates/load', {
        params: {
          client: this.client.id
        }
      })
      this.loading = false
    }
  },

  methods: {
    ...mapActions({
      action: 'client/profileUpdates/action'
    }),

    openShareModal (data) {
      this.shareModal = true
      this.shareModalData = data
    },

    async create () {
      this.addingNew = true
      try {
        const payload = {
          client: this.client.id,
          createdAt: 'CURRENT_TIMESTAMP',
          customId: 'PROFILE_UDATE_CUSTOM_ID',
          createdBy: window.appData.currentUser.id
        }
        const data = await this.$store.dispatch('client/profileUpdates/create', payload)
      } catch {} finally {
        this.addingNew = false
      }
    },

    start (data) {
      this.openForm = data
      this.form = data.data || null
      this.editMode = !this.isSubmitted(data)
    },

    async cancel (id) {
      try {
        await this.$confirm('Are you sure you want to cancel a Profile Update?')
        try {
          this.loading = true
          await  this.$store.dispatch('client/profileUpdates/delete', id)
          this.$message.success('Profile Update cancelled successfully.')
        } catch {} finally {
          this.loading = false
        }
      } catch {}
    },
    
    async accept () {
      try {
        await this.$confirm('Are you sure you want to accept?')
        const payload = { accepted: 'CURRENT_TIMESTAMP' }
        this.update(payload)
      } catch {}
    },

    submit () {
      const payload = {
        data: this.form,
        submitted: 'CURRENT_TIMESTAMP'
      }
      this.update(payload)
    },

    async save () {
      this.update({ data: this.form })
    },

    async update (payload, openForm = false) {
      this.buttonLoader = true
      try {
        await this.$store.dispatch('client/profileUpdates/update', { id: this.openForm.id, payload })
        this.$message.success('Profile Update updated successfully.')
        this.openForm = openForm
      } catch {} finally {
        this.buttonLoader = false
      }
    },

    editSave () {
      const payload = { data: this.form }
      this.update(payload, this.openForm)
      this.editMode = false
    },

    close () {
      this.openForm = null
      this.form = null
    },

    viewPdf (data) {
      console.log('view')
    },

    statusIcon (data) {
      if (this.isAccepted(data)) return 'el-icon-s-claim success'
      return this.isSubmitted(data) ? 'el-icon-s-claim warning' : 'el-icon-s-release'
    },

    isSigned (data) {
      return !!data?.signedAt && !!data?.signedFile
    },

    isSubmitted (data) {
      return data?.submitted
    },

    isAccepted (data) {
      return data?.accepted
    },

    updateStore (data) {
      console.log('update')
      if (this.shareModalData) this.shareModalData = data
      // this.$store.commit('client/single/setData', { key: 'ClientEvaluation', data })
      // this.$store.commit('client/single/setData', { key: 'evaluation', data })
    }
  }
}
</script>

<style lang="scss" scoped>
.table-wrapper {
  margin-top: 20px;
}
.status {
  font-size: 30px;
  margin-right: 10px;
  .success {
    color: #67c23a;
  }
  .warning {
    color: orange;
  }
}
</style>
