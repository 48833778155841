<template>
  <div class="page-form">
    <p class="text-center">Documents</p>
    
    <p><b>PERSONAL INFORMATION</b></p>

    <p>{{ client.firstName }} - {{ client.lastName }} - {{ age }} - {{ client.nationality | country }} - {{ client.residence | country }}</p>

    <p>Do you have a criminal record? <span class="answer">{{ formatYN(value.criminal_record) }}</span></p>

    <p><b>MAIN APPLICANT</b></p>

    <p>What is your current status in Canada? <span class="answer">{{ formatCurrentStatus(value.current_status) }}</span></p>

    <p>What service is requested? <span class="answer">{{ formatRequestedService(value.requested_service) }}</span></p>

    <p>Document Type <span class="answer">{{ formatTravelDocuments(value.document_type) }}</span></p>

    <p><b>ADDITIONAL INFORMATION:</b></p>

    <span class="answer">{{ value.additional_info }}</span>

  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    client: {
      type: Object,
      required: true
    }
  },

  computed: {

    age () {
      return moment().diff(moment(this.client.dob), 'years')
    }
  },

  methods: {
    formatYN (val) {
      return val === 'yes' ? 'Yes' : 'No'
    },

    formatCurrentStatus (status) {
      const currentStatus = window.currentStatus
      return currentStatus.find( item => item.id === status).text
    },

    formatRequestedService (status) {
      const requestedService = window.requestedService
      return requestedService.find( item => item.id === status).text
    },

    formatDocumentType (status) {
      const documentType = window.documentType
      return documentType.find( item => item.id === status).text
    },

    formatTravelDocuments (status) {
      const documentAndTravel = [ ...window.travelDocuments, ...window.documentType ]
      return documentAndTravel.find( item => item.id === status).text
    },
  }
  
}
</script>
