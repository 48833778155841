<template>
  <form-item :label="label" :validator="validator" :class="{ full }" :no-style="noStyle">
    <input
      :class="classes"
      v-model="val"
      :type="type"
      :placeholder="placeholder || `Enter ${label || 'text'}`"
      :maxlength="maxlength"
      :min="min"
      :max="max"
      :disabled="disabled"
      @blur="$emit('blur')">
  </form-item>
</template>

<script>
import inputMixin from './input-mixin.js'

export default {
  mixins: [inputMixin],

  props: {
    type: {
      type: String,
      default: 'text'
    },

    placeholder: {
      type: String,
      default: null
    },

    full: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    noStyle: {
      type: Boolean,
      default: false
    },

    maxlength: {
      type: Number,
      default: null
    },

    min: {
      type: [Number, String ],
      default: null
    },

    max: {
      type: [Number, String ],
      default: null
    },

    classes: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
  .full {
    width: 100%;
  }
</style>