<template>
  <layout active="/scheduler/calendar">
    <div>
      <schedulerMenu :page="currentPage" />

      <panel :title="currentPage.text">
        <div class="scheduler-panel">
          <div class="panel-title">
            <div class="panel-action">
              <calendar-date-select
                :month.sync="month"
                :year.sync="year" />
            </div>
            <div class="panel-date">
              <ch-button color="gray" :disabled="!selected" @click="viewSelectedDate()">View selected day</ch-button>
              <ch-button @click="openAddEvent = true">Add new Event</ch-button>
            </div>
          </div>
          
          <div class="panel-body-scheduler">
            <calendar ref="calendar" :type="type" :month="month" :year="year" @selected="selected = $event" />
          </div>
        </div>
      </panel>

      <manage-event-modal
        v-model="openAddEvent"
        :type="type"
        :preselectedDate="selected && selected.isInFuture ? selected.date : null"
        @save="addedEvent($event)" />
    </div>
  </layout>
</template>

<script>
import SchedulerMenu from './SchedulerMenu'
import ChButton from '../common/ChButton'
import CalendarDateSelect from '../calendar/CalendarDateSelect'
import Calendar from '../calendar/Calendar'
import ManageEventModal from '../calendar/ManageEventModal'
import Layout from '../common/Layout'

export default {
  name: 'SchedulerCalendar',

  components: {
    SchedulerMenu,
    ChButton,
    CalendarDateSelect,
    Calendar,
    ManageEventModal,
    Layout
  }, 

  data () {
    return {
      type: 'SCHEDULER',
      currentPage: {
        text: 'Calendar'
      },
      openAddEvent: false,
      selected: null,
      month: (new Date()).getMonth(),
      year: (new Date()).getFullYear()
    }
  },

  watch: {
    year () {
      this.resetSelected()
    },

    month () {
      this.resetSelected()
    }
  },

  methods: {
    viewSelectedDate () {
     if (this.selected) {
       let date = moment(this.selected.date).format('YYYY-MM-DD')
       location.href = `/tools/scheduler/calendar/date-view/${date}`
     }
    },

    resetSelected () {
      this.$refs.calendar.selected = null
    },

    addedEvent (event) {
      this.openAddEvent = false
      this.$refs.calendar.events.push(event)
    }
  }
}
</script>
