<template>
  <div>
    <div v-if="!isSubmitted && !expired" class="page">
      <profile-update-form
        v-if="!loading"
        v-model="form"
        :guestClient="data.client"
        :readonly="false" />

      <div class="flex-center mt-30">
        <el-button type="primary" :loading="buttonLoader" @click="update()">Save</el-button>
        <el-button v-if="!isSubmitted" type="success" :loading="buttonLoader" @click="submit()">Submit</el-button>
      </div>
    </div>
    <div class="form-submitted" v-else>
      <h1>{{ message }}</h1>
      <p v-if="justSubmitted">Thank you for submitting the form.</p>
      <a href="/">
        <el-button type="primary" class="button">Back to homepage</el-button>
      </a>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'GuestProfileUpdateForm',

  props: {
    link: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      type: 'ProfileUpdate',
      form: null,
      buttonLoader: false,
      data: null,
      justSubmitted: false,
      loading: true,
      expired: false
    }
  },

  computed: {
    message () {
      if (this.expired) return 'This link has expired.'
      return this.justSubmitted ? 'Form submitted successfully' : 'Form has already been submitted.'
    },

    client () {
      return this.data ? this.data.client : null
    },

    isSubmitted () {
      return this.data?.submitted
    }
  },

  created () {
    this.load()
  },

  methods: {
    async load () {
      const response = await axios.get(`profile-update/${this.link}`)
      this.data = response.data
      if (response.data.expired) {
        return this.expired = true
      }
      this.form = response.data.data
      this.loading = false
    },

    async submit () {
      try {
        await this.$confirm('Are you sure you want to submit a form?')
        this.update(true)
      } catch {}
    },

    async update (submitted = false) {
      try {
        const payload = { data: this.form }
        if (submitted) payload.submitted = 'CURRENT_TIMESTAMP'
        const response = await axios.post(`profile-update/${this.link}`, payload)
        this.data = response.data
        if (submitted) this.justSubmitted = true
        else this.$message.success('Profile Update form saved successfully.')
      } catch {}
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  margin: 0 auto;
  width: 100%;
  max-width: 1024px;
}
.form-submitted {
  margin: 100px 0;
  text-align: center;
  h1 {
    font-size: 30px;
  }
  p {
    font-size: 20px;
    margin-top: 15px;
  }
  .button {
    margin-top: 40px;
  }
}
</style>