<template>
  <div>
    <div class="table-wrapper">
      <div class="info-box">
        <h3>Client Consultation Agreement</h3>
      </div>
      <el-table :data="[1]" v-loading="loading" style="min-width: 100%" stripe>
        <el-table-column label="Status" width="90">
          <div class="status">
            <i :class="{
                'el-icon-s-claim success': agreement,
                'el-icon-s-release': !agreement,
                warning: !signed && agreement
              }" />
          </div>
        </el-table-column>

        <el-table-column label="Filename">
          <a v-if="signed" :href="signedFileUrl" target="_blank">{{ agreement.signedFile.file }}</a>
          <template v-else-if="agreement && agreement.file">{{ agreement.file.file }}</template>
          <template v-else>N/A</template>
        </el-table-column>

        <el-table-column label="Created At">
          <template v-if="agreement">{{ agreement.createdAt | date('ll') }}</template>
          <template v-else>N/A</template>
        </el-table-column>

        <el-table-column label="Upload Signed At">
          <template v-if="!signed">N/A</template>
          <template v-else>
            {{ agreement.signedAt | date }}
          </template>
        </el-table-column>

        <el-table-column label="Consultant Fee">
          <template v-if="!agreement">
            <ex-input v-model="fee" no-style @blur="formatCurrency()" style="width: 100px;" />
            <el-button :type="confirmed ? 'info' : 'success'" size="mini" :disabled="confirmed" icon="el-icon-check" @click="confirmed = true" />
          </template>
          <template v-else>{{ agreement.fee }}</template>
        </el-table-column>

        <el-table-column label="Actions" align="right" width="200">
          <el-button v-if="!agreement" :loading="loading" :disabled="!confirmed" size="mini" type="primary" @click="create()">Create</el-button>
          <template v-else>
            <el-button size="mini" type="danger" @click="remove()">{{ !signed ? 'Remove' : 'Remove Signed' }}</el-button>
            <a :href="viewUrl" target="_blank">
              <el-button size="mini" icon="el-icon-document">View</el-button>
            </a>
            <el-button v-if="!signed && agreement" size="mini" icon="el-icon-s-promotion" @click="shareModal = true">Share</el-button>
            <el-button v-if="!signed" size="mini" icon="el-icon-edit" @click="$refs.upload.click()">Upload signed</el-button>
            <input v-show="false" ref="upload" type="file" @change="uploadFile" />
          </template>
        </el-table-column>
      </el-table>
    </div>

    <share
      v-if="shareModal"
      :data="agreement"
      :action="action"
      :use-link="false"
      @close="shareModal = false" />
  </div>
</template>

<script>
import numeral from 'numeral'
import { mapActions } from 'vuex'
import helperMixin from '../../common/helpers-mixin'

import ExInput from '../../common/Input'
import Share from '../setup-checklist/evaluation/Share'

export default {
  name: 'ClientConsultationAgreement',

  mixins: [ helperMixin ],

  components: {
    Share,
    ExInput
  },

  data () {
    return {
      type: 'ClientConsultationAgreement',
      fee: '$200.00',
      confirmed: false,
      loading: false,
      shareModal: false
    }
  },

  computed: {
    agreement () {
      return this.registration[this.type]
    },

    registration () {
      return this.$store.state.client.single.registration
    },

    signedFileUrl () {
      if (!this.agreement || !this.agreement.signedFile) return null
      return `/download/ClientDocument/${this.agreement.signedFile.id}`
    },

    signed () {
      return this.agreement && this.agreement.signedAt && this.agreement.signedFile
    },

    viewUrl () {
      if (!this.agreement || !this.agreement.file) return null
      return `/download/ClientDocument/${this.agreement.file.id}`
    }
  },

  watch: {
    fee () {
      this.confirmed = false
    }
  },

  methods: {
    ...mapActions({
      action: 'client/consultations/action',
      createChecklist: 'client/single/createChecklist',
      deleteChecklist: 'client/single/deleteChecklist',
      generatePdf: 'client/single/generateCCAgreementPdf',
      updateChecklist: 'client/single/updateChecklist',
      createDocument: 'client/single/createDocument',
      uploadDocument: 'client/single/uploadDocument',
      loadChecklist: 'client/single/loadChecklist'
    }),

    async remove () {
      if (this.signed) {
        const payload = {
          signedAt: null,
          signedFile: null
        }
        await this.updateChecklist({ id: this.agreement.id, type: this.type, payload })
      } else {
        await this.deleteChecklist({ type: this.type, id: this.agreement.id })
      }
    },

    async create () {
      this.loading = true
      const payload = {
        client: this.registration.id,
        fee: this.fee,
        createdAt: 'CURRENT_TIMESTAMP',
        createdBy: window.appData.currentUser.id,
        signedAt: null,
        signedFile: null
      }
      const id = (await this.createChecklist({ type: this.type, payload })).id
      await this.generatePdf(id)
      await this.loadChecklist({ type: this.type, id: this.registration.id, force: true })
      this.loading = false
    },

    async uploadFile ($e) {
      let file = $e.target.files[0]
      if (file) {
        this.loading = true
        file = this.changeFileName(file, `${this.registration.firstName} ${this.registration.lastName} - Client Consultation Agreement Signed`)
        const document = await this.createDocument({
          type: 'Client Consultation Agreement',
          clientRegId: this.registration.id,
          createdAt: 'CURRENT_TIMESTAMP'
        })
        await this.uploadDocument({ id: document.id, file })
        await this.updateChecklist({
          id: this.agreement.id,
          type: this.type,
          payload: {
            signedAt: 'CURRENT_TIMESTAMP',
            signedFile: document.id,
          }
        })
        this.loading = false
      }
    },

    formatCurrency () {
      this.fee = numeral(this.fee).format('$0.00')
    }
  }
}
</script>

<style lang="scss" scoped>
.table-wrapper {
  margin-top: 20px;
}
.status {
  font-size: 30px;
  margin-right: 10px;
  .success {
    color: #67c23a;
  }
  .warning {
    color: orange;
  }
}
</style>