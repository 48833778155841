<template>
  <header>
    <header-system-messages />

    <div
      v-if="user"
      class="account">
      <div class="r-text">
        <h2>Welcome back, <a :href="`/profile`">{{ user.firstName }}</a></h2>
        <h4>Account ID: <a :href="`/profile`">{{ user.uuid }}</a></h4>
      </div>

      <div
        class="dropdown-icon"
        :class="{ opened }"
        @click="opened = !opened" />

      <ul
        v-show="opened"
        class="dropdown">
        <li>{{ user.firstName }} {{ user.lastName }}</li> 
        <li>{{ isEmployee ? system.dbaName : company }}</li>
        <li>{{ user.uuid }}</li>        
        <li class="line" />        
        <li>
          <a :href="`/profile`">
            <b>My Profile</b>
          </a>
        </li>        
        <li class="line" />
        <li>
          <a @click="logout()">
            <b>Logout</b>
          </a>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import HeaderSystemMessages from './header/HeaderSystemMessages'

export default {
  components: {
    HeaderSystemMessages
  },

  data () {
    return {
      opened: false,
      roles: [],
      client: null
    }
  },

  computed: {
    company () {
      return this.client ? this.client.company : ''
    },

    user () {
      return window.appData.currentUser
    },

    system () {
      return window.appData.company
    },

    isEmployee () {
      return this.roles.filter(role => role.user_group === 'staff').map(role => role.name).includes(this.user.role)
    }
  },

  watch: {},

  async created () {
    this.roles = (await api.list('Role')).items
    const clients = (await api.list('Client', { userId: this.user.id })).items
    if (clients.length) this.client = clients[0]
  },

  methods: {
    async logout () {
      this.opened = false
      const response = await api.request('POST', '/logout', {})
      location.href = "/login"
    } 
  }
}
</script>

<style lang="scss" scoped>
header {
  min-height: 95px;
  width: 100%;
  background-color: #e7e7e7;
  display: flex;
  justify-content: flex-end;
  .account {
    background-color: #dcdcdc;
    width: 100%;
    max-width: 235px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    h2 {
      font-size: 14px;
      font-weight: 700;
      color: #787878;
      margin-bottom: 4px;
    }
    h4 {
      font-size: 11px;
      font-weight: 700;
      color: #787878;
    }
    .dropdown-icon {
      margin-left: 20px;
      cursor: pointer;
      width: 28px;
      height: 28px;
      background-image: url(/assets/images/dropdown.png);
      background-position: left;
      &.opened {
        background-position: right;
      }
    }
    .dropdown {
      position: absolute;
      top: 95px;
      right: 0;
      background-color: #dcdcdc;
      width: 235px;
      display: flex;
      flex-direction:  column;
      align-items:  flex-start;
      justify-content:  center;
      transition: all ease-in-out 0.2s;
      padding: 10px 30px;
      z-index:99;
      li {
        font-size: 14px;
        color:#000;
        font-weight: 400;
        height: 25px;
        line-height: 25px;
        cursor: pointer;
        b {
          font-weight:700;
        }
        &.line {
          width: 170px;
          height: 1px;
          background-color: rgba(0, 0, 0, .2);
          margin: 10px 0;
        }
      }      
    }
  }
}
</style>