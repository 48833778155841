import notes from './notes'
import single from './single'
import invoices from './invoices'
import fileSetup from './fileSetup'
import myAccount from './myAccount'
import evaluation from './evaluation'
import statements from './statements'
import consultations from './consultations'
import subscriptions from './subscriptions'
import profileUpdates from './profileUpdates'

const state = {
  registrations: null
}

const actions = {
  async list ({ state, commit }, { params = {}, force = false }) {
    if (state.registrations && !force) return state.registrations
    const data = (await api.list('Client', params, ['createdAt:DESC'])).items
    commit('setRegistrations', data)
    return data
  },

  async get ({ state, commit }, { params = {}, force = false }) {
    if (state.registrations && !force) return state.registrations
    const data = (await api.list('ClientFull', params, ['createdAt:DESC'])).items
    commit('setRegistrations', data)
    return data
  },

  async remove ({ commit }, id) {
    await api.delete('Client', id)
    commit('removeRegistration', id)
  }
}

const mutations = {
  setRegistrations (state, data) {
    state.registrations = data
  },

  removeRegistration (state, id) {
    const idx = state.registrations.findIndex(item => item.id === id)
    state.registrations.splice(idx, 1)
  }
}

export default {
  state,
  actions,
  mutations,
  namespaced: true,
  modules: {
    notes,
    single,
    invoices,
    fileSetup,
    myAccount,
    statements,
    evaluation,
    consultations,
    subscriptions,
    profileUpdates
  }
}