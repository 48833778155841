<template>
  <layout :active="active">
    <tabs v-model="activeTab" :header="headerData" :tabs="tabs">
      <el-button v-for="(tab, idx) in subTabs[activeTab]" :key="tab" size="mini" :type="activeSubTab === idx ? 'primary' : null" @click="activeSubTab = idx">{{ tab }}</el-button>
      <el-button v-if="setupDone && !data.userId" type="success" icon="el-icon-check" size="mini" @click="setup()">Client Setup</el-button>
    </tabs>

    <panel v-if="data">
      <keep-alive v-if="!loading">
        <component :is="view.component" v-bind="view.data" />
      </keep-alive>

      <div v-else class="loader">
        <i class="el-icon-loading" />
      </div>
    </panel>
  </layout>
</template>

<script>
import { mapActions } from 'vuex'
import helperMixin from '../common/helpers-mixin'

import Notes from './Notes'
import Tabs from '../common/Tabs'
import Panel from '../common/Panel'
import Layout from '../common/Layout'
import ClientInfo from './ClientInfo'
import ClientUsers from './ClientUsers'
import Evaluations from './Evaluations'
import ClientFiles from './ClientFiles'
import ClientUserLog from './ClientUserLog'
import Consultations from './Consultations'
import ProfileUpdate from './ProfileUpdate'
import BillingInfo from './billing/BillingInfo'
import ClientDocuments from './ClientDocuments'
import ClientInfoCompany from './ClientInfoCompany'
import ClientInfoContacts from './ClientInfoContacts'
import BillingInvoices from './billing/BillingInvoices'
import BillingMyAccount from './billing/BillingMyAccount'
import BillingStatements from './billing/BillingStatements'
import SetupChecklist from './setup-checklist/SetupChecklist'
import BillingSubscriptions from './billing/BillingSubscriptions'

export default {
  name: 'ViewRegistration',

  mixins: [helperMixin],

  components: {
    Tabs,
    Panel,
    Notes,
    Layout,
    ClientInfo,
    ClientFiles,
    BillingInfo,
    Evaluations,
    ClientUsers,
    ProfileUpdate,
    ClientUserLog,
    Consultations,
    SetupChecklist,
    BillingInvoices,
    ClientDocuments,
    BillingMyAccount,
    BillingStatements,
    ClientInfoCompany,
    ClientInfoContacts,
    BillingSubscriptions
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      activeTab: 'Registration',
      activeSubTab: 0,
      loading: false
    }
  },

  computed: {
    view () {
      const components = {
        Registration: {
          0: { component: 'client-info' },
          1: {
            component: 'setup-checklist',
            data: {
              id: this.id,
              registration: this.data
            }
          }
        },
        General: {
          0: { component: 'client-general-info' }
        },
        'Client Info': {
          0: { component: 'client-info-contacts' },
          1: { component: 'profile-update' },
        },
        Billing: {
          0: { component: 'billing-info' },
          1: { component: 'billing-my-account' },
          2: { component: 'billing-invoices' },
          3: { component: 'billing-statements' },
          4: { component: 'billing-subscriptions' }
        },
        Documents: {
          0: { component: 'client-documents' }
        },
        Access: {
          0: { component: 'client-users' },
          1: { component: 'client-user-log' }
        },
        Consultations: {
          0: { component: 'consultations' }
        },
        Evaluations: {
          0: { component: 'evaluations' }
        },
        Notes: {
          0: { component: 'notes' }
        },
        Files: {
          0: { component: 'client-files' }
        }
      }

      return components[this.activeTab] ? components[this.activeTab][this.activeSubTab] : 'div'
    },

    tabs () {
      if (!this.isClient) return [['Registration', 'Documents']]
      return [
        ['Registration', 'General', 'Client Info', 'Billing', 'Documents', 'Notes', 'Emails'],
        ['Access', 'Consultations', 'Evaluations', 'Files', 'Account Settings']
      ]
    },

    subTabs () {
      return {
        Registration: ['Client Info', 'Setup Checklist'],
        'Client Info': ['Contacts', 'Profile Update'],
        Billing: ['Billing Info', 'My Account', 'Invoices', 'Statements', 'Subscriptions'],
        Access: ['Users', 'User Log'],
        Consultations: []
      }
    },
    
    active () {
      return this.isClient ? '/clients/profiles' : '/clients/registrations'
    },

    isClient () {
      return this.data ? !!this.data.userId : false
    },
  
    headerData () {
      if (!this.data) return null
      return [
        {
          text: this.data.userId ? 'Client ID' : 'Account ID',
          value: this.data.userId ? this.data.userId.uuid : this.data.id
        }
      ]
    },

    agreement () {
      return this.data?.ClientConsultationAgreement || null
    },
  
    fileSetup () {
      return this.data?.ClientFileSetup || null
    },

    clientAssign () {
      return this.data?.ClientAssign || null
    },

    clientEvaluation () {
      return this.data ? this.data?.ClientEvaluation : null
    },

    accountManager () {
      return this.clientAssign?.accountManager || {}
    },

    projectManager () {
      return this.clientAssign?.clientServicesRep || {}
    },

    setupDone () {
      const agreement = this.agreement?.signedAt && this.agreement?.signedFile
      const fileSetup = this.fileSetup?.signedAt && this.fileSetup?.signedFile
      return this.clientAssign && agreement && this.clientEvaluation?.accepted && fileSetup
    },

    data () {
      return this.$store.state.client.single.registration
    },

    user () {
      return window.appData.currentUser
    }
  },

  watch: {
    activeTab () {
      this.activeSubTab = 0
    }
  },

  async created () {
    this.loading = true
    await this.get({ id: this.id })
    const checklists = ['ClientAssign', 'ClientConsultationAgreement', 'ClientEvaluation', 'ClientFileSetup']
    checklists.forEach(async type => {
      await this.loadChecklist({ type, id: this.id })
    })
    await this.loadDocuments({ id: this.id })
    await this.loadUsers()
    const evaluation = await this.loadEvaluation({ params: { client: this.data.id } })
    this.$store.commit('client/single/setData', { key: 'evaluation', data: evaluation[0] })

    const clientSetup = this.getUrl('clientSetup')
    if (clientSetup && !this.data.userId) this.setup()

    const tab = this.getUrl('tab')
    const subitem = this.getUrl('subitem')
    if (tab) {
      this.activeTab = tab
      if (subitem) this.$nextTick(() => this.activeSubTab = parseInt(subitem))
    }
    this.loading = false
  },

  methods: {
    ...mapActions({
      loadUsers: 'users/list',
      get: 'client/single/get',
      update: 'client/single/update',
      loadDocuments: 'client/single/loadDocuments',
      loadChecklist: 'client/single/loadChecklist',
      loadEvaluation: 'client/evaluation/load'
    }),

    async setup () {
      try {
        await this.$confirm(`Are you sure you want to do Client Setup?`, 'Warning', {
          confirmButtonText: 'Setup Client',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })
        try {
          await this.$confirm(null, `Send Welcome Email?`, {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            type: 'success'
          })
          this.createUser(true)
        } catch {
          this.createUser(false)
        }
      } catch {}
        
    },

    async createUser (sendEmail) {
      try {
        const loading = this.$loading()
        const payload = {
          firstName: this.data.firstName,
          lastName: this.data.lastName,
          email: this.data.email,
          phone: this.data.phone,
          phoneExt: this.data.phoneExt,
          role: 'client',
          uuid: 'GET_USER_UUID',
          activationCode: 'USER_ACTIVATION_CODE',
          activationCodeExpires: 'USER_ACTIVATION_CODE_EXPIRES'
        }
        let user = (await api.create('User', payload, true)).item
        await this.update({ id: this.data.id, payload: { status: 'NEW', userId: user.id } })
        user = (await api.update('User', user.id, { uuid: user.uuid, accountId: 'GET_USER_ACC_ID', created: 'CURRENT_TIMESTAMP' }, true)).item
        if (sendEmail) await api.action('User', user.id, 'ClientWelcomeEmail')
        await this.get({ id: this.id, force: true })
        loading.close()
        this.$alert(`Successfully finished Client Setup.<br><br>Generated Account ID: <b>${user.accountId}</b><br>Generated Activation Code is: <b>${user.activationCode}</b>`, 'Client Setup', {
          confirmButtonText: 'OK',
          dangerouslyUseHTMLString: true
        })
      } catch {}
    }
  }
}
</script>

<style lang="scss" scoped>
.loader {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  color: #000;
}
</style>
