<template>
  <layout active="/system/document-depot">
    <document-depot-menu />
  </layout>
</template>

<script>
import Layout from '../common/Layout'
import DocumentDepotMenu from './DocumentDepotMenu'

export default {
  name: 'DocumentDepot',

  components: {
    Layout,
    DocumentDepotMenu
  }
}
</script>
