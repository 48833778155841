<template>
  <div>
    <div class="buttons">
      <el-button
        size="mini"
        icon="el-icon-plus"
        type="primary"
        :disabled="addNew"
        @click="addNew = true">
        New
      </el-button>
    </div>

    <div class="table-wrapper">
      <div class="info-box">
        <h3>Client Evaluation</h3>
      </div>
      <el-table
        v-loading="loading"
        :data="evaluations"
        style="min-width: 100%"
        stripe>
        <el-table-column label="ID" width="90">
          <template slot-scope="scope">
            {{ scope.row ? scope.row.customId : 'N/A' }}
          </template>
        </el-table-column>

        <el-table-column label="Status" width="90">
          <template slot-scope="scope">
            <div class="status">
              <i :class="statusIcon(scope.row)" />
            </div>
          </template>
        </el-table-column>

        <el-table-column label="Program">
          <template slot-scope="scope">
            <ex-select v-if="!scope.row" v-model="program" :options="programOptions" no-style />
            <template v-else>{{ scope.row.program | program }}</template>
          </template>
        </el-table-column>

        <el-table-column label="Category">
          <template slot-scope="scope">
            <ex-select v-if="!scope.row" v-model="category" :options="categoryOptions" no-style />
            <template v-else>{{ scope.row.category | programCategory }}</template>
          </template>
        </el-table-column>

        <el-table-column label="Created">
          <template slot-scope="scope">
            <template v-if="!scope.row">N/A</template>
            <template v-else>{{ scope.row.createdAt | date }}</template>
          </template>
        </el-table-column>

        <el-table-column label="Submited">
          <template slot-scope="scope">
            <template v-if="!isSubmitted(scope.row)">N/A</template>
            <template v-else>{{ scope.row.submitted | date }}</template>
          </template>
        </el-table-column>

        <el-table-column label="Accepted">
          <template slot-scope="scope">
            <template v-if="!isAccepted(scope.row)">N/A</template>
            <template v-else>{{ scope.row.accepted | date }}</template>
          </template>
        </el-table-column>

        <el-table-column label="Actions" align="right">
          <template slot-scope="scope">
            <template v-if="!scope.row">
              <el-button size="mini" type="danger" @click="addNew = false">Cancel</el-button>
              <el-button :disabled="!valid" size="mini" type="primary" @click="create()">Create</el-button>
            </template>
            <template v-else>
              <el-button v-if="!isSubmitted(scope.row)" size="mini" @click="openShare(scope.row)">Share</el-button>
              <el-button size="mini" type="primary" @click="start(scope.row)">{{ viewButtonText(scope.row) }}</el-button>
              <el-button size="mini" type="danger" @click="cancel(scope.row)">Cancel</el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <modal
      v-if="openForm"
      :width="1024"
      @close="close()">
      <component
        :is="programForm"
        v-model="form"
        :category="openForm ? openForm.category : null"
        :client="client" />

      <div class="mt-20 text-center">
        <template v-if="!isSubmitted(openForm)">
          <el-button size="small" type="primary" :loading="buttonLoader" @click="save()">Save</el-button>
          <el-button size="small" type="success" :loading="buttonLoader" @click="submit()">Submit</el-button>
        </template>
        <template v-else>
          <template v-if="!editMode && !isAccepted(openForm)">
            <el-button size="small" type="primary" @click="editMode = true">Edit</el-button>
            <el-button size="small" type="success" @click="accept()">Accept</el-button>
          </template>
          <el-button v-else size="small" type="primary" :loading="buttonLoader" @click="editSave()">Save</el-button>
        </template>
        <el-button size="small" @click="close()">Close</el-button>
      </div>
    </modal>

    <share
      v-if="shareModal"
      :data="shareModalData"
      :action="actionEvaluation"
      :link="link"
      @update="updateStore($event)"
      @close="shareModal = false" />
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions } from 'vuex'
import helperMixin from '../common/helpers-mixin'

import ExSelect from '../common/Select'
import Share from './setup-checklist/evaluation/Share'
import TemporaryResidence from './setup-checklist/evaluation/TemporaryResidence'
import PEQ from './setup-checklist/evaluation/PEQ'
import PEQReview from './setup-checklist/evaluation/PEQReview'
import TemporaryResidenceReview from './setup-checklist/evaluation/TemporaryResidenceReview'
import EconomicImmigration from './setup-checklist/evaluation/EconomicImmigration'
import HumanitarianImmigration from './setup-checklist/evaluation/HumanitarianImmigration'
import HumanitarianImmigrationReview from './setup-checklist/evaluation/HumanitarianImmigrationReview'
import EconomicImmigrationReview from './setup-checklist/evaluation/EconomicImmigrationReview'
import Documents from './setup-checklist/evaluation/Documents'
import DocumentsReview from './setup-checklist/evaluation/DocumentsReview'
import LegalReturnToCanada from './setup-checklist/evaluation/LegalReturnToCanada'
import LegalReturnToCanadaReview from './setup-checklist/evaluation/LegalReturnToCanadaReview'
import LegalImmigrationAppeal from './setup-checklist/evaluation/LegalImmigrationAppeal'
import LegalImmigrationAppealReview from './setup-checklist/evaluation/LegalImmigrationAppealReview'
import LegalImmigrationDetention from './setup-checklist/evaluation/LegalImmigrationDetention'
import LegalImmigrationDetentionReview from './setup-checklist/evaluation/LegalImmigrationDetentionReview'
import LegalPRAA from './setup-checklist/evaluation/LegalPRAA'
import LegalPRAAReview from './setup-checklist/evaluation/LegalPRAAReview'
import LegalRefugeeAppeal from './setup-checklist/evaluation/LegalRefugeeAppeal'
import LegalRefugeeAppealReview from './setup-checklist/evaluation/LegalRefugeeAppealReview'
import LegalRefugeeClaim from './setup-checklist/evaluation/LegalRefugeeClaim'
import LegalRefugeeClaimReview from './setup-checklist/evaluation/LegalRefugeeClaimReview'
import FamilyReunification from './setup-checklist/evaluation/FamilyReunification'
import FamilyReunificationReview from './setup-checklist/evaluation/FamilyReunificationReview'
import Citizenship from './setup-checklist/evaluation/Citizenship'
import CitizenshipReview from './setup-checklist/evaluation/CitizenshipReview'

export default {
  name: 'Evaluations',

  mixins: [helperMixin],

  components: {
    PEQ,
    Share,
    ExSelect,
    PEQReview,
    Documents,
    DocumentsReview,
    TemporaryResidence,
    EconomicImmigration,
    HumanitarianImmigration,
    TemporaryResidenceReview,
    LegalReturnToCanada,
    LegalReturnToCanadaReview,
    EconomicImmigrationReview,
    LegalImmigrationAppeal,
    LegalImmigrationAppealReview,
    HumanitarianImmigrationReview,
    LegalImmigrationDetention,
    LegalImmigrationDetentionReview,
    LegalRefugeeAppealReview,
    LegalPRAA,
    LegalPRAAReview,
    LegalRefugeeAppeal,
    LegalRefugeeClaim,
    LegalRefugeeClaimReview,
    FamilyReunification,
    FamilyReunificationReview,
    Citizenship,
    CitizenshipReview
  },

  data () {
    return {
      addNew: false,

      type: 'ClientEvaluation',

      program: null,
      category: null,

      editMode: false,
      shareModal: false,
      shareModalData: null,

      form: {},

      openForm: null,
      loading: false,
      buttonLoader: false,

      forms: {
        economic_immigration: 'EconomicImmigration',
        humanitarian_immigration: 'HumanitarianImmigration',
        PEQ: 'PEQ',
        visitor_visa: 'TemporaryResidence',
        supervisa: 'TemporaryResidence',
        student_permit: 'TemporaryResidence',
        temporary_worker: 'TemporaryResidence',
        other: 'TemporaryResidence',
        document_replacement: 'Documents',
        document_extension: 'Documents',
        travel_documents: 'Documents',
        authorization_return: 'LegalReturnToCanada',
        imigration_appeal: 'LegalImmigrationAppeal',
        immigration_detention: 'LegalImmigrationDetention',
        PRAA: 'LegalPRAA',
        refugee_appeal: 'LegalRefugeeAppeal',
        refugee_claim: 'LegalRefugeeClaim',
        spouse_sponsorship: 'FamilyReunification',
        parents_sponsorship: 'FamilyReunification',
        children_sponsorship: 'FamilyReunification',
        citizenship_application: 'Citizenship'
      }
    }
  },

  computed: {
    link () {
      if (!this.shareModalData) return null
      const url = window.appData.apiRoot.replace('/api', '/client-evaluation-form/')
      return this.shareModalData.link ? url + this.shareModalData.link : null
    },

    evaluations () {
      const data = this.$store.state.client.evaluation.data
      return this.addNew ? [...data, null] : data
    },

    programForm () {
      let form = this.openForm ? this.forms[this.openForm.category] || 'div' : 'div'
      if (this.isSubmitted(this.openForm) && !this.editMode) form += 'Review'
      return form
    },

    programOptions () {
      return Object.keys(window.programOptions).map(key => ({
        id: key,
        text: window.programOptions[key].text
      }))
    },

    categoryOptions () {
      if (!this.program) return null
      return Object.keys(window.programOptions[this.program].items).map(key => ({
        id: key,
        text: window.programOptions[this.program].items[key]
      }))
    },

    client () {
      return this.$store.state.client.single.registration
    },

    valid () {
      return !!(this.program && this.category)
    }
  },

  watch: {
    'program' () {
      this.category = null
    }
  },

  methods: {
    ...mapActions({
      actionEvaluation: 'client/evaluation/action',
      createEvaluation: 'client/evaluation/create',
      updateEvaluation: 'client/evaluation/update',
      remove: 'client/evaluation/delete'
    }),

    openShare (data) {
      this.shareModal = true
      this.shareModalData = data
    },

    viewButtonText (evaluation) {
      if (this.isAccepted(evaluation)) return 'View'
      if (this.isSubmitted(evaluation)) return 'Review'
      return 'Start'
    },

    statusIcon (evaluation) {
      if (this.isAccepted(evaluation)) return 'el-icon-s-claim success'
      return this.isSubmitted(evaluation) ? 'el-icon-s-claim warning' : 'el-icon-s-release'
    },

    isSubmitted (evaluation) {
      return evaluation?.submitted
    },

    isAccepted (evaluation) {
      return evaluation?.accepted
    },

    editSave () {
      const payload = { data: this.form }
      this.update(payload, true)
      this.editMode = false
    },

    close () {
      this.openForm = null
      this.editMode = false
    },

    async accept () {
      this.$confirm('Are you sure you want to accept?').then(() => {
        const payload = { accepted: 'CURRENT_TIMESTAMP' }
        this.update(payload)
      })
    },

    submit () {
      const payload = {
        data: this.form,
        submitted: 'CURRENT_TIMESTAMP'
      }
      this.update(payload)
    },

    save () {
      const payload = { data: this.form }
      this.update(payload)
    },

    start (evaluation) {
      if (evaluation?.data) {
        this.form = _.cloneDeep(evaluation.data)
      }
      this.openForm = evaluation
    },

    async update (payload, openForm = null) {
      this.buttonLoader = true
      try {
        const evaluation = await this.updateEvaluation({ id: this.openForm.id, payload})
        this.$message.success('Client Evaluation updated successfully.')
        this.openForm = openForm
      } catch {} finally {
        this.buttonLoader = false
      }
    },

    async create () {
      if (!this.valid) return

      this.loading = true
      try {
        const payload = {
          program: this.program,
          category: this.category,
          client: this.client.id,
          customId: 'EVALUATIONS_CUSTOM_ID',
          isSetup: false,
          createdAt: 'CURRENT_TIMESTAMP',
          createdBy: window.appData.currentUser.id
        }
        await this.createEvaluation(payload)
        this.addNew = false
        this.program = null
        this.category = null
      } catch {} finally {
        this.loading = false
      }
    },

    async cancel (evaluation) {
      try {
        await this.$confirm('Are you sure you want to cancel a Client Evaluation?')
        try {
          this.loading = true
          await this.remove(evaluation.id)
          this.$message.success('Client Evaluation cancelled successfully.')
        } catch {} finally {
          this.loading = false
        }
      } catch {}
    },

    updateStore (data) {
      if (this.shareModalData) this.shareModalData = data
      this.$store.commit('client/single/setData', { key: 'ClientEvaluation', data })
      this.$store.commit('client/single/setData', { key: 'evaluation', data })
    }
  }
}
</script>

<style lang="scss" scoped>
.table-wrapper {
  margin-top: 20px;
}
.status {
  font-size: 30px;
  margin-right: 10px;
  .success {
    color: #67c23a;
  }
  .warning {
    color: orange;
  }
}
</style>