<template>
  <div class="page-form">
    <p class="text-center">Legal - PRAA</p>
    
    <p><b>PERSONAL INFORMATION</b></p>

    <p>{{ client.firstName }} - {{ client.lastName }} - {{ age }} - {{ client.nationality | country }} - {{ client.residence | country }}</p>

    <p>Do you have a criminal record? <span class="answer">{{ formatYN(value.criminal_record) }}</span></p>

    <p><b>MAIN APPLICANT</b></p>

    <p>Are you currently living in Canada <span class="answer">{{ formatYN(value.living_in_canada) }}</span></p>

    <p v-if="value.living_in_canada === 'yes'">Date of entry to Canada <span class="answer">{{ value.entry_to_canada | date('ll') }}</span></p>

    <div v-else>
        <p>Date of last entry to Canada <span class="answer">{{ value.last_entry_to_canada | date('ll') }}</span></p>
        <p>Date of last refugee claim <span class="answer">{{ value.last_refugee_claim | date('ll') }}</span></p>
        <p>Date of leave from Canada <span class="answer">{{ value.leave_from_canada | date('ll') }}</span></p>
    </div>

    <p><b>ADDITIONAL INFORMATION:</b></p>

    <span class="answer">{{ value.additional_info }}</span>

  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    client: {
      type: Object,
      required: true
    }
  },

  computed: {
    age () {
      return moment().diff(moment(this.client.dob), 'years')
    }
  },

  methods: {
    formatYN (val) {
      return val === 'yes' ? 'Yes' : 'No'
    }
  }
}
</script>
