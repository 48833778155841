<template>
  <el-drawer
    :title="title"
    :visible.sync="drawer"
    :before-close="beforeClose"
    :size="size"
    destroy-on-close
    @close="$emit('closed')">
    <div class="content">
      <slot />
    </div>
    <div
      v-if="$slots.footer"
      class="drawer__footer">
      <slot name="footer" />
    </div>
  </el-drawer>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },

    title: {
      type: String,
      required: true
    },

    size: {
      type: String,
      default: "400px"
    },

    beforeClose: {
      type: Function,
      default: (done) => done()
    }
  },

  data () {
    return {
      drawer: this.value
    }
  },

  watch: {
    value (value) {
     this.drawer = value
    },

    drawer (value) {
      this.$emit('input', value)
    }
  },
}
</script>