<template>
<button
  class="ch-button"
  :class="buttonClasses"
  :disabled="disabled"
  @click="$emit('click', $event)">
  <slot />
</button>
</template>

<script>
export default {
  name: 'chButton',
  
  props: {
    color: {
      type: String,
      // validator: (value) => ['gray', 'white', 'primary', 'success', 'danger', 'warning'].includes(value),
      default: 'primary'
    },

    size: {
      type: String,
      // validator: (value) => ['xs', 'sm', 'md', 'lg'].indexOf(value) !== -1,
      default: 'md'
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    buttonClasses () {
      const classes = []
      classes.push(`btn-${this.size}`)
      classes.push(`btn-${this.color}`)
      return classes
    }
  }
}
</script>

<style lang="scss" scoped>
  .ch-button {
    border: none;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    transition: all ease .2s;
  }
  .ch-button:disabled {
    cursor: auto;
  }

  .ch-button.btn-xs {
    padding: 0 5px;
    font-size: 13px;
    height: 20px;
  }
  .ch-button.btn-sm {
    padding: 0 10px;
    font-size: 14px;
    height: 28px;
  }
  .ch-button.btn-md {
    padding: 0 15px;
    font-size: 16px;
    height: 35px;
  }
  .ch-button.btn-lg {
    padding: 0 25px;
    font-size: 22px;
    height: 45px;
  }

  .ch-button.btn-white {
    background-color: #fff;
    color: #555;
  }
  .ch-button.btn-white:hover, .ch-button.btn-white:focus {
    background-color: #f7f7f7;
    color: #000;
  }
  .ch-button.btn-white:disabled {
    background-color: #fff;
    color: #999;
  }

  .ch-button.btn-gray {
    background-color: #dbdbdb;
    color: #222;
  }
  .ch-button.btn-gray:hover, .ch-button.btn-gray:focus {
    background-color: #cecece;
    color: #000;
  }
  .ch-button.btn-gray:disabled {
    background-color: #eeeeee;
    color: #999;
  }

  .ch-button.btn-primary {
    background-color: #000;
  }
  .ch-button.btn-primary:hover, .ch-button.btn-primary:focus {
    background-color: #7033cb;
  }
  .ch-button.btn-primary:disabled {
    background-color: #7cb5f3;
  }

  .ch-button.btn-success {
    background-color: #16cc6b;
  }
  .ch-button.btn-success:hover, .ch-button.btn-success:focus {
    background-color: #14b15d;
  }
  .ch-button.btn-success:disabled {
    background-color: #68e2a1;
  }

  .ch-button.btn-danger {
    background-color: #ec2355;
  }
  .ch-button.btn-danger:hover, .ch-button.btn-danger:focus {
    background-color: #d11f4c;
  }
  .ch-button.btn-danger:disabled {
    background-color: #f56b8d;
  }

  .ch-button.btn-warning {
    background-color: #eca224;
  }
  .ch-button.btn-warning:hover, .ch-button.btn-warning:focus {
    background-color: #d4911c;
  }
  .ch-button.btn-warning:disabled {
    background-color: #f5c470;
  }
</style>
