<template> 
  <div class="page">
    <div class="wrapper">
      <logo />

      <panel :title="$tc('registration.client_registration')" padding="0" class="panel" :border="false">
        <div class="subtitle">{{ $tc('registration.client_general_information') }}</div>
        <div class="form">
          <div class="col w100">
            <div class="lang-field">
              <ex-select v-model="form.lang" label="Language / Langage / Idioma" placeholder="Language / Langage / Idioma" :validator="$v.form.lang" :options="langOptions" @input="onLangChange" full />
            </div>
          </div>
          <div class="col">
            <ex-input v-model="form.firstName" :label="$tc('registration.first_name')" :placeholder="$tc('registration.enter_first_name')" :validator="$v.form.firstName" full />
          </div>
          <div class="col">
            <ex-input v-model="form.lastName" :label="$tc('registration.last_name')" :placeholder="$tc('registration.enter_last_name')" :validator="$v.form.lastName" full />
          </div>
          <div class="col">
            <div class="form-group full">
              <label>{{ $tc('registration.date_of_birth') }}: <span class="required">*</span></label>
              <div class="date-form-group">
                <ex-select v-model="day" :validator="$v.day" :options="dayOptions" full no-style>{{ $tc('registration.date_dd') }}</ex-select>
                <ex-select v-model="month" :validator="$v.month" :options="monthOptions" full no-style>{{ $tc('registration.date_mm') }}</ex-select>
                <ex-select v-model="year" :validator="$v.year" :options="yearOptions" full no-style>{{ $tc('registration.date_yy') }}</ex-select>
              </div>
            </div>
          </div>
          <div class="col">
            <ex-input v-model="form.email" type="email" :label="$tc('registration.email_address')" :placeholder="$tc('registration.enter_email_address')" :validator="$v.form.email" full />
          </div>
          <div class="col">
            <ex-select v-model="selectedNationality" :label="$tc('registration.country_of_nationality')" :placeholder="$tc('registration.select_country_of_nationality')" :validator="$v.form.nationality" :options="countryOptions" full />
          </div>
          <div class="col">
            <ex-select v-model="selectedResidence" :label="$tc('registration.country_of_residence')" :placeholder="$tc('registration.select_country_of_residence')" :validator="$v.form.residence" :options="countryOptions" full />
          </div>
        </div>
      
        <div class="subtitle">{{ $tc('registration.client_address_information') }}</div>
        <div class="form">
          <div class="col">
            <ex-input v-model="form.address" :label="$tc('registration.address')" :placeholder="$tc('registration.enter_address')" :validator="$v.form.address" full />
          </div>
          <div class="col">
            <ex-input v-model="form.city" :label="$tc('registration.city')" :placeholder="$tc('registration.enter_city')" :validator="$v.form.city" full />
          </div>
          <div class="col">
            <ex-select v-model="selectedState" :label="$tc('registration.state_province')" :placeholder="$tc('registration.select_state_province')" :disabled="!stateOptions" :validator="$v.form.state" :options="stateOptions" full />
          </div>
          <div class="col">
            <ex-input v-model="form.postal" :label="$tc('registration.postal_code')" :placeholder="$tc('registration.enter_postal_code')" :validator="$v.form.postal" full />
          </div>
          <div class="col">
            <ex-input v-model="form.phone" :label="$tc('registration.phone_number')" :placeholder="$tc('registration.enter_phone_number')" :validator="$v.form.phone" full />
          </div>
          <div class="col">
            <ex-input v-model="form.otherPhone" :label="$tc('registration.other_phone_number')" :placeholder="$tc('registration.other_phone_number')" full />
          </div>
        </div>

        <div class="subtitle">{{ $tc('registration.client_service_requested') }}</div>
        <div class="form">
          <div class="col w100">
            <ex-select v-model="form.service" :label="$tc('registration.service')" :placeholder="$tc('registration.select_service')" :validator="$v.form.service" :options="serviceOptions" full />
          </div>
        </div>

        <div class="terms">
          <p>{{ $tc('registration.do_you_agree_to_our') }}:</p>
          <div class="check">
            <div class="ch-div">
              <input v-model="agree.terms" type="checkbox" id="terms" class="check-b">
              <label for="terms"> <a>{{ $tc('registration.termes_conditions') }}</a></label>
            </div>
            <div class="ch-div">
              <input v-model="agree.privacy" type="checkbox" id="cbx1" class="check-b">
              <label for="cbx1"> <a>{{ $tc('registration.privacy') }}</a></label>
            </div>
            <div class="ch-div">
              <input v-model="agree.user_policy" type="checkbox" id="cbx2" class="check-b">
              <label for="cbx2"> <a>{{ $tc('registration.user_policy') }}</a></label>
            </div>
            <button class="ch-btn" @click="save">{{ $tc('registration.submit_registration') }}</button>
          </div>
        </div>
      </panel>

      <get-plugged-footer :is-login="false" />
      <div class="login-logo">
        <img src="/assets/images/login-logo-2.png">
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '../common/Logo'
import Panel from '../common/Panel'
import ExInput from '../common/Input'
import ExSelect from '../common/Select'
import Datepicker from 'vuejs-datepicker'
import GetPluggedFooter from '../common/GetPluggedFooter'

import helperMixin from '../common/helpers-mixin'
import { phone, isTruthy } from '../common/validators'
import { required, numeric, email, alpha } from 'vuelidate/lib/validators'

export default {
  name: 'client-registration',

  mixins: [ helperMixin ],

  components: {
    Logo,
    Panel,
    ExInput,
    ExSelect,
    Datepicker,
    GetPluggedFooter
  },

  data () {
    return  {
      form: {
        lang: 'en',

        firstName: null,
        lastName: null,
        email: null,
        nationality: null,
        residence: null,

        address: null,
        city: null,
        state: null,
        postal: null,
        phone: null,
        otherPhone: null,
        service: null
      },

      day: null,
      month: null,
      year: null,

      agree: {
        terms: false,
        privacy: false,
        user_policy: false
      },

      selectedState: null,
      selectedResidence: null,
      selectedNationality: null,
      langOptions: window.langOptions
    }
  },

  computed: {
    yearOptions () {
      const data = []
      const currentYear = (new Date()).getFullYear()
      for(let i = currentYear; i >= 1930; i--)
        data.push({
          id: i,
          text: i
        })
      return data
    },

    monthOptions () {
      return window.months.map(month => ({
        id: month,
        text: month
      }))
    },

    dayOptions () {
      const options = []
      for (let i = 1; i <= 31; i++) {
        options.push({
          id: i,
          text: i
        })
      }
      return options
    },

    stateOptions () {
      return (['CA', 'US'].includes(this.form.residence)) ? window.states[this.form.residence].map(state => {
        return {
          id: state.id,
          text: `${state.id} - ${state.text}`
        }
      }) : null
    }
  },

  watch: {
    selectedState (value) {
      if (value) {
        this.form.state = this.getInitials(value)
      }
    },

    selectedNationality (value) {
      this.form.nationality = this.getInitials(value)
    },

    selectedResidence (value) {
      if (value) {
        this.form.residence = this.getInitials(value)
        this.selectedState = null
        this.form.state = null
      } else {
        this.form.residence = null
      }
    }
  },

  methods: {
    onLangChange (lang) {
      this.$i18n.locale = lang || 'en'
    },

    async save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const loading = this.$loading()
        try {
          const payload = {
            obj: {
              ...this.form,
              createdAt: 'CURRENT_TIMESTAMP',
              status: 'REG'
            }
          }
          payload.obj.dob = `${this.year}-${window.months.indexOf(this.month) + 1}-${this.day}`
          await api.request('POST', '/client-registration', payload)
          this.$alert('Thank you for submitting your information!', 'Registration submitted!', {
            confirmButtonText: 'Back to Home',
            center: true,
            showClose	: false,
            callback: action => action === 'confirm' ? location.href = '/' : false
          })
        } catch {
          this.$message.error('There was an error... Please try again.')
        } finally {
          loading.close()
        }
      }
    },

    getInitials (value) {
      if (!value) return null
      return value.split('').splice(0, 2).join('')
    }
  },

  validations () {
    const rules = {
      form: {
        lang: { required },
        firstName: { required },
        lastName: { required },
        email: { required, email },
        nationality: { required },
        residence: { required },
        address: { required },
        city: { required },
        postal: { required },
        phone: { required, phone },
        service: { required }
      },

      day: { required },
      month: { required },
      year: { required },

      agree: {
        terms: { required, isTruthy },
        privacy: { required, isTruthy },
        user_policy: { required, isTruthy }
      }
    }

    if (this.stateOptions) {
      rules.form.state = { required }
    }

    return rules
  }
}
</script>

<style lang="scss">
.page {
  /*background-image: url("/assets/images/login-bg.jpg");*/
  background-color:#e7e7e7;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 75px 0;
  position: relative;
  .wrapper {
    width: 100%;
    max-width: 685px;
    margin: 0 auto;
    .form {
      .col {
        padding-right: 40px;
        width: 50%;
        &.w100 {
          width: 100%;
        }
      }
      .lang-field {
        width: 50%;
        margin: 0 auto;
      }
    }
    .terms {
      min-height: 90px;
      background-color: #d6d6d6;
      display: flex;
      align-items: center;
      position: relative;
      p {
        font-size: 12px;
        font-weight: 500;
        color: #828282;
        position: absolute;
        top: 10px;
        left: 20px;
        font-family: 'Roboto', sans-serif;
      }
      .check {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        .ch-div {
          display: flex;
          align-items: center;
          label {
            margin: 0;
            margin-left: 10px;
            a {
              font-size: 12px;
            }
          }
          .check-b {
            width: 25px;
            height: 25px;
            box-sizing: border-box;
            padding: 0;
          }
        }
        .ch-btn {
          font-size: 19px;
          font-weight: 700;
          color: #fff;
          font-family: 'Roboto', sans-serif;
          background: rgb(69,162,11);
          background: linear-gradient(180deg, rgba(69,162,11,1) 85%, rgba(43,140,0,1) 100%);
          padding: 15px 30px;
          border-radius: 5px;
          border: none;
          border-bottom: 3px solid #fff;
          box-shadow: 0px 4px 6px #b6b6b6;
          cursor: pointer;
          &:disabled {
            cursor: not-allowed;
            opacity: .7;
          }
        }
      }
    }
    .panel {
      margin-bottom: 20px;
    }
    .subtitle {
      min-height: 40px;
      background-color: #000;
      color: #fff;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.login-logo {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 80px;
  height: 80px;
  img{
    width: 100%; 
    height: 100%; 
  }
}

.form-group {
  margin: 0 20px;
}
.date-form-group {
  display: flex;
}
</style>