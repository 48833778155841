<template>
  <div class="mb-50">
    <table class="form-table">
      <tr class="heading">
        <td colspan="4"><b>2. Documents:</b></td>
      </tr>
    </table>
    
    <table
      v-for="document, idx in form.documents"
      :key="idx"
      class="form-table">
      <tr>
        <td colspan="4">
          <b>{{ idx === 0 ? 'Main applicant' : `Dependant ${idx}` }}</b>
          <el-button
            v-if="idx !== 0"
            size="mini"
            type="danger"
            icon="el-icon-delete"
            class="remove-item"
            @click="form.documents.splice(idx, 1)">
            Remove Dependant 
          </el-button>
        </td>
      </tr>
      <tr>
        <td>ICU : (Brown Paper)</td>
        <td>Canadian Visa / ETA No.</td>
        <td>Date of Issue</td>
        <td>Date of Expiration</td>
      </tr>
      <tr v-if="readonly">
        <td>{{ form.documents[idx].ICU }}</td>
        <td>{{ form.documents[idx].visa }}</td>
        <td>{{ form.documents[idx].issueDate | date }}</td>
        <td>{{ form.documents[idx].expirationDate | date }}</td>
      </tr>
      <tr v-else>
        <td><ex-input v-model="form.documents[idx].ICU" no-style full /></td>
        <td><ex-input v-model="form.documents[idx].visa" no-style full /></td>
        <td><datepicker v-model="form.documents[idx].issueDate" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="Date of Issue" no-style /></td>
        <td><datepicker v-model="form.documents[idx].expirationDate" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="Date of Expiration" no-style /></td>
      </tr>
      <tr>
        <td>Passport No.</td>
        <td>Country of Issue</td>
        <td>Date of Issue</td>
        <td>Date of Expiration</td>
      </tr>
      <tr v-if="readonly">
        <td>{{ form.documents[idx].passport.no }}</td>
        <td>{{ form.documents[idx].passport.country }}</td>
        <td>{{ form.documents[idx].passport.issueDate | date }}</td>
        <td>{{ form.documents[idx].passport.expirationDate | date }}</td>
      </tr>
      <tr v-else>
        <td><ex-input v-model="form.documents[idx].passport.no" no-style full /></td>
        <td><ex-select v-model="form.documents[idx].passport.country" :options="countryOptions" no-style full /></td>
        <td><datepicker v-model="form.documents[idx].passport.issueDate" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="Date of Issue" no-style /></td>
        <td><datepicker v-model="form.documents[idx].passport.expirationDate" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="Date of Expiration" no-style /></td>
      </tr>
    </table>

    <div
      v-if="!readonly"
      class="mt-10">
      <el-button
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="$emit('add')">
        Add Dependant 
      </el-button>
    </div>
  </div>
</template>

<script>
import helperMixin from '../../common/helpers-mixin'

import ExInput from '../../common/Input'
import ExSelect from '../../common/Select'
import Datepicker from 'vuejs-datepicker'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    readonly: {
      type: Boolean,
      default: true
    }
  },

  mixins: [ helperMixin ],

  components: {
    ExInput,
    ExSelect,
    Datepicker
  },

  data () {
    return {
      form: this.value
    }
  }
}
</script>

<style lang="scss" scoped>
  .remove-item {
    float: right;
  }
</style>