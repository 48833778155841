<template>
  <div class="page-form">
    <p class="text-center">Permanent Residence – Humanitarian Immigration</p>
    
    <p><b>PERSONAL INFORMATION</b></p>

    <p>{{ client.firstName }} - {{ client.lastName }} - {{ age }} - {{ client.nationality | country }} - {{ client.residence | country }}</p>

    <p>Do you have a criminal record? <span class="answer">{{ formatYN(value.criminal_record) }}</span></p>

    <p>Have you been declared a Protected Person or a Refugee by the Refugee Protection Division? <span class="answer">{{ formatYN(value.protected_person) }}</span></p>

    <p><b>PERMANENT RESIDENCE:</b></p>

    <p>Do you have a CSQ? <span class="answer">{{ formatYN(value.csq) }}</span>
      <span class="ml-20">How many persons will be included in the application? {{ value.persons_included }}</span>
      <br>Are any applicants outside Canada? <span class="answer">{{ formatYN(value.applicants_outside_canada) }}</span>
    </p>

    <p><b>HUMANITARIAN AND COMPASSIONATE CONSIDERATIONS:</b></p>

    <p>How long have you been living in Canada? {{ value.how_long_living_in_ca }} Years
      Are you currently working? <span class="answer">{{ formatYN(value.working) }}</span>
      <br>Are you able to communicate in
        <span class="checkbox-group"><input type="checkbox" :disabled="true" :checked="value.communicate === 'English'"> English</span>
        <span class="checkbox-group"><input type="checkbox" :disabled="true" :checked="value.communicate === 'French'"> French</span>
        <span class="checkbox-group"><input type="checkbox" :disabled="true" :checked="value.communicate === 'Both'"> Both</span>
        <span class="checkbox-group"><input type="checkbox" :disabled="true" :checked="value.communicate === 'Neither'"> Neither</span>
      <br>Did you make a refugee claim that was rejected by the Refugee Protection Division or the Refugee Appeal Division of the Immigration Refugee Board of Canada or did you abandon your Refugee Claim?
        <span class="answer">{{ formatYN(value.abandon_refugee) }}</span>
    </p>

    <div v-if="value.abandon_refugee === 'yes'">
      <p>Was your refugee claim rejected or abandoned within the last 12 months? <span class="answer">{{ formatYN(value.abondoned_in_last_12_months) }}</span>
        <br>Did you withdraw your Refugee claim during your Refugee Protection Division hearing, which occurred with the last 12 months?
          <span class="answer">{{ formatYN(value.withdraw_refugee_claim) }}</span></p>

      <p><b>Is your application based on:</b>
        <br>The Best interest of a child? <span class="answer">{{ formatYN(value.best_interest_of_child) }}</span>
        <br>You suffer from a medical condition that would risk your life if you are returned to your country?
          <span class="answer">{{ formatYN(value.suffer_mediacal_cond) }}</span>
        <br>Other <span class="answer">{{ formatYN(value.other) }}</span></p>
    </div>

    <div v-if="value.other === 'yes'">
      <p>Are you currently in a sponsorship process? <span class="answer">{{ formatYN(value.sponsorship_process) }}</span>
        <span class="ml-20">Since when? {{ value.sponsorship_process_since || '' }}</span>
        <span class="checkbox-group"><input v-model="value.sponsorship_process_since_unknown" type="checkbox" :disabled="true"> Not sure</span>
        <br>Are you a victim of abuse, domestic violence or a forced marriage? <span class="answer">{{ formatYN(value.victiom_violence) }}</span>
        <br>Did you already receive your Certificate of Selection of Quebec? <span class="answer">{{ formatYN(value.certificate_selectin) }}</span>
        <br>Are there any children included in your application? <span class="answer">{{ formatYN(value.any_children) }}</span>
      </p>
    </div>

    <p><b>ADDITIONAL INFORMATION:</b></p>
    <span class="answer">{{ formatYN(value.additional_info) }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    client: {
      type: Object,
      required: true
    }
  },

  computed: {

    age () {
      return moment().diff(moment(this.client.dob), 'years')
    }
  },

  methods: {
    formatYN (val) {
      if (val === 'yes') return 'Yes'
      if (val === 'no') return 'No'
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox-group {
  margin-left: 50px;
  input {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
}
</style>