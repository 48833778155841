<template>
<layout active="/tools/scheduler">
  <schedulerMenu :page="currentPage" />

  <eventsByDate :date="date" :type="type" />
</layout>
</template>

<script>
import SchedulerMenu from './SchedulerMenu'
import EventsByDate from '../calendar/EventsByDate'
import Layout from '../common/Layout'

export default {
  name: 'SchedulerCalendarDateView',

  components: {
    SchedulerMenu,
    EventsByDate,
    Layout
  },

  props: {
    date: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      currentPage: {
        text: 'Calendar'
      },
      type: 'SCHEDULER'
    }
  }
}
</script>
