<template>
  <div class="page-form">
    <p class="text-center">Permanent Residence – Economic Immigration</p>
    
    <p><b>PERSONAL INFORMATION</b></p>

    <p>{{ client.firstName }} - {{ client.lastName }} - {{ age }} - {{ client.nationality | country }} - {{ client.residence | country }}</p>

    <economic-immigration-form-review v-model="value" />

    <p><b>SPOUSE / COMMON-LAW PARTNER INFORMATION</b></p>

    <p>Do you have a spouse or common-law partner? <span class="answer">{{ formatYN(value.spouse) }}</span></p>
    
    <p>
      First Name <span class="answer">{{ value.spouse_info.firstName }}</span>
      <span class="ml-20">Last Name <span class="answer">{{ value.spouse_info.lastName }}</span></span>
      <span class="ml-20">Age <span class="answer">{{ value.spouse_info.age }}</span></span>
      <br><span>Country of Birth <span class="answer">{{ value.spouse_info.nationality | country }}</span></span>
      <span class="ml-20">Country of Residence <span class="answer">{{ value.spouse_info.residence | country }}</span></span>
    </p>

    <economic-immigration-form-review
      v-model="value.spouse_info"
      is-spouse />

      
    <p><b>ADDITIONAL INFORMATION:</b></p>
    <p><span class="answer">{{ value.additional_info }}</span></p>
  </div>
</template>

<script>
import EconomicImmigrationFormReview from './EconomicImmigrationFormReview'

export default {
  components: {
    EconomicImmigrationFormReview
  },

  props: {
    value: {
      type: Object,
      required: true
    },

    client: {
      type: Object,
      required: true
    }
  },

  computed: {
    age () {
      return moment().diff(moment(this.client.dob), 'years')
    }
  },

  methods: {
    formatYN (val) {
      return val === 'yes' ? 'Yes' : 'No'
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox-group {
  margin-left: 50px;
  input {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
}
</style>