<template>
  <div class="page-form">
    <p class="text-center">Legal - Refugee Appeal</p>
    
    <p><b>PERSONAL INFORMATION</b></p>

    <p>{{ client.firstName }} - {{ client.lastName }} - {{ age }} - {{ client.nationality | country }} - {{ client.residence | country }}</p>

    <p>Do you have a criminal record? <ex-select v-model="form.criminal_record" :options="yesNoOptions" no-style /></p>

    <p><b>MAIN APPLICANT</b></p>

    <p>Are you currently living in Canada <ex-select v-model="form.living_in_canada" :options="yesNoOptions" no-style /></p>

    <div v-if="isLivingInCanada">
        <p>Date of the decision of the Refugee Protection Division <datepicker v-model="form.refugee_protection_division" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="Date" style="width: 155px; display: inline-block"/></p>
        <p>Did you already submit the intention to appeal to the Refugee Appeal Division? <ex-select v-model="form.intention_to_appeal" :options="yesNoOptions" no-style /></p>
    </div>

    <div v-if="alreadyAppealed">
        <p>Date submitted <datepicker v-model="form.date_submited" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="Date" style="width: 155px; display: inline-block"/></p>
        <p>How many applicants will be in your appeal? <ex-input v-model.number="form.applicants_number" type="number" no-style style="width: 135px;"/></p>
    </div>

    <p><b>ADDITIONAL INFORMATION:</b></p>

    <ex-textarea v-model="form.additional_info" :rows="4" :maxlength='500' no-style full />

  </div>
</template>

<script>
import numeral from 'numeral'

import Datepicker from 'vuejs-datepicker'
import ExInput from '../../../common/Input'
import ExSelect from '../../../common/Select'
import ExTextarea from '../../../common/Textarea'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    client: {
      type: Object,
      required: true
    }
  },

  components: {
    ExInput,
    ExSelect,
    Datepicker,
    ExTextarea
  },

  data () {
    return {
      form: this.value,

      yesNoOptions: [
        {
          id: 'yes',
          text: 'Yes'
        },
        {
          id: 'no',
          text: 'No'
        }
      ]
    }
  },

  computed: {
    alreadyAppealed () {
      return this.form.intention_to_appeal === 'yes'
    },

    isLivingInCanada () {
      return this.form.living_in_canada === 'yes'
    },

    age () {
      return moment().diff(moment(this.client.dob), 'years')
    }
  },

  watch: {
    form: {
      handler (form) {
        this.$emit('input', form)
      },
      deep: true,
      immediate: true
    }
  },

  created () {
    if (!Object.keys(this.form).length) {
      this.form = {
        criminal_record: null,
        living_in_canada: null,
        refugee_protection_division: null,
        intention_to_appeal: null,
        date_submited: null,
        applicants_number: null,
        additional_info: null,
      }
    }
  }
}
</script>
