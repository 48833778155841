<template>
  <div class="plugg-tabs">
    <div class="header">
      <span>Client Modal v1.0</span>
      <span class="right">{{ now | date('PD') }}</span>
    </div>
    <div class="empty">
      <template v-if="header">
        <span v-for="item in header" :key="item.text">{{ item.text }}: <b>{{ item.value }}</b></span>
      </template>
    </div>
    <div class="tabs">
      <div v-for="(tab, idx) in tabs" :key="idx">
        <button
          v-for="item in tab"
          :key="item"
          class="btn"
          :class="{ active: activeTab === item }"
          @click="activeTab = item">
          {{ item }}
        </button>
      </div>
    </div>
    <div v-if="$slots.default" class="tab-items">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',

  props: {
    value: {
      type: String,
      required: true
    },

    tabs: {
      type: Array,
      required: true
    },

    header: {
      type: Array,
      default: null
    }
  },

  data () {
    return {     
      now: moment()
    }
  },

  computed: {
    activeTab: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },

  created () {
    setInterval(this.getNow, 1000);
  },

  methods: {
    getNow () {
      this.now = moment()
    }
  },
}
</script>

<style lang="scss" scoped>
.btn {
  margin: 4px;
}
</style>