<template>
  <div class="mb-50">
    <table class="form-table">
      <tr class="heading">
        <td colspan="5"><b>3. Country of residence in the last 5 years (Other than Canada or your Country of Origin)</b></td>
      </tr>
    </table>
    
    <table class="form-table">
      <tr>
        <td>Country</td>
        <td>Status</td>
        <td>From (Year/Month/Day)</td>
        <td>A (Year/Month/Day)</td>
        <td v-if="!readonly" />
      </tr>
      <template v-if="readonly">
        <tr
          v-for="document, idx in form.residenceCountries"
          :key="idx">
          <td>{{ form.residenceCountries[idx].country | country }}</td>
          <td>{{ form.residenceCountries[idx].status }}</td>
          <td>{{ form.residenceCountries[idx].from | date }}</td>
          <td>{{ form.residenceCountries[idx].to | date }}</td>
        </tr>
      </template>
      <template v-else>
        <tr
          v-for="document, idx in form.residenceCountries"
          :key="idx">
          <td><ex-select v-model="form.residenceCountries[idx].country" :options="countryOptions" no-style full /></td>
          <td><ex-input v-model="form.residenceCountries[idx].status" no-style full /></td>
          <td><datepicker v-model="form.residenceCountries[idx].from" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="From" no-style /></td>
          <td><datepicker v-model="form.residenceCountries[idx].to" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="A" no-style /></td>
          <td>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              round
              :disabled="idx === 0"
              @click="form.residenceCountries.splice(idx, 1)" />
          </td>
        </tr>
      </template>
    </table>

    <div class="mt-10">
      <el-button
        v-if="!readonly"
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="$emit('add')">
        Add Country 
      </el-button>
    </div>
  </div>
</template>

<script>
import helperMixin from '../../common/helpers-mixin'

import ExInput from '../../common/Input'
import ExSelect from '../../common/Select'
import Datepicker from 'vuejs-datepicker'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    readonly: {
      type: Boolean,
      default: true
    }
  },

  mixins: [ helperMixin ],

  components: {
    ExInput,
    ExSelect,
    Datepicker
  },

  data () {
    return {
      form: this.value
    }
  }
}
</script>

<style lang="scss" scoped>
  .remove-item {
    float: right;
  }
</style>