<template>
  <layout active="/tools/scheduler">
    <scheduler-menu />

    <panel :title="title">
      <scheduler-dashboard />
    </panel>
  </layout>
</template>

<script>
import Layout from '../common/Layout'
import SchedulerDashboard from './SchedulerDashboard'
import SchedulerMenu from './SchedulerMenu'

export default {
  name: 'Scheduler',

  components: {
      Layout,
      SchedulerDashboard,
      SchedulerMenu
  },

  data () {
    return {
      page: {
        text: 'Scheduler Dashboard'
      }
    }
  },

  computed: {
    title () {
      return this.page ? this.page.text : this.default
    }
  }
}
</script>
