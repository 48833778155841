<template>
  <div class="page-form">
    <p class="text-center">Legal - Immigration Detention</p>
    
    <p><b>PERSONAL INFORMATION</b></p>

    <p>{{ client.firstName }} - {{ client.lastName }} - {{ age }} - {{ client.nationality | country }} - {{ client.residence | country }}</p>

    <p>Do you have a criminal record?  <span class="answer">{{ formatYN(value.criminal_record) }}</span></p>

    <p><b>MAIN APPLICANT</b></p>

    <p>Did the detained person already have a hearing? <span class="answer">{{ formatYN(value.have_hearing) }}</span></p>

    <p>Date of next hearing <span class="answer">{{ value.next_hearing | date('ll') }}</span></p>

    <p><b>ADDITIONAL INFORMATION:</b></p>

    <span class="answer">{{ value.additional_info }}</span>

  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    client: {
      type: Object,
      required: true
    }
  },

  computed: {
    age () {
      return moment().diff(moment(this.client.dob), 'years')
    }
  },

  methods: {
    formatYN (val) {
      return val === 'yes' ? 'Yes' : 'No'
    }
  }
}
</script>
