<template>
  <div class="page-form">
    <p class="text-center"><b>Client Profile Information Form</b></p>

    <div>
      <b>{{ client.userId.uuid }} - {{ client.firstName }} - {{ client.lastName }} - {{ client.nationality | country }} - {{ client.residence | country }}</b>
    </div>

    <identity
      v-model="form"
      :readonly="readonly" />

    <profile-update-documents
      v-model="form"
      :readonly="readonly"
      @add="newDocument()" />

    <country-of-residence
      v-model="form"
      :readonly="readonly"
      @add="newResidenceCountry()" />

    <education-info
      v-model="form"
      :readonly="readonly"
      @add="newEducation()" />

    <activity-info
      v-model="form"
      :readonly="readonly"
      @add="newActivityInfo()" />

    <addresses
      v-model="form"
      :readonly="readonly"
      @add="newAddress()" />

    <family-members-info
      v-model="form"
      :readonly="readonly"
      @add="newFamilyMember()" />
  </div>
</template>

<script>
import helperMixin from '../common/helpers-mixin'

import ExInput from '../common/Input'
import ExSelect from '../common/Select'
import Datepicker from 'vuejs-datepicker'
import Identity from './profile-update/Identity'
import Addresses from './profile-update/Addresses'
import ActivityInfo from './profile-update/ActivityInfo'
import EducationInfo from './profile-update/EducationInfo'
import FamilyMembersInfo from './profile-update/FamilyMembersInfo'
import CountryOfResidence from './profile-update/CountryOfResidence'
import ProfileUpdateDocuments from './profile-update/ProfileUpdateDocuments'

const documentsSample = {
  ICU: null,
  visa: null,
  issueDate: null,
  expirationDate: null,
  passport: {
    no: null,
    country: null,
    issueDate: null,
    expirationDate: null
  }
}

const countryOfResidenceSample = {
  country: null,
  status: null,
  from: null,
  to: null
}

const educationInfoSample = {
  name: null,
  diploma: null,
  studyField: null,
  country: null,
  city: null,
  from: null,
  to: null
}

const activityInfoSample = {
  activity: null,
  status: null,
  company: null,
  country: null,
  city: null,
  from: null,
  to: null
}

const addressSample = {
  country: null,
  state: null,
  city: null,
  address: null,
  zip: null,
  from: null,
  to: null
}

const familyMemberSample = {
  firstName: null,
  lastName: null,
  relationType: null,
  maritalStatus: null,
  birthPlace: null,
  dob: null,
  address: null,
  city: null,
  country: null,
  deathDate: null,
}

export default {
  props: {
    value: {
      type: Object,
      default: null
    },

    readonly: {
      type: Boolean,
      default: true
    },

    guestClient: {
      type: Object,
      default: null
    }
  },

  mixins: [ helperMixin ],

  components: {
    ExInput,
    Identity,
    ExSelect,
    Addresses,
    Datepicker,
    ActivityInfo,
    EducationInfo,
    FamilyMembersInfo,
    CountryOfResidence,
    ProfileUpdateDocuments
  },

  data () {
    return {
      form: {
        lastName: null,
        firstName: null,
        dob: null,
        city: null,
        country: null,
        height: null,
        eyeColor: null,
        partner: {
          lastName: null,
          firstName: null,
          marriageType: null,
          unionDate: null,
          dob: null,
          city: null,
          country: null
        },
        previousPartner: {
          lastName: null,
          firstName: null,
          marriageType: null,
          unionDate: null,
          separationDate: null,
          dob: null,
          city: null,
          country: null
        },

        documents: [],
        residenceCountries: [],
        educations: [],
        activities: [],
        addresses: [],
        familyMembers: []
      }
    }
  },

  computed: {
    client () {
      return this.$store.state.client?.single?.registration || this.guestClient
    }
  },

  created () {
    if (this.value) {
      this.form = this.value
    } else {
      this.newDocument()
      this.newResidenceCountry()
      this.newEducation()
      this.newActivityInfo()
      this.newAddress()
      this.newFamilyMember()
    }
  },

  watch: {
    form: {
      handler (form) {
        this.$emit('input', form)
      },
      deep: true
    }
  },

  methods: {
    newDocument () {
      this.form.documents.push(JSON.parse(JSON.stringify(documentsSample)))
    },

    newResidenceCountry () {
      this.form.residenceCountries.push(JSON.parse(JSON.stringify(countryOfResidenceSample)))
    },

    newEducation () {
      this.form.educations.push(JSON.parse(JSON.stringify(educationInfoSample)))
    },

    newActivityInfo () {
      this.form.activities.push(JSON.parse(JSON.stringify(activityInfoSample)))
    },

    newAddress () {
      this.form.addresses.push(JSON.parse(JSON.stringify(addressSample)))
    },

    newFamilyMember () {
      this.form.familyMembers.push(JSON.parse(JSON.stringify(familyMemberSample)))
    },
  }
}
</script>
