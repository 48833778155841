<template>
   <modal
    title="ITS Emeri File Setup"
    :width="1200"
    @close="$emit('close')">
    <table class="form-table">
      <tr class="heading">
        <td colspan="3">CASE-FILE AND CLIENT AGREEMENT SETUP</td>
      </tr>
      <tr>
        <td>Client ID: <b>{{ registration.id }}</b></td>
        <td>File Number: <b>{{ nextFileNumber }}</b></td>
        <td>Date Created: <b>{{ now | date('LL') }}</b></td>
      </tr>

      <tr class="heading">
        <td colspan="3">CLIENT INFORMATION</td>
      </tr>
      <tr>
        <td colspan="3">
          <b>Client 1</b><br>
          {{ registration.firstName }} - {{ registration.lastName }} – {{ registration.email }} – {{ registration.phone }} – {{ registration.otherPhone }}<br>
          {{ registration.address }} – {{ registration.city }} – {{ registration.state }} – {{ registration.postal }} – {{ registration.residence | country }}

          
          <div class="mt-20">
            <template v-if="form.client">
              <b>Client 2</b><br />
              <div class="form">
                <div class="col">
                  <ex-input v-model="form.client.firstName" label="First Name" no-margin full />
                </div>
                <div class="col">
                  <ex-input v-model="form.client.lastName" label="Last Name" no-margin full />
                </div>
                <div class="col">
                  <ex-input v-model="form.client.email" type="email" label="Email Address" no-margin full />
                </div>
                <div class="col">
                  <ex-input v-model="form.client.phone" label="Phone Number" no-margin full />
                </div>
                <div class="col">
                  <ex-input v-model="form.client.otherPhone" label="Other Phone Number" no-margin full />
                </div>

                <div class="col">
                  <ex-input v-model="form.client.address" label="Address" no-margin full />
                </div>
                <div class="col">
                  <ex-input v-model="form.client.city" label="City" no-margin full />
                </div>
                <div class="col">
                  <ex-input v-model="form.client.postal" label="Postal code" no-margin full />
                </div>
                <div class="col">
                  <ex-select v-model="selectedResidence" label="Country of Residence" :options="countryOptions" no-margin full />
                </div>
                <div class="col">
                  <ex-select v-model="selectedState" label="State/Province" :disabled="!stateOptions" :options="stateOptions" no-margin full />
                </div>
              </div>
            </template>
            <el-button v-else size="mini" icon="el-icon-plus" type="primary" @click="addClient()">Add Client 2</el-button>
          </div>
        </td>
      </tr>
    </table>

    <table class="form-table">
      <tr class="heading">
        <td colspan="2">FILE DETAILS</td>
      </tr>
      <tr>
        <td>
          Program: <ex-select v-model="form.program" :options="programOptions" class="width-300" full no-style />
        </td>
        <td>
          Category: <ex-select v-model="form.category" :options="categoryOptions" class="width-300" full no-style />
        </td>
      </tr>
    </table>

    <table class="form-table">
      <tr class="heading">
        <td colspan="3">FILE DUTIES</td>
      </tr>
      <tr>
        <td colspan="3">
          <ul>
            <li v-for="(duty, index) in form.file_duties" :key="'duty' + index" class="flex mb-5">
              <div class="mr-10">{{ getAlphabeticalOL(index + 1) }})</div>
              <ex-input v-model="form.file_duties[index]" full no-style />

              <el-button size="mini" icon="el-icon-close" type="danger" circle @click="form.file_duties.splice(index, 1)" />
            </li>
          </ul>

          <div class="mt-20"><el-button size="mini" icon="el-icon-plus" type="primary" @click="form.file_duties.push(null)">Add Duty</el-button></div>
        </td>
      </tr>

      <tr class="heading">
        <td colspan="3">BILLING OPTIONS</td>
      </tr>
      <tr>
        <td colspan="2">
          Client Billing Option <ex-select v-model="form.billing_option" :options="billingOptions" no-style />
        </td>
        <td>
          Hourly Rate <ex-input v-model="form.billing_hourly_rate" :disabled="form.billing_option !== 'hourly'" @blur="formatCurrency('billing_hourly_rate')" no-style />
        </td>
      </tr>

      <tr class="heading">
        <td colspan="3">BILLING DETAILS</td>
      </tr>
      <tr>
        <td colspan="3">
          <ul>
            <li v-for="(item, index) in form.billing_details" :key="'billing' + index" class="flex mb-5">
              <div class="mr-10">{{ getAlphabeticalOL(index + 1) }})</div>
              <ex-input v-model="form.billing_details[index]" full no-style />
              <el-button size="mini" icon="el-icon-close" type="danger" circle @click="form.billing_details.splice(index, 1)" />
            </li>
          </ul>

          <div class="mt-20"><el-button size="mini" icon="el-icon-plus" type="primary" @click="form.billing_details.push(null)">Add Detail</el-button></div>
        </td>
      </tr>

      <tr class="heading">
        <td colspan="3">PAYMENT TERMS</td>
      </tr>
      <tr>
        <td class="text-center"><b>Item</b></td>
        <td class="text-center"><b>Amount</b></td>
        <td class="text-center"><b>Note</b></td>
      </tr>
      <tr>
        <td>Initial Consultation Amount</td>
        <td>{{ form.initial_consultation.amount }}</td>
        <td><ex-input v-model="form.initial_consultation.note" full no-style /></td>
      </tr>
      <tr>
        <td>Professional Fees</td>
        <td><ex-input v-model="form.professional_fees.amount" @blur="form.professional_fees.amount = getFormattedCurrency(form.professional_fees.amount)" no-style full /></td>
        <td><ex-input v-model="form.professional_fees.note" full no-style /></td>
      </tr>
      <tr>
        <td>Administration Fee</td>
        <td><ex-input v-model="form.administration_fee.amount" @blur="form.administration_fee.amount = getFormattedCurrency(form.administration_fee.amount)" no-style full /></td>
        <td><ex-input v-model="form.administration_fee.note" full no-style /></td>
      </tr>
      <tr>
        <td><b>Items Total</b></td>
        <td>{{ getFormattedCurrency(itemsTotal) }}</td>
      </tr>
      <tr>
        <td colspan="3" class="text-center"><b>TAXES</b></td>
      </tr>
      <tr>
        <td>Federal Tax - <b>{{ company.federalTaxNumber }}</b></td>
        <td><ex-input v-model="form.federal_tax.amount" @blur="form.federal_tax.amount = getFormattedCurrency(form.federal_tax.amount)" no-style full /></td>
        <td><ex-input v-model="form.federal_tax.note" full no-style /></td>
      </tr>
      <tr>
        <td>Provincial Tax - <b>{{ company.provincialTaxNumber }}</b></td>
        <td><ex-input v-model="form.provincial_tax.amount" @blur="form.provincial_tax.amount = getFormattedCurrency(form.provincial_tax.amount)" no-style full /></td>
        <td><ex-input v-model="form.provincial_tax.note" full no-style /></td>
      </tr>
      <tr>
        <td><b>Taxes Total</b></td>
        <td>{{ getFormattedCurrency(taxesTotal) }}</td>
      </tr>
      <tr>
        <td><b>Sub-Total</b></td>
        <td>{{ getFormattedCurrency(subTotal) }}</td>
      </tr>
      <tr>
        <td colspan="3" class="text-center"><b>DISBURSEMENTS</b> <el-button size="mini" icon="el-icon-plus" type="primary" @click="addDisbursement()">Add Disbursement</el-button></td>
      </tr>
      <tr v-for="(disbursement, index) in form.disbursements" :key="index">
        <td>{{ getAlphabeticalOL(index + 1) }}) Disbursement: <ex-input v-model="form.disbursements[index].name" no-style /></td>
        <td><ex-input v-model="form.disbursements[index].amount" @blur="form.disbursements[index].amount = getFormattedCurrency(form.disbursements[index].amount)" no-style full /></td>
        <td>
          <div class="flex">
            <ex-input v-model="form.disbursements[index].note" full no-style />
            <el-button size="mini" icon="el-icon-close" type="danger" circle @click="form.disbursements.splice(index, 1)" />
          </div>
        </td>
      </tr>
      <tr>
        <td><b>Disbursements Total</b></td>
        <td>{{ getFormattedCurrency(disbursementsTotal) }}</td>
        <td />
      </tr>
      <tr>
        <td colspan="3"><b>TOTALS</b></td>
      </tr>
      <tr>
        <td><b>File Total</b></td>
        <td>{{ getFormattedCurrency(total) }}</td>
        <td><ex-input v-model="form.total_note" full no-style /></td>
      </tr>

      <tr class="heading">
        <td colspan="3">PAYMENT SCHEDULE</td>
      </tr>
      <tr>
        <td class="text-center"><b>Service Milestone</b></td>
        <td class="text-center"><b>Estimated Date of Completion</b></td>
        <td class="text-center"><b>Payment Due</b></td>
      </tr>
      <tr v-for="(milestone, index) in form.milestones" :key="`milestone_${index}`">
        <td><ex-input v-model="form.milestones[index].name" label="Service Milestone" full no-style /></td>
        <td><datepicker v-model="form.milestones[index].date" calendar-button calendar-button-icon="el-icon-date" format="d MMM yyyy" placeholder="Estimated Date of Completion" /></td>
        <td>
          <div class="flex">
            <ex-input v-model="form.milestones[index].payment_due" label="Payment Due" @blur="form.milestones[index].payment_due = getFormattedCurrency(form.milestones[index].payment_due)" full no-style />
            <el-button size="mini" icon="el-icon-close" type="danger" circle @click="form.milestones.splice(index, 1)" />  
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="2"><b>Total</b></td>
        <td>{{ getFormattedCurrency(paymentScheduleTotal) }}</td>
      </tr>
      <tr>
        <td colspan="3">
          <el-button size="mini" icon="el-icon-plus" type="primary" @click="addMilestone()">Add Milestone</el-button>
        </td>
      </tr>
      
      <tr class="heading">
        <td colspan="3">FILE TRACKER</td>
      </tr>
    </table>
    <tracker ref="tracker" type="ClientFileSetup" :id="data ? data.id : null" />

    <div class="buttons">
      <el-button icon="el-icon-check" type="primary" @click="save()">Submit</el-button>
      <el-button @click="$emit('close')">Cancel</el-button>
    </div>
   </modal>
</template>

<script>
import numeral from 'numeral'
import { mapActions } from 'vuex'
import helperMixin from '../../common/helpers-mixin'

import Modal from '../../common/Modal'
import ExInput from '../../common/Input'
import Datepicker from 'vuejs-datepicker'
import ExSelect from '../../common/Select'

export default {
  mixins: [helperMixin],

  props: {
    data: {
      type: Object,
      default: null
    },

    nextId: {
      type: Number,
      required: true
    }
  },

  components: {
    Modal,
    ExInput,
    ExSelect,
    Datepicker
  },

  data () {
    return {
      form: {
        program: null,
        category: null,
        file_duties: [null],
        billing_details: ['Payment at office', 'Interac Transfer - info@itsimmigration.ca'],
        billing_option: null,
        billing_hourly_rate: null,

        initial_consultation: {
          amount: null,
          note: null
        },
        professional_fees: {
          amount: null,
          note: null
        },
        administration_fee: {
          amount: null,
          note: null
        },
        federal_tax: {
          amount: null,
          note: null
        },
        provincial_tax: {
          amount: null,
          note: null
        },

        disbursements: [{
          name: null,
          amount: null,
          note: null
        }],

        milestones: [],

        client: null,

        total_note: null
      },

      selectedState: null,
      selectedResidence: null,

      billingOptions: [
        {
          id: 'flat_fee',
          text: 'Flat-Fee by Milestones'
        },
        {
          id: 'hourly',
          text: 'Hourly'
        }
      ],
      now: moment()
    }
  },

  computed: {
    nextFileNumber () {
      return numeral(this.nextId).format('000')
    },

    subTotal () {
      return this.itemsTotal + this.taxesTotal
    },

    itemsTotal () {
      const values = [this.form.initial_consultation.amount, this.form.professional_fees.amount, this.form.administration_fee.amount]
      return values
        .filter(amount => amount)
        .map(amount => parseFloat(numeral(amount).format('0.00')))
        .reduce((total, amount) => total + parseFloat(amount), 0)
    },

    taxesTotal () {
      const values = [this.form.federal_tax.amount, this.form.provincial_tax.amount]
      return values
        .filter(amount => amount)
        .map(amount => parseFloat(numeral(amount).format('0.00')))
        .reduce((total, amount) => total + parseFloat(amount), 0)
    },

    disbursementsTotal () {
      return this.form.disbursements
        .filter(item => item.amount)
        .map(item => parseFloat(numeral(item.amount).format('0.00')))
        .reduce((total, amount) => total + parseFloat(amount), 0)
    },

    paymentScheduleTotal () {
      return this.form.milestones
        .filter(amount => amount.payment_due)
        .map(milestone => parseFloat(numeral(milestone.payment_due).format('0.00')))
        .reduce((total, amount) => total + parseFloat(amount), 0)
    },

    total () {
      return this.subTotal + this.disbursementsTotal
    },

    agreement () {
      return this.registration['ClientConsultationAgreement']
    },

    programOptions () {
      return Object.keys(window.programOptions).map(key => ({
        id: key,
        text: window.programOptions[key].text
      }))
    },

    categoryOptions () {
      if (!this.form.program) return null
      return Object.keys(window.programOptions[this.form.program].items).map(key => ({
        id: key,
        text: window.programOptions[this.form.program].items[key]
      }))
    },

    registration () {
      return this.$store.state.client.single.registration
    },

    stateOptions () {
      return (['CA', 'US'].includes(this.form.client.residence)) ? window.states[this.form.client.residence].map(state => {
        return {
          id: state.id,
          text: `${state.id} - ${state.text}`
        }
      }) : null
    },

    company () {
      return window.appData.company
    }
  },

  watch: {
    selectedState (value) {
      if (value) {
        this.form.client.state = this.getInitials(value)
      }
    },

    selectedResidence (value) {
      if (!this.form.client) return
      if (value) {
        this.form.client.residence = this.getInitials(value)
        this.selectedState = null
        this.form.client.state = null
      } else {
        this.form.client.residence = null
      }
    }
  },

  async created () {
    if (this.data) {
      this.form = JSON.parse(JSON.stringify(this.data.data))
      this.selectedResidence = this.form.client?.residence
      this.selectedState = this.form.client?.state
    } else {
      this.form.initial_consultation.amount = this.agreement?.fee
    }
  },

  methods: {
    ...mapActions({
      create: 'client/fileSetup/create',
      update: 'client/fileSetup/update'
    }),

    async save () {
      const payload = {
        data: {
          ...this.form,
          total_amount: this.getFormattedCurrency(this.total),
          sub_total: this.getFormattedCurrency(this.subTotal),
          items_total: this.getFormattedCurrency(this.itemsTotal),
          taxes_total: this.getFormattedCurrency(this.taxesTotal),
          disbursments_total: this.getFormattedCurrency(this.disbursementsTotal),
          payment_schedule_total: this.getFormattedCurrency(this.paymentScheduleTotal),
        }
      }
      let data = null
      if (this.data) {
        data = await this.update({ id: this.data.id, payload })
      } else {
        payload.clientRegId = this.registration.id
        payload.createdAt = 'CURRENT_TIMESTAMP'
        payload.customId = 'CLIENTFILES_CUSTOM_ID'
        payload.isSetup = false
        payload.createdBy = window.appData.currentUser.id

        data = await this.create(payload)
        this.$emit('add')
      }
      await this.$refs.tracker.save(data.id)
      this.updateStore(data)
      this.$message.success('ITS Emeri File Setup submitted successfully.')
      this.$emit('close')
    },

    updateStore (data) {
      this.$store.commit('client/single/setData', { key: 'ClientFileSetup', data })
    },

    addClient () {
      this.form.client = {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        otherPhone: null,
        address: null,
        city: null,
        state: null,
        postal: null,
        residence: null
      }
    },

    addMilestone () {
      this.form.milestones.push({
        name: null,
        date: null,
        payment_due: null
      })
    },

    addDisbursement () {
      this.form.disbursements.push({
        name: null,
        amount: null,
        note: null
      })
    },

    formatCurrency (key) {
      this.form[key] = numeral(this.form[key]).format('$0.00')
    },

    getFormattedCurrency (amount) {
      return numeral(amount).format('$0.00')
    },

    getInitials (value) {
      if (!value) return null
      return value.split('').splice(0, 2).join('')
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.form {
  padding: 0;
  .col {
    width: calc(20% - 16px);
    margin-bottom: 10px;
  }
  .form-group {
    margin: 0;
  }
}
.date-form-group {
  display: flex;
}
.width-300 {
  max-width: 300px;
}
</style>