export default {
  registration: {
    language: 'Langage',
    client_registration: 'Registre de Clients',
    client_general_information: 'Informations générales sur les clients',
    first_name: 'Prénom',
    enter_first_name: 'Entrez votre prénom',
    date_of_birth: 'Date de Naissance',
    date_dd: 'JJ',
    date_mm: 'MM',
    date_yy: 'AA',
    country_of_nationality: 'Pays de nationalité',
    select_country_of_nationality: 'Sélectionnez le pays',
    last_name: 'Nom de famille',
    enter_last_name: 'Entre le nom de famille',
    email_address: 'Adresse e-mail',
    enter_email_address: 'Entrez l\'adresse e-mail',
    country_of_residence: 'Pays de résidence',
    select_country_of_residence: 'Sélectionnez le pays',
    client_address_information: 'Informations sur les coordonnées du client',
    address: 'Adresse',
    enter_address: 'Entrez l\'adresse',
    state_province: 'État/Province',
    select_state_province: 'Sélectionnez État/Province',
    phone_number: 'Numéro de téléphone',
    enter_phone_number: 'Entrez le numéro de téléphone',
    city: 'Ville',
    enter_city: 'Entrez la ville',
    postal_code: 'Code postal',
    enter_postal_code: 'Entrez le code postal',
    other_phone_number: 'Entrez un autre numéro de téléphone',
    client_service_requested: 'Service Client Demandé',
    service: 'Service',
    select_service: 'Sélectionnez le service ',
    do_you_agree_to_our: 'Êtes-vous d\'accord avec notre',
    termes_conditions: 'Termes et conditions',
    privacy: 'Confidentialité',
    submit_registration: 'S\'enregistrer',
    user_policy: 'Politique de l\'utilisateur',
    already_a_user_login_to_get_started: 'Êtes-vous déjà un utilisateur? Connectez-vous pour commencer',
    trouble_logging_in_please_contact_info_itsimmigration_ca: 'Problèmes de connexion? Veuillez contacter: info@itsimmigration.ca',
    use_of_this_site_is_restricted_for_the_use_of_its_immigration_clients_associates: 'L\'utilisation de ce site est réservée à l\'usage des Clients et Associés d\'ITS Immigration'
  },

  services: {
    consultation: 'Consultation ',
    permanent_residence: 'Résidence permanente',
    temporary_residence: 'Résidence temporaire',
    sponsorship: 'Parrainage',
    citizenship_application: 'Demande de citoyenneté',
    document_replacement_extension: 'Remplacement et extension de documents',
    travel_documents: 'Documents de voyage',
    legal_advic: 'Conseils juridiques'
  }
}
