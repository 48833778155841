<template>
<form class="form add-task-form">
  <div class="inline-form-group">
    <div class="form-group">
      <label>Date:</label>
      <datepicker
        v-model="form.date"
        :class="{ error: $v.form.date.$dirty && $v.form.date.$invalid }"
        calendar-button
        calendar-button-icon="el-icon-date"
        maxlength="100"
        format="yyyy MMM d"
        :open-date="new Date()"
        :disabled="readonly"
        :disabledDates="disabledDates"/>
    </div>
    <div class="form-group">
      <label>Time:</label>
      <ex-select v-model="form.hours" :options="hourOptions" :validator="$v.form.hours" :disabled="readonly" no-style full />
      
      <ex-select v-model="form.minutes" :options="minutesOptions" :validator="$v.form.minutes" :disabled="readonly" no-style full />
      
      <ex-select v-model="form.am_pm" :options="amPmOptions" :validator="$v.form.am_pm" :disabled="readonly" no-style full />
    </div>
  </div>
  
  <tag-employees ref="tagEmployees" v-if="isScheduler" v-model="selectedEmployees" />

  <div class="form-group task-description">
    <label>Task:</label>
    <ex-textarea
      v-model="form.text"
      maxlength="300"
      placeholder="Enter task description"
      :class="{ error: $v.form.text.$dirty && $v.form.text.$invalid }"
      full
      no-margin />
  </div>
</form>
</template>

<script>
import TagEmployees from '../calendar/TagEmployees'
import Datepicker from 'vuejs-datepicker'
import ExInput from '../common/Input'
import ExSelect from '../common/Select'
import ExTextarea from '../common/Textarea'

import { required } from 'vuelidate/lib/validators'

export default {
  name: 'addTask',

  components: {
    Datepicker,
    TagEmployees,
    ExInput,
    ExSelect,
    ExTextarea
  },

  props: {
    type: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      form: {},
      hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      timeOptions: ['00', 15, 30, 45],
      amPm: ['AM', 'PM'],
      selectedEmployees: [],
      disabledDates: {
        to: new Date(Date.now() - 8640000)
      }
    }
  },

  computed: {
    isScheduler () {
      return this.type === 'SCHEDULER'
    },

    hourOptions () {
      return this.hours.map(hour => {
        return {
          id: hour,
          text: hour
        }
      })
    },

    minutesOptions () {
      return this.timeOptions.map(time => {
        return {
          id: time,
          text: time
        }
      })
    },

    amPmOptions () {
      return this.amPm.map(amPm => {
        return {
          id: amPm,
          text: amPm
        }
      })
    }
  },

  created () {
    this.clear()
  },

  methods: {
    async save () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$message.error('Please fill all required fields')
        return
      }
      const loading = this.$loading()
      const formDate = this.form.date.toISOString().slice(0,10);
      try {
        const payload = {
          text: this.form.text,
          date: moment(`${formDate} ${this.form.hours}:${this.form.minutes} ${this.form.am_pm}`, 'YYYY-MM-DD h:mm A').format('YYYY-MM-DD HH:mm:ss'),
          created: 'CURRENT_TIMESTAMP',
          createdBy: appData.currentUser.id,
          type: this.type
        }
        
        const task = (await api.create("TaskList", payload, true)).item

        if (this.selectedEmployees.length) {
          const allEmployees = this.$refs.tagEmployees.employees
          const props = this.selectedEmployees.map(id => {
            return { user_id: id }
          })
          await api.update("TaskList", task.id, { employees: props })
        }

        this.$emit('save', task)
        this.clear()
        this.$v.form.$reset()
        loading.close()
        this.$message.success('Task added successfully.')
      } catch (e) {
        loading.close()
      }
    },

    clear () {
      this.form = {
        text: null,
        date: null,
        hours: null,
        minutes: null,
        am_pm: null
      }
      this.selectedEmployees = []
    }
  },

  validations () {
    const rules = {
      form: {
        date: { required },
        text: { required },
        hours: { required },
        minutes: { required },
        am_pm: { required }
      }
    }
    return rules
  }
}
</script>

<style lang="scss" scoped>
  .add-task-form {
    display: flex;
    padding: 35px 0;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    .inline-form-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .form-group {
        display: flex;
        flex-wrap: wrap;
        width: auto !important;
        margin-right: 5px; 
        label {
          width: 100%;
        }
        .inline-block {
          margin-right: 10px; 
        }
      }
    }
    .task-description {
      width: 100% !important; 
      textarea {
        display: block;
        width: 100%;
        resize: vertical;
        box-sizing: border-box;
        min-height: 100px;
        padding: 6px 10px;
        border: 1px solid #d6d6d6;
        margin: 6px 0 16px;
        border-radius: 3px;
        &.error {
          border: 1px solid #d23131;
        }
      }
    }
  }
</style>
