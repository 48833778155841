import $ from 'jquery';
import Vue from 'vue';
import store from '../store'
import messages from '../i18n'
import VueI18n from 'vue-i18n'


Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en',
  messages
})


$(function($) {
  window.vueMixins=window.vueMixins || {}
  $("[vue-dyn]").each(function() {
    var $this=$(this);
    var mixins=$this.attr("vue-dyn").split(",").filter(function(a) {
      return a;
    }).map(function(a) {
      return window.vueMixins[a];
    });
    $this.removeAttr("vue-dyn");

    (new Vue({
      el: this,
      i18n,
      store,
      mixins: mixins
    }));
  });
});
