<template>
  <layout :active="active">
    <page-info
      :title="profiles ? 'Client Profiles' : 'Client Registrations'"
      :filters="appliedFilters"
      :filters-map="!!profiles ? filtersMap : null"
      @apply="applyFilters" />

    <div class="table-wrapper">
      <el-table :data="data" style="min-width: 100%" stripe>
        <el-table-column v-if="!profiles" label="Actions" width="100">
          <template slot-scope="scope">
            <el-button :disabled="!scope.row.isSetupDone" size="mini" :type="scope.row.isSetupDone ? 'success' : 'info'" icon="el-icon-check" circle @click="redirect(scope.row)" />
            <el-button size="mini" type="danger" icon="el-icon-close" circle @click="askDelete(scope.row.id)" />
          </template>
        </el-table-column>

        <el-table-column label="Status">
          <template slot-scope="scope">
            <el-tag effect="dark" :type="clientStatusColors[scope.row.status] || 'primary'" size="mini">{{ !!profiles ? clientStatuses[scope.row.status] : scope.row.status }}</el-tag>
          </template>
        </el-table-column>

        <template v-if="!profiles">
          <el-table-column label="Registration ID">
            <template slot-scope="scope">
              <a :href="`/clients/registrations/${scope.row.id}`" class="link">
                <b>{{ scope.row.id }}</b>
              </a>
            </template>
          </el-table-column>

          <el-table-column label="Registration Date">
            <template slot-scope="scope">{{ scope.row.createdAt | date('LL') }}</template>
          </el-table-column>
        </template>

        <template v-else>
          <el-table-column label="Client ID">
            <template slot-scope="scope">
              <a :href="`/clients/registrations/${scope.row.id}`" class="link">
                <b>
                {{ scope.row.userId.uuid }}
                </b>
              </a>
            </template>
          </el-table-column>
        </template>

        <el-table-column label="Contact First Name">
          <template slot-scope="scope">
            {{ scope.row.firstName }}
          </template>
        </el-table-column>

        <el-table-column label="Contact Last Name">
          <template slot-scope="scope">
            {{ scope.row.lastName }}
          </template>
        </el-table-column>

        <el-table-column label="Phone">
          <template slot-scope="scope">
            {{ scope.row.phone }}
          </template>
        </el-table-column>

        <el-table-column label="Email">
          <template slot-scope="scope">
            {{ scope.row.email }}
          </template>
        </el-table-column>

        <el-table-column label="Deadline">
          <template slot-scope="scope">
            <template v-if="scope.row.deadline">
              {{ scope.row.deadline | date('LL') }}
            </template>
            <template v-else>N/A</template>
          </template>
        </el-table-column>

        <el-table-column label="Service Requested">
          <template slot-scope="scope">
            {{ scope.row.service | service }}
          </template>
        </el-table-column>

        <template v-if="profiles">
          <el-table-column label="Options">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="startEdit(scope.row)">Update</el-button>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>

    <modal
      v-if="editing"
      title="Update Client Profile"
      :width="400"
      class="edit-modal">
      <label>Status</label>
      <ex-select
        v-model="editing.status"
        label="Status"
        :validator="$v.editing.status"
        :options="clientStatusesOptions"
        class="m-0"
        full noStyle />

      <label class="mt-20">Deadline</label>
      <datepicker
        v-model="editing.deadline"
        calendar-button
        calendar-button-icon="el-icon-date"
        maxlength="100"
        format="yyyy MMM d"
        :disabledDates="disabledDates"
      />

      <div class="buttons">
        <el-button type="secondary" :disabled="saving" @click="cancelEdit()">Cancel</el-button>
        <el-button type="success" :loading="saving" @click="saveEdit()">Save</el-button>
      </div>
    </modal>
  </layout>
</template>

<script>
import { mapActions } from 'vuex'
import helpersMixin from '../common/helpers-mixin'
import { required } from 'vuelidate/lib/validators'

import Panel from '../common/Panel'
import Modal from '../common/Modal'
import Layout from '../common/Layout'
import ExSelect from '../common/Select'
import PageInfo from '../common/PageInfo'
import Datepicker from 'vuejs-datepicker'

export default {
  name: 'client-registrations',

  mixins: [helpersMixin],

  props: {
    profiles: {
      type: String,
      default: null
    }
  },

  components: {
    Panel,
    Modal,
    Layout,
    ExSelect,
    PageInfo,
    Datepicker
},

  data () {
    return {
      // Make default search when open page to show last 30 days of Setup date
      appliedFilters: {},

      editing: null,
      saving: false,
      clientStatuses: window.clientStatuses,
      disabledDates: {
        to: new Date(Date.now() - 8640000)
      },
      clientStatusColors: window.clientStatusColors
    }
  },

  computed: {
    filtersMap () {
      return [
        {
          key: 'status',
          label: 'Status',
          type: 'select',
          options: this.clientStatusesOptions,
          multiple: true
        },
        {
          key: 'clientId',
          label: 'Client ID',
          type: 'input',
        },
        {
          key: 'firstName',
          label: 'First Name',
          type: 'input',
        },
        {
          key: 'lastName',
          label: 'Last Name',
          type: 'input',
        },
        {
          key: 'phone',
          label: 'Phone',
          type: 'input',
        },
        {
          key: 'email',
          label: 'Email',
          type: 'input',
        },
        {
          key: 'deadline',
          label: 'Deadline',
          type: 'range_datepicker',
        },
        {
          key: 'service',
          label: 'Service requested',
          type: 'select',
          options: this.serviceOptions
        }
      ]
    },

    clientStatusesOptions () {
      return Object.keys(window.clientStatuses).map(key => ({
        id: key,
        text: window.clientStatuses[key]
      }))
    },

    active () {
      return this.profiles ? '/clients/profiles' : '/clients/registrations'
    },

    data () {
      const regs = this.$store.state.client.registrations || []
      return !this.profiles ? regs.filter(user => user.status === 'REG') : regs.filter(user => user.userId && user.status !== 'REG')
    },

    payload () {
      const params = {}

      if (this.appliedFilters?.status) {
        params.status = this.appliedFilters.status
      }

      if (this.appliedFilters?.clientId) {
        params['userId.uuid'] = ['like', `%${this.appliedFilters.clientId}%`]
      }

      if (this.appliedFilters?.firstName) {
        params.firstName = ['like', `%${this.appliedFilters.firstName}%`]
      }

      if (this.appliedFilters?.lastName) {
        params.lastName = ['like', `%${this.appliedFilters.lastName}%`]
      }

      if (this.appliedFilters?.phone) {
        params.phone = ['like', `%${this.appliedFilters.phone}%`]
      }

      if (this.appliedFilters?.email) {
        params.email = ['like', `%${this.appliedFilters.email}%`]
      }

      if (this.appliedFilters?.deadline?.from || this.appliedFilters?.deadline?.to) {
        if (this.appliedFilters?.deadline?.from) {
          params.createdAtAfter = ['>=', moment(this.appliedFilters?.deadline?.from).format('YYYY-MM-DD') + " 00:00:00", "deadline"]
        }
        
        if (this.appliedFilters?.deadline?.to) {
          params.createdAtBefore = ['<=', moment(this.appliedFilters?.deadline?.to).format('YYYY-MM-DD') + " 23:59:59", "deadline"]
        }
      }

      if (this.appliedFilters?.service) {
        params.service = this.appliedFilters.service
      }

      if (!Object.keys(params).length && this.profiles) {
        params.status = ["IN('Active', 'New')"];
      }

      return params
    }
  },  

  created () {
    this.load()
  },

  methods: {
    ...mapActions({
      update: 'client/single/update',
      getRegistrations: 'client/list',
      removeRegistration: 'client/remove'
    }),

    async load () {
      await this.getRegistrations({ params: this.payload, force: true })
    },

    applyFilters (filters) {
      this.appliedFilters = filters
      this.load()
    },

    startEdit (item) {
      this.editing = {
        id: item.id,
        status: item.status,
        deadline: item.deadline ? new Date(item.deadline) : null
      }
    },

    cancelEdit () {
      this.editing = null
    },

    async saveEdit () {
      this.$v.editing.$touch()
      if (this.$v.editing.$invalid) return

      this.saving = true
      try {
        await this.update({ id: this.editing.id, payload: {
          status: this.editing.status,
          deadline: moment(this.editing.deadline).format('YYYY-MM-DD')
        }})
        await this.getRegistrations(true)
        this.cancelEdit()
        this.$message.success('Profile updated.');
      } catch (e) {
        console.error(e.message);
        this.$message.error('Something went wrong');
      } finally {
        this.saving = false
      }
    },

    async askDelete (id) {
      try {
        await this.$confirm(`Are you sure to delete Client Registration #${id}?`, 'Warning', {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          type: 'error'
        })

        await this.removeRegistration(id)
        this.$message.success('Registration deleted successfully.')
      } catch {}
    },

    redirect (data) {
      if (data.isSetupDone) {
        location.href = `/clients/registrations/${data.id}?clientSetup=true`
      }
    }
  },

  validations: {
    editing: {
      status: { required }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-title {
  margin: 0;
}
.edit-modal {
  label {
    display: block;
    margin-bottom: 10px;
  }

  .buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>