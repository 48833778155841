import Vue from 'vue'
import Vuex from 'vuex'

import users from './users'
import client from './client'
import invoices from './invoices'
import system from './system'
import projects from './projects'
import tracker from './tracker'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    users,
    client,
    system,
    invoices,
    tracker,
    projects
  }
})
