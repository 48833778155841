<template>
  <nav :class="{ expanded }">
    <div class="overflow">
      <a href="/">
        <div class="logo">
          <img class="extended" :src="`/files/system/${company.logo}`">
          <img class="small" :src="`/files/system/${company.systemIcon}`">
        </div>
      </a>

      <div class="hamgurger">
        <a @click="expanded = !expanded">
          <img src="/assets/images/bars-1.png">
        </a>
      </div>

      <ul>
        <template v-for="(item, index) in menu">
          <li
            v-if="!isAdvHidden || !item.advanced"
            :key="item.name + index"
            :class="{ active: isActive(item), open: item.open }">
            <a v-if="item.link" :href="item.link" class="item">
              <div class="main">
                <i class="icon" :class="item.icon" />
                <span>{{ item.name }}</span>
              </div>
            </a>
            <template v-else>
              <div class="item" @click="toggleMenu(item)">
                <div class="main">
                  <i class="icon" :class="item.icon" />
                  <span>{{ item.name }}</span>
                </div>
              </div>
              <div v-show="item.open && expanded" class="dropdown">
                <template v-for="(subitem, idx) in item.items">
                  <a
                    v-if="subitem.view !== false && (!isAdvHidden || !subitem.advanced)"
                    :key="subitem.link + idx"
                    :href="subitem.link"
                    class="dropdown-item"
                    :class="{ active: active === subitem.link }">
                    {{ subitem.name }}
                  </a>
                </template>
              </div>
            </template>
          </li>
        </template>
      </ul>
    </div>
    <div class="copy">
      <div class="text-center">
        <p><a href="/privacy" target="_blank">Privacy Policy</a></p>            
      </div>

      <p class="copyright-side text-center">
        Copyright &copy; {{ year }} Plugg & Co.<br>All rights reserved.
      </p>

      <div class="flex">
        <p>1.0.3</p>
        <p><a href="#">Manage</a></p>
      </div>

      <div class="text-center">
        <img src="/assets/images/footer-logo-1.png">
      </div>
    </div>
  </nav>
</template>

<script>
import helperMixin from './helpers-mixin'

export default {
  name: 'Sidebar',

  mixins: [helperMixin],

  props: {
    value: {
      type: Boolean,
      required: true
    },

    active: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      year: (new Date()).getFullYear(),
      menu: [],
      company: window.appData.company,
      customEnv: window.appData.customEnv,
    }
  },

  computed: {
    isAdvHidden() {
      return this.customEnv.noadvmenu || false;
    },

    expanded: {
      get () {
        return this.value
      },
      set (value) {
        if (!value) this.menu.forEach(item => item.open = false)
        this.$emit('input', value)
      }
    }
  },

  watch: {
    isUserAdmin (value) {
      this.setMenu()
    }
  },

  created () {
    this.setMenu()
  },

  methods: {
    setMenu () {
      this.menu = [
        {
          icon: 'dashboard',
          name: 'Dashboard',
          link: '/dashboard'
        },
        {
          icon: 'tools',
          name: 'Tools',
          open: false,
          items: [
            {
              name: 'Scheduler',
              link: '/tools/scheduler',
              condition: this.isUserAdmin || this.user.isEmployee
            },
            {
              name: 'Invoicing',
              link: '/tools/invoicing',
              condition: this.isUserAdmin || this.user.isEmployee
            }
          ],
        },
        {
          icon: 'profile',
          name: 'Support',
          advanced: true,
          open: false,
          items: [
            {
              name: 'Help-Desk',
              link: '/',
            },
            {
              name: 'Documentation',
              link: '/',
            }
          ],
        },
        {
          icon: 'client',
          name: 'Clients',
          open: false,
          items: [
            {
              name: 'Client Registrations',
              link: '/clients/registrations',
            },
            {
              name: 'Client Profiles',
              link: '/clients/profiles',
            },
            {
              name: 'Client Files',
              link: '/',
              advanced: true,
            }
          ],
        },
        {
          icon: 'settings',
          name: 'Settings',
          open: false,
          items: [
            {
              name: 'Document Depot',
              link: '/system/document-depot',
              advanced: true,
            },
            {
              name: 'Agents',
              link: '/system/agents',
              advanced: true,
            },
            {
              name: 'Employees',
              link: '/system/employees',
            },
            {
              name: 'Users',
              link: '/system/users',
            }
          ],
        },        
        {
          icon: 'system',
          name: 'System',
          open: false,
          items: [
            {
              name: 'System Status',
              link: '/',
              advanced: true,
            },
            {
              name: 'System Branding',
              link: '/system/branding',
            },
            {
              name: 'Change Log',
              link: '/system/changelog',
            }
          ],
        }        
      ].filter(item => item.condition !== false).map(item => ({
        ...item,
        items: item.items?.filter(subitem => subitem.condition !== false)
      }))

      if (this.active) {
        this.menu.filter(item => item.items).forEach(item => {
          if (item.items.some(i => i.link === this.active)) this.toggleMenu(item)
        })
      }
    },

    isActive (item) {
      if (item.items) {
        return item.items.some(i => i.link === this.active)
      }
      return this.active === item.link
    },

    toggleMenu (item) {
      if (!this.expanded) this.expanded = true
      item.open = !item.open
    }
  }
}
</script>

<style lang="scss">
$widthm: calc(100% - 8px);
#app>nav {
  position: fixed;
  left: 0;
  top: 0;
  width: 65px;
  height: 100vh;
  background-color: #e7e7e7;
  transition: all ease-in-out 0.2s;
  z-index: 9;
  display: flex;
  flex-direction: column;
  .overflow {
    width: calc(100% + 8px);
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  ul {
    li {
      display: block;
      width: $widthm;
      min-height: 50px;
      transition: all ease-in-out .1s;
      position: relative;
      .item {
        display: block;
        width: 100%;
        height: 50px;
        position: relative;
        text-decoration: none;
        cursor: pointer;
        .main {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
        }
        .dropdown {
          display: block;
        }
        .icon {
          display: inline-block;
          vertical-align: top;
          width: 60px;
          height: 50px;
          background-position: center;
          background-repeat: no-repeat;
          &.dashboard {
            background-image: url(/assets/images/sidebar/dashboard.png);
          }
          &.calc {
            background-image: url(/assets/images/sidebar/calc.png);
          }
          &.client {
            background-image: url(/assets/images/sidebar/client.png);
          }
          &.clock {
            background-image: url(/assets/images/sidebar/clock.png);
          }
          &.cubes {
            background-image: url(/assets/images/sidebar/cubes.png);
          }
          &.profile {
            background-image: url(/assets/images/sidebar/profile.png);
          }
          &.settings {
            background-image: url(/assets/images/sidebar/settings.png);
          }
          &.store {
            background-image: url(/assets/images/sidebar/store.png);
          }
          &.thumbsup {
            background-image: url(/assets/images/sidebar/thumbsup.png);
          }
          &.tools {
            background-image: url(/assets/images/sidebar/tools.png);
          }
          &.users {
            background-image: url(/assets/images/sidebar/users.png);
          }
          &.system {
            background-image: url(/assets/images/sidebar/system-icon.png);
          }
        }
        span {
          display: none;
          color: #383838;
          font-size: 16px;
          font-weight: 400;
        }
      }
      &:hover, &.open {
        background-color: #000;
        span {
          color:#fff;
        }
        .icon {
          &.dashboard {
            background-image: url(/assets/images/sidebar/dashboard-2.png);
          }
          &.calc {
            background-image: url(/assets/images/sidebar/calc-2.png);
          }
          &.client {
            background-image: url(/assets/images/sidebar/client-2.png);
          }
          &.clock {
            background-image: url(/assets/images/sidebar/clock-2.png);
          }
          &.cubes {
            background-image: url(/assets/images/sidebar/cubes-2.png);
          }
          &.profile {
            background-image: url(/assets/images/sidebar/profile-2.png);
          }
          &.settings {
            background-image: url(/assets/images/sidebar/settings-2.png);
          }
          &.store {
            background-image: url(/assets/images/sidebar/store-2.png);
          }
          &.thumbsup {
            background-image: url(/assets/images/sidebar/thumbsup-2.png);
          }
          &.tools {
            background-image: url(/assets/images/sidebar/tools-2.png);
          }
          &.users {
            background-image: url(/assets/images/sidebar/users-2.png);
          }
          &.system {
            background-image: url(/assets/images/sidebar/system-icon-2.png);
          }
        }
      }
      &.active {
        span {
          color: #383838;
        }
        &::after {
          content: '';
          width: calc(100% + 8px);
          height: 50px;
          background-image: url(/assets/images/menu-item-bg.png);
          background-repeat: no-repeat;
          background-position: right;
          position: absolute;
          top: 0;
          left: 0;
          transition: all ease-in-out 0.2s;
          z-index: 1;
        }
        .icon {
          &.dashboard {
            background-image: url(/assets/images/sidebar/dashboard.png);
          }
          &.calc {
            background-image: url(/assets/images/sidebar/calc.png);
          }
          &.client {
            background-image: url(/assets/images/sidebar/client.png);
          }
          &.clock {
            background-image: url(/assets/images/sidebar/clock.png);
          }
          &.cubes {
            background-image: url(/assets/images/sidebar/cubes.png);
          }
          &.profile {
            background-image: url(/assets/images/sidebar/profile.png);
          }
          &.settings {
            background-image: url(/assets/images/sidebar/settings.png);
          }
          &.store {
            background-image: url(/assets/images/sidebar/store.png);
          }
          &.thumbsup {
            background-image: url(/assets/images/sidebar/thumbsup.png);
          }
          &.tools {
            background-image: url(/assets/images/sidebar/tools.png);
          }
          &.users {
            background-image: url(/assets/images/sidebar/users.png);
          }
          &.system {
            background-image: url(/assets/images/sidebar/system-icon.png);
          }
        }
      }
      .dropdown {
        background-color: #000;
        border-top: 1px solid rgba(0,0,0,.1);
        .dropdown-item {
          display: block;
          font-size: 13px;
          padding: 8px 20px;
          color: #f2f2f2;
          text-decoration: none;
          &:hover, &.active {
            background-color: #f2f2f2;
            color: #000;
          }
        }
      }
    }
  }
  .logo {
    width: calc(100% - 8px);
    height: 75px;
    display: flex;
    justify-content: center;
    margin-top: 5px;
    padding: 0 7px;
    img {
      width: 100%;
      height: 55px;
      object-fit: contain;
      object-position: center;
      &.extended {
        display: none;
      }
      &.small {
        display: block;
      }
    }
    // i {
    //   width: 55px;
    //   height: 55px;
    //   background-image: url(/assets/images/plugg-logo.png);
    //   background-repeat: no-repeat;
    //   background-position: center;
    //   transition: all ease-in-out 0.2s;
    // }
  }
  .hamgurger {
    width: $widthm;
    margin: 60px 0;
    display: flex;
    justify-content: center;
    opacity: .8;
    transition: all ease-in-out 0.2s;
    a {
      padding: 5px;
      cursor: pointer;
    }
    &:hover {
      opacity: 1;
    }
  }
  &.expanded {
    width: 195px;
    ul {
      li {
        span {
          display: inline-block;
          vertical-align: top;
          line-height: 50px;
        }
      }
    }
    .logo img {
      &.extended {
        display: block;
      }
      &.small {
        display: none;
      }
    }
  }
}
@keyframes navCopyShow {
  from { opacity: 0; }
  90% { opacity: 0; }
  to { opacity: 1; }
}
.copy {
  box-sizing: border-box;
  margin-top: 15px;
  padding: 0 20px 20px 20px;
  .text-center {
    text-align: center;
  }
  @media screen and (max-width: 760px) {
    display: none;
  }
  .flex{
    display: flex;
    justify-content: space-between;
    p:first-child {
      display: block;
      opacity: 1 !important;
    }
    margin-bottom: 5px;
  }
  p {
    line-height: 22px;
    font-size: 12px;
    a {
      text-decoration: underline;
    }
    display: none;
  }
  .copyright-side {
    font-size: 10px;
    line-height: 1.5;
    margin: 10px 0;
  }
  
  animation-name: navCopyShow;
  animation-duration: .2s;
}
.expanded {
  .copy {
    p {
      display: block;
      animation-name: navCopyShow; animation-duration: .2s;
    }
  }
}
.copy {
}
</style>
