<template>
  <div class="page-form">
    <p class="text-center">Permanent Residence – Humanitarian Immigration</p>
    
    <p><b>PERSONAL INFORMATION</b></p>

    <p>{{ client.firstName }} - {{ client.lastName }} - {{ age }} - {{ client.nationality | country }} - {{ client.residence | country }}</p>

    <p>Do you have a criminal record? <ex-select v-model="form.criminal_record" :options="yesNoOptions" no-style /></p>

    <p>Have you been declared a Protected Person or a Refugee by the Refugee Protection Division? <ex-select v-model="form.protected_person" :options="yesNoOptions" no-style /></p>

    <p><b>PERMANENT RESIDENCE:</b></p>

    <p>Do you have a CSQ? <ex-select v-model="form.csq" :options="yesNoOptions" class="small-input" :disabled="!isProtectedPerson" no-style />
      <span class="ml-20">How many persons will be included in the application? <ex-input v-model="form.persons_included" :disabled="!isProtectedPerson" no-style /></span>
      <br>Are any applicants outside Canada? <ex-select v-model="form.applicants_outside_canada" :options="yesNoOptions" :disabled="!isProtectedPerson" class="small-input" no-style />
    </p>

    <p><b>HUMANITARIAN AND COMPASSIONATE CONSIDERATIONS:</b></p>

    <p>How long have you been living in Canada? <ex-input v-model="form.how_long_living_in_ca" label="Years" :disabled="!isntProtectedPerson" no-style />
      Are you currently working? <ex-select v-model="form.working" :options="yesNoOptions" :disabled="!isntProtectedPerson" class="small-input" no-style />
      <br>Are you able to communicate in
        <span class="checkbox-group"><input type="checkbox" :disabled="!isntProtectedPerson" :checked="form.communicate === 'English'" value="English" @change="onCheckbox($event)"> English</span>
        <span class="checkbox-group"><input type="checkbox" :disabled="!isntProtectedPerson" :checked="form.communicate === 'French'" value="French" @change="onCheckbox($event)"> French</span>
        <span class="checkbox-group"><input type="checkbox" :disabled="!isntProtectedPerson" :checked="form.communicate === 'Both'" value="Both" @change="onCheckbox($event)"> Both</span>
        <span class="checkbox-group"><input type="checkbox" :disabled="!isntProtectedPerson" :checked="form.communicate === 'Neither'" value="Neither" @change="onCheckbox($event)"> Neither</span>
      <br>Did you make a refugee claim that was rejected by the Refugee Protection Division or the Refugee Appeal Division of the Immigration Refugee Board of Canada or did you abandon your Refugee Claim?
        <ex-select v-model="form.abandon_refugee" :disabled="!isntProtectedPerson" :options="yesNoOptions" class="small-input" no-style />
    </p>

    <div v-if="form.abandon_refugee === 'yes'">
      <p>Was your refugee claim rejected or abandoned within the last 12 months? <ex-select v-model="form.abondoned_in_last_12_months" :options="yesNoOptions" :disabled="!isAbandonRefugee" class="small-input" no-style />
        <br>Did you withdraw your Refugee claim during your Refugee Protection Division hearing, which occurred with the last 12 months?
          <ex-select v-model="form.withdraw_refugee_claim" :options="yesNoOptions" :disabled="!isAbandonRefugee" class="small-input" no-style /></p>

      <p><b>Is your application based on:</b>
        <br>The Best interest of a child? <ex-select v-model="form.best_interest_of_child" :options="yesNoOptions" :disabled="!isAbandonRefugee" class="small-input" no-style />
        <br>You suffer from a medical condition that would risk your life if you are returned to your country?
          <ex-select v-model="form.suffer_mediacal_cond" :options="yesNoOptions" :disabled="!isAbandonRefugee" class="small-input" no-style />
        <br>Other <ex-select v-model="form.other" :options="yesNoOptions" :disabled="!isAbandonRefugee" class="small-input" no-style /></p>
    </div>

    <div v-if="form.other === 'yes'">
      <p>Are you currently in a sponsorship process? <ex-select v-model="form.sponsorship_process" :options="yesNoOptions" :disabled="!isOther" class="small-input" no-style />
        <span class="ml-20">Since when? <span class="datepicker-wrapper">
          <datepicker
            v-model="form.sponsorship_process_since"
            calendar-button-icon="el-icon-date"
            format="d MMM yyyy"
            class="datepicker-small"
            :open-date="new Date()"
            :disabled="!isOther || form.sponsorship_process_since_unknown"
            calendar-button />
        </span></span>
        <span class="checkbox-group"><input v-model="form.sponsorship_process_since_unknown" type="checkbox" :disabled="!isOther"> Not sure</span>
        <br>Are you a victim of abuse, domestic violence or a forced marriage? <ex-select v-model="form.victiom_violence" :options="yesNoOptions" :disabled="!isOther" class="small-input" no-style />
        <br>Did you already receive your Certificate of Selection of Quebec? <ex-select v-model="form.certificate_selectin" :options="yesNoOptions" :disabled="!isOther" class="small-input" no-style />
        <br>Are there any children included in your application? <ex-select v-model="form.any_children" :options="yesNoOptions" :disabled="!isOther" class="small-input" no-style />
      </p>
    </div>

    <p><b>ADDITIONAL INFORMATION:</b></p>
    <ex-textarea v-model="form.additional_info" :rows="4" no-style full />
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import ExInput from '../../../common/Input'
import ExSelect from '../../../common/Select'
import ExTextarea from '../../../common/Textarea'

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    client: {
      type: Object,
      required: true
    }
  },

  components: {
    ExInput,
    ExSelect,
    Datepicker,
    ExTextarea
  },

  data () {
    return {
      form: this.value,
  
      yesNoOptions: [
        {
          id: 'yes',
          text: 'Yes'
        },
        {
          id: 'no',
          text: 'No'
        }
      ]
    }
  },

  computed: {
    isOther () {
      return this.form.other === 'yes'
    },

    isAbandonRefugee () {
      return this.form.abandon_refugee === 'yes'
    },
  
    isProtectedPerson () {
      return this.form.protected_person === 'yes'
    },

    isntProtectedPerson () {
      return this.form.protected_person === 'no'
    },

    age () {
      return moment().diff(moment(this.client.dob), 'years')
    }
  },

  watch: {
    'form.sponsorship_process_since_unknown' () {
      this.form.sponsorship_process_since = null
    },

    isOther () {
      ['sponsorship_process', 'sponsorship_process_since', 'sponsorship_process_since_unknown', 'victiom_violence', 'certificate_selectin', 'any_children']
        .forEach(key => this.form[key] = null)
    },

    isAbandonRefugee () {
      ['abondoned_in_last_12_months', 'withdraw_refugee_claim', 'best_interest_of_child', 'suffer_mediacal_cond', 'other']
        .forEach(key => this.form[key] = null)
    },

    isProtectedPerson () {
      ['csq', 'persons_included', 'applicants_outside_canada', 'how_long_living_in_ca', 'working', 'communicate', 'abandon_refugee']
        .forEach(key => this.form[key] = null)
    },

    form: {
      handler (form) {
        this.$emit('input', form)
      },
      deep: true,
      immediate: true
    }
  },

  created () {
    if (!Object.keys(this.form).length) {
      this.form = {
        criminal_record: null,
        protected_person: null,
        csq: null,
        persons_included: null,
        applicants_outside_canada: null,
        how_long_living_in_ca: null,
        working: null,
        communicate: null,
        abandon_refugee: null,
        abondoned_in_last_12_months: null,
        withdraw_refugee_claim: null,
        best_interest_of_child: null,
        suffer_mediacal_cond: null,
        other: null,
        sponsorship_process: null,
        sponsorship_process_since: null,
        sponsorship_process_since_unknown: null,
        victiom_violence: null,
        certificate_selectin: null,
        any_children: null,
        additional_info: null,
      }
    }
  },

  methods: {
    onCheckbox (event) {
      if (event.target.checked) this.form.communicate = event.target.value
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox-group {
  margin-left: 50px;
  input {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
}

.datepicker-wrapper {
  display: inline-block;
  width: 140px;
}
</style>