<template>
  <div class="page-form">
    <p class="text-center">Permanent Residence – Economic Immigration</p>
    
    <p><b>PERSONAL INFORMATION</b></p>

    <p>{{ client.firstName }} - {{ client.lastName }} - {{ age }} - {{ client.nationality | country }} - {{ client.residence | country }}</p>

    <economic-immigration-form v-model="form" />

    <p><b>SPOUSE / COMMON-LAW PARTNER INFORMATION</b></p>

    <p>Do you have a spouse or common-law partner? <ex-select v-model="form.spouse" :options="yesNoOptions" class="small-input" no-style /></p>
    
    <p>
      First Name <ex-input v-model="form.spouse_info.firstName" :disabled="disableSpouseForm" no-style />
      <span class="ml-20">Last Name <ex-input v-model="form.spouse_info.lastName" :disabled="disableSpouseForm" no-style /></span>
      <span class="ml-20">Age <ex-input v-model.number="form.spouse_info.age" type="number" no-style :disabled="disableSpouseForm" class="small-input" /></span>
      <br><span>Country of Birth <ex-select v-model="form.spouse_info.nationality" :options="countryOptions" :disabled="disableSpouseForm" no-style /></span>
      <span class="ml-20">Country of Residence <ex-select v-model="form.spouse_info.residence" :options="countryOptions" :disabled="disableSpouseForm" no-style /></span>
    </p>

    <economic-immigration-form
      v-model="form.spouse_info"
      :disabled="disableSpouseForm"
      is-spouse />

      
    <p><b>ADDITIONAL INFORMATION:</b></p>

    <ex-textarea v-model="form.additional_info" :rows="4" no-style full />
  </div>
</template>

<script>
import helperMixin from '../../../common/helpers-mixin'

import ExInput from '../../../common/Input'
import ExSelect from '../../../common/Select'
import ExTextarea from '../../../common/Textarea'
import EconomicImmigrationForm from './EconomicImmigrationForm'

export default {
  mixins: [ helperMixin ],

  props: {
    value: {
      type: Object,
      required: true
    },

    client: {
      type: Object,
      required: true
    }
  },

  components: {
    ExInput,
    ExSelect,
    ExTextarea,
    EconomicImmigrationForm
  },

  data () {
    return {
      form: this.value,

      yesNoOptions: [
        {
          id: 'yes',
          text: 'Yes'
        },
        {
          id: 'no',
          text: 'No'
        }
      ]
    }
  },

  computed: {
    disableSpouseForm () {
      return this.form.spouse !== 'yes'
    },

    age () {
      return moment().diff(moment(this.client.dob), 'years')
    },

    provinceOptions () {
      return window.states.CA
    }
  },

  watch: {
    form: {
      handler (form) {
        this.$emit('input', form)
      },
      deep: true,
      immediate: true
    }
  },

  created () {
    if (!Object.keys(this.form).length) {
      const fields = {
        criminal_record: null,
        
        ielts: null,
        ielts_date: null,
        tefaq_tef_dalf_sel: null,
        tefaq_tef_dalf_sel_date: null,

        english_writing: null,
        english_reading: null,
        english_speaking: null,
        english_listening: null,
        french_writing: null,
        french_reading: null,
        french_speaking: null,
        french_listening: null,

        diploma: null,
        program_duration: null,
        language: null,
        inside_canada: null,

        self_employed: false,
        payroll: false,
        full_time: false,
        part_time: false,
        less_than_15h_week: false,
        work_inside_canada: false,
        work_outside_canada: false,

        familiy_in_ca: null,
        familiy_in_manitoba: null,
        children_aged_less_18: null,
        children_aged_less_6: null,
      }

      this.form = {
        preferred_destination_ca: null,
        net_worth: null,
        ...fields,
        marital_status: null,
        spouse: null,
        additional_info: null,
        spouse_info: {
          firstName: null,
          lastName: null,
          age: null,
          nationality: null,
          residence: null,
          ...fields
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.small-input {
  width: 80px;
}
</style>