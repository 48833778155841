<template>
  <div>
    <div v-if="!isSubmitted && !expired" class="page">
      <component v-if="!loading" :is="programForm" v-model="form" :category="data ? data.category : null" :client="client" />

      <div class="flex-center mt-30">
        <el-button type="primary" :loading="buttonLoader" @click="update()">Save</el-button>
        <el-button v-if="!isSubmitted" type="success" :loading="buttonLoader" @click="submit()">Submit</el-button>
      </div>
    </div>
    <div class="form-submitted" v-else>
      <h1>{{ message }}</h1>
      <p v-if="justSubmitted">Thank you for submitting the form.</p>
      <a href="/">
        <el-button type="primary" class="button">Back to homepage</el-button>
      </a>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import PEQ from './setup-checklist/evaluation/PEQ'
import TemporaryResidence from './setup-checklist/evaluation/TemporaryResidence'
import EconomicImmigration from './setup-checklist/evaluation/EconomicImmigration'
import HumanitarianImmigration from './setup-checklist/evaluation/HumanitarianImmigration'
import Documents from './setup-checklist/evaluation/Documents'
import LegalReturnToCanada from './setup-checklist/evaluation/LegalReturnToCanada'
import LegalImmigrationAppeal from './setup-checklist/evaluation/LegalImmigrationAppeal'
import LegalImmigrationDetention from './setup-checklist/evaluation/LegalImmigrationDetention'
import LegalPRAA from './setup-checklist/evaluation/LegalPRAA'
import LegalRefugeeAppeal from './setup-checklist/evaluation/LegalRefugeeAppeal'
import LegalRefugeeClaim from './setup-checklist/evaluation/LegalRefugeeClaim'
import FamilyReunification from './setup-checklist/evaluation/FamilyReunification'
import Citizenship from './setup-checklist/evaluation/Citizenship'

export default {
  name: 'ClientEvaluationForm',

  props: {
    link: {
      type: String,
      required: true
    }
  },

  components: {
    PEQ,
    Documents,
    TemporaryResidence,
    EconomicImmigration,
    HumanitarianImmigration,
    LegalReturnToCanada,
    LegalImmigrationAppeal,
    LegalImmigrationDetention,
    LegalPRAA,
    LegalRefugeeAppeal,
    LegalRefugeeClaim,
    FamilyReunification,
    Citizenship
  },

  data () {
    return {
      type: 'ClientEvaluation',
      form: {},
      buttonLoader: false,
      data: null,
      justSubmitted: false,
      loading: true,
      expired: false
    }
  },

  computed: {
    message () {
      if (this.expired) return 'This link has expired.'
      return this.justSubmitted ? 'Form submitted successfully' : 'Form has already been submitted.'
    },

    client () {
      return this.data ? this.data.client : null
    },

    isSubmitted () {
      return this.data?.submitted
    },

    programForm () {
      const forms = {
        economic_immigration: 'EconomicImmigration',
        humanitarian_immigration: 'HumanitarianImmigration',
        PEQ: 'PEQ',
        visitor_visa: 'TemporaryResidence',
        supervisa: 'TemporaryResidence',
        student_permit: 'TemporaryResidence',
        temporary_worker: 'TemporaryResidence',
        other: 'TemporaryResidence',
        document_replacement: 'Documents',
        document_extension: 'Documents',
        travel_documents: 'Documents',
        authorization_return: 'LegalReturnToCanada',
        imigration_appeal: 'LegalImmigrationAppeal',
        immigration_detention: 'LegalImmigrationDetention',
        PRAA: 'LegalPRAA',
        refugee_appeal: 'LegalRefugeeAppeal',
        refugee_claim: 'LegalRefugeeClaim',
        spouse_sponsorship: 'FamilyReunification',
        parents_sponsorship: 'FamilyReunification',
        children_sponsorship: 'FamilyReunification',
        citizenship_application: 'Citizenship'
      }
      let form =  this.data ? forms[this.data.category] || 'div' : 'div'
      if (this.isSubmitted) form += 'Review'
      return form
    }
  },

  created () {
    this.load()
  },

  methods: {
    async load () {
      const response = await axios.get(`client-evaluation/${this.link}`)
      this.data = response.data
      if (response.data.expired) {
        return this.expired = true
      }
      this.form = response.data.data || {}
      this.loading = false
    },

    async submit () {
      try {
        await this.$confirm('Are you sure you want to submit a form?')
        this.update(true)
      } catch {}
    },

    async update (submitted = false) {
      try {
        const payload = { data: this.form }
        if (submitted) payload.submitted = 'CURRENT_TIMESTAMP'
        const response = await axios.post(`client-evaluation/${this.link}`, payload)
        this.data = response.data
        if (submitted) this.justSubmitted = true
        else this.$message.success('Client Evaluation form saved successfully.')
      } catch {}
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  margin: 0 auto;
  width: 100%;
  max-width: 1024px;
}
.form-submitted {
  margin: 100px 0;
  text-align: center;
  h1 {
    font-size: 30px;
  }
  p {
    font-size: 20px;
    margin-top: 15px;
  }
  .button {
    margin-top: 40px;
  }
}
</style>