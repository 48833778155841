<template>
  <div class="page-form">
    <p class="text-center">Temporary Residence</p>
    
    <p><b>PERSONAL INFORMATION</b></p>

    <p>{{ client.firstName }} - {{ client.lastName }} - {{ age }} - {{ client.nationality | country }} - {{ client.residence | country }}</p>

    <p>Do you have a criminal record?<span class="answer">{{ formatYN(value.criminal_record) }}</span></p>

    <p><b>MAIN APPLICANT</b></p>

    <p>Marital Status <span class="answer">{{ value.maritial_status | maritialStatus }}</span></p>

    <p>Medical issues <span class="answer">{{ formatYN(value.medical_issues) }}</span></p>

    <p>Status <span class="answer">{{ formatRS(value.status) }}</span></p>

    <p>Children under 18 <span class="answer">{{ formatYN(value.children) }}</span> How many <span class="answer">{{ value.children_num }}</span> Granted custody <span class="answer">{{ formatYN(value.granted_custody) }}</span></p>

    <p>Source of income <span class="answer">{{ value.source_of_income }}</span> Yearly income <span class="answer">{{ value.yearly_income }}</span> Personal Net Worth <span class="answer">{{ value.personal_net_worth }}</span></p>

    <p><b>LANGUAGE SKILLS</b></p>

    <p>IELTS <span class="answer">{{ formatYN(value.IELTS) }}</span> Date: <span class="answer">{{ value.IELTS_date | date('ll') }}</span> TEFAQ/TEF/DALF/SEL <span class="answer">{{ formatYN(value.TEFAQ) }}</span> Date: <span class="answer">{{ value.TEFAQ_date | date('ll') }}</span></p>
    
    <p>English (1-10)  Writing <span class="answer">{{ value.english_writing }}</span> Reading <span class="answer">{{ value.english_reading }}</span> Speaking <span class="answer">{{ value.english_speaking }}</span> Listening <span class="answer">{{ value.english_listening }}</span></p>
    
    <p>French (1-10)  Writing <span class="answer">{{ value.french_writing }}</span> Reading <span class="answer">{{ value.french_writing }}</span> Speaking <span class="answer">{{ value.french_writing }}</span> Listening <span class="answer">{{ value.french_writing }}</span></p>

    <p><b>EDUCATION AND TRAINING</b></p>

    <p>Highest diploma <span class="answer">{{ value.highest_diploma }}</span> Program Duration <span class="answer">{{ value.program_duration }}</span> Language <span class="answer">{{ value.language }}</span></p>

    <p>Inside Canada <span class="answer">{{ formatYN(value.inside_canada) }}</span></p>
    
    <p><b>WORK HISTORY (PAID WORK IN YOUR FIELD)</b></p>

    <p>
      <span class="checkbox-group check-line">Self Employed <input type="checkbox" :disabled="true" :checked="value.self_employed"></span>
      <span class="checkbox-group check-line">Payroll <input type="checkbox" :disabled="true" :checked="value.payroll"></span>
    </p>
    <p>
      <span class="checkbox-group check-line">Full time <input type="checkbox" :disabled="true" :checked="value.full_time"></span>
      <span class="checkbox-group check-line">Part time <input type="checkbox" :disabled="true" :checked="value.part_time"></span>
      <span class="checkbox-group check-line">Less than 15h a week <input type="checkbox" :disabled="true" :checked="value.less_then_fifeteen"></span>
    </p>
    <p>
      <span class="checkbox-group check-line">Inside Canada <input type="checkbox" :disabled="true" :checked="value.inside_canada_work"></span>
      <span class="checkbox-group check-line">Outside Canada <input type="checkbox" :disabled="true" :checked="value.outside_canada"></span>
    </p>

    <p><b>ADDITIONAL INFORMATION:</b></p>

    <span class="answer">{{ value.additional_info }}</span>

  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    client: {
      type: Object,
      required: true
    }
  },

  computed: {
    age () {
      return moment().diff(moment(this.client.dob), 'years')
    }
  },

  methods: {
    formatYN (val) {
      return val === 'yes' ? 'Yes' : 'No'
    },
    
    formatMS (status) {
      const ms = window.maritialStatusOptions
      return ms.find( item => item.id === status).text
    },

    formatRS (status) {
      const rs = window.residenceStatus
      return rs.find( item => item.id === status).text
    }
  }
}
</script>
<style lang="scss" scoped>
  .check-line {
    display: inline-block; 
    width: 200px;  
  }
</style>
